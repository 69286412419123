const pdfDownload = ({
  url,
  fileName,
  extension = "pdf",
  onComplete = () => {},
  captureErrors = true,
}) =>
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const aTag = document.createElement("a");
      aTag.setAttribute("download", `${fileName}.${extension}`);
      aTag.href = blobUrl;

      // Add an event listener for when the download is initiated
      aTag.addEventListener("click", () => {
        onComplete(); // Call the onComplete callback when download starts
      });

      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    })
    .catch((err) => {
      // TODO Ryan Tomaselli we should display an error message
      // to the user here or let the error bubble up for
      // callers to handle
      if (!captureErrors) {
        throw err;
      }
    });

export default pdfDownload;
