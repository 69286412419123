import * as XLSX from "xlsx";
import Papa from "papaparse";

export const convertFileToJson = (file, onComplete) =>
  new Promise((resolve, reject) => {
    if (!file) {
      resolve(null);
    }

    const fileType = file.type;
    const fileName = file.name;

    const isExcelFile =
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel" ||
      fileName.endsWith(".xlsx") ||
      fileName.endsWith(".xls");

    const isCsvFile =
      fileType === "text/csv" ||
      fileName.endsWith(".csv") ||
      fileName.endsWith(".txt");

    if (isExcelFile) {
      // Parse Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Take only the first sheet

        // Convert the worksheet to JSON, using the first row as headers
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Transform the data to use headers from the first row
        const headers = jsonData[0]; // First row as headers
        const rows = jsonData.slice(1); // Data starting from the second row
        const formattedData = rows.map((row) =>
          Object.fromEntries(row.map((cell, i) => [headers[i], cell]))
        );

        resolve(formattedData);
      };
      reader.readAsArrayBuffer(file);
    } else if (isCsvFile) {
      // Parse CSV file using PapaParse
      Papa.parse(file, {
        header: true, // Parses the CSV rows into JSON objects using the CSV headers
        skipEmptyLines: true,
        complete: (result) => {
          // Set the parsed CSV data directly
          resolve(result.data);
        },
      });
    } else {
      console.error(
        "Unsupported file format. Please upload a CSV or Excel file."
      );
    }
  });
