import React from "react";
import { Tooltip as BaseTooltip } from "@material-tailwind/react";

const Tooltip = ({ children, content }) => {
  return (
    <BaseTooltip content={content} className={`default-tooltip`}>
      {children}
    </BaseTooltip>
  );
};

export default Tooltip;
