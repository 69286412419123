import React from "react";
import Modal from "./Modal";
import { PrimaryButtons, SecondaryButtons } from "./buttons";

const ErrorPopUp = ({ onClose, open, data, title, proceed }) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      mxWidth={"max-w-[600px]"}
      title={title}
    >
      <div className=" overscroll-x-none max-h-[calc(70vh-32px)] overflow-y-scroll ">
        <div className=" flex flex-col gap-12">
          <div>
            <div className=" flex flex-col gap-4">
              <p className="text-[14px] text-wrap">{data}</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" h-10 my-8 flex justify-between ">
        <SecondaryButtons label={"Edit"} onClick={onClose} />
        <PrimaryButtons
          label={"Proceed"}
          onClick={(e) => {
            onClose();
            proceed();
          }}
        />
      </div>
    </Modal>
  );
};

export default ErrorPopUp;
