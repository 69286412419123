import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import StartNewImport from "src/components/importedListings/StartNewImport";
import PreviousImports from "src/components/importedListings/PreviousImports";
import SearchImportedListings from "src/components/importedListings/SearchImportedListings";

const ImportedListingsPage = () => {
  return (
    <>
      <Routes>
        <Route path="search" element={<SearchImportedListings />} />
        <Route path="start" element={<StartNewImport />} />
        <Route path="previous" element={<PreviousImports />} />
        <Route index element={<Navigate to="search" />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default ImportedListingsPage;
