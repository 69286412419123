import Select from "../common/Select";
const PageSize = ({ value, onChange, ...props }) => (
  <Select
    heading="Per Page"
    list={[
      {
        id: "25",
        label: "25",
        value: "25",
      },
      {
        id: "50",
        label: "50",
        value: "50",
      },
      {
        id: "100",
        label: "100",
        value: "100",
      },
    ]}
    rounded="8px"
    SelectionColor={false}
    selecionHeight={"36px"}
    flowWidth
    value={value}
    onChange={onChange}
    {...props}
  />
);

export default PageSize;
