import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import ToolbarButton from "./ToolbarButton";
const NavigateListings = ({ onNextClick, onPreviousClick}) => {
  return <div className="flex gap-2 items-center text-base">
    <ToolbarButton
      id="navigate-listings-previous"
      icon={<FaChevronUp />}
      onClick={onPreviousClick}
    />
    <ToolbarButton
      id="navigate-listings-next"
      icon={<FaChevronDown />}
      onClick={onNextClick}
    />
  </div>;
};

export default NavigateListings;
