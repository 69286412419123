import React from "react";
import { sortContacts } from "../common/amenities";
import ContactCard from "../common/ContactCard";
import { getPropertyId } from "src/utils/listings";
import { removeDuplicateContactsAndMarkOriginals } from "src/utils/listings";

const ContactCards = ({ listing, discoveryTooltip, isLastDiscoveryTooltip, onDiscoveryTooltipNextClick}) => {
  const processedContacts = sortContacts(
    removeDuplicateContactsAndMarkOriginals(listing?.contacts || [])
  );

  return (
    <div className="lg:flex   flex-col h-full sm:min-w-[320px] xl:w-[320px]">
      <p className=" text-[14px] text-[#999999] leading-6 font-semibold mb-2">
        Contacts ({processedContacts?.length})
      </p>
      <div className=" w-[calc(100vw-24px)] overflow-none xl:w-[380px] hideScrollBar">
        <div className={`w-[calc(100vw-12px)] gap-y-3  xl:w-[370px]`}>
          <div className=" grid grid-cols-2 gap-y-3   ">
            {processedContacts
              // .slice(0, 4)
              .map(
                (item, index) =>
                  index <= 3 && (
                    <ContactCard
                      {...(discoveryTooltip && index ===0 && {
                        discoveryTooltip,
                        isLastDiscoveryTooltip,
                        onDiscoveryTooltipNextClick
                      })}
                      key={`contact-card-${item.id}`}
                      name={[item?.first_name, item?.last_name].join(" ") || ""}
                      number={item.phone}
                      dnc={item.phone_dnc || item.dnc}
                      type={item?.phone_type}
                      propertyId={getPropertyId(listing)}
                      isStarred={item?.star}
                      list={"expired"}
                      hidden={item?.hidden}
                    />
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCards;
