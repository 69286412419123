import React, { useState, useEffect } from "react";
import { SecondaryButtons, PrimaryButtons } from "./buttons";
import ScrollContainer from "../core/ScrollContainer";
import Modal from "./Modal";
import useAPI from "./UseApi";

import { Close } from "@landvoice/icons";
import { PrimaryInput } from "./inputFields";

const AddTags = ({
  onClose,
  open,
  tags = [],
  postTo,
  onSaved = (updatedTags) => {},
}) => {
  const [currentTags, setCurrentTags] = useState(tags);

  const [newTags, setNewTags] = useState(``);
  const { post } = useAPI();

  const clear = () => {
    setNewTags("");

    onClose();
  };

  useEffect(() => {
    setCurrentTags(tags);
    // eslint-disable-next-line
  }, [tags, open]);

  const saveTags = () => {
    post(
      postTo,
      {
        tags: [...newTags.split(" "), ...currentTags].filter(
          (item) => item !== "" && item !== " "
        ),
      },
      () => {
        onSaved(
          [...newTags?.split(" "), ...currentTags].filter(
            (item) => item !== "" && item !== " "
          )
        );
        clear();
      }
    );
  };

  const removeTag = (tag) => {
    let index = currentTags.indexOf(tag);
    if (index !== -1) {
      currentTags.splice(index, 1);
      setCurrentTags([...currentTags]);
    }
  };
  return (
    <Modal onClose={clear} open={open} mxWidth={"max-w-[600px]"} title="Tags">
      <div className="  max-w-[552px] min-h-[176px] py-4 ">
        <div className="flex justify-between gap-2 h-9">
          <PrimaryInput
            autoFocus
            value={newTags}
            onChange={(e) => {
              setNewTags(e.target.value);
            }}
            placeHolder={"Type tag name here"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (newTags) {
                  setCurrentTags([...currentTags, ...newTags.split(" ")]);
                  setNewTags("");
                }
              }
            }}
          />
          <SecondaryButtons
            label="Add Tag"
            onClick={() => {
              if (newTags) {
                setCurrentTags([...currentTags, ...newTags.split(" ")]);
                setNewTags("");
              }
            }}
          />
        </div>
        <div className="mt-8">
          {currentTags.length ? (
              <ScrollContainer>
                <div className=" flex flex-wrap gap-2 mt-2 max-h-[200px] pr-[8px]">
                  {currentTags.map((tag, index) => {
                    return (
                      <div
                        key={`tag ${index}`}
                        className="flex items-center justify-between h-6 min-w-[59px] bg-[#EBEBEB] px-2 gap-1 rounded-[15.56px]"
                      >
                        <p className="text-[11px] font-bold text-[#666666] leading-4">
                          {tag}
                        </p>
                        <div
                          className="opacity-50 hover:opacity-100"
                          onClick={() => removeTag(tag)}
                        >
                          <Close className={"text-[12px]"} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ScrollContainer>
          ) : (
            <p className="text-[#C2C2C2] italic leading-5 text-[14px]">
              You have not added any tags yet
            </p>
          )}{" "}
        </div>
      </div>
      <div className=" flex justify-between mt-8">
        <SecondaryButtons label={"Cancel"} onClick={clear} />
        <PrimaryButtons label={"Save"} onClick={saveTags} />
      </div>
    </Modal>
  );
};

export default AddTags;
