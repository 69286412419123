import React from "react";
import { NavLink } from "react-router-dom";
import {
  XActive,
  FacebookActive,
  LinkedInActive,
  InstagramActive,
} from "@landvoice/icons";

const socialToIconMap = {
  facebook: FacebookActive,
  twitter: XActive,
  linkedin: LinkedInActive,
  instagram: InstagramActive,
};

const Socials = (props) => (
  <div className="flex gap-[20px] items-center">
    {Object.entries(socialToIconMap).reduce((acc, [key, Icon]) => {
      return props.hasOwnProperty(key)
        ? [
            ...acc,
            <NavLink key={`social-icon-${key}`} to={props[key]} target="blank">
              <Icon />
            </NavLink>,
          ]
        : acc;
    }, [])}
  </div>
);

export default Socials;
