export const PRODUCT_TYPES = {
  expired: "expired",
  oldexpired: "oldexpired",
  preforeclosure: "preforeclosure",
  distressed: "distressed",
  fsbo: "fsbo",
  customupload: "customupload",
};

export const SUPPORTED_SOCIAL_MEDIA = ["facebook", "twitter", "linkedin", "instagram"];
export const FALLBACK_MAP_MARKER_COLOR = "#fa7532";
export const SELECTED_LISTING_MAP_MARKER_COLOR = "#0ea5e9";