import ListingDetails from "../SearchListings/ListingDetails";
import useListingDetails from "../../hooks/useListingDetails";
import { Mail, Editbutton } from "@landvoice/icons";
import HorizonalDivider from "../common/HorizonalDivider";
import { splitArray } from "src/utils/arrays";
import {
  HDivider,
  InfoText,
  formatDate,
  formatPhoneNumber,
} from "../common/amenities";
import { SUPPORTED_SOCIAL_MEDIA } from "../../constants/listing";
import ExpandableContent from "../common/ExpandableContent";
import DetailsField from "../SearchListings/DetailsField";
import { pick } from "../../utils/objects";
import ContactCard from "../common/ContactCard";
import Socials from "../common/Socials";
import { convertObjectToArray } from "../../utils/objects";
import {
  getImportedListingTitle,
  getImportedListingPrice,
  getFormattedPrice,
  getStatusDateFromMlsStatus,
  getPropertyId,
} from "../../utils/listings";
import { PropertyTypeEnum } from "../../data/selectionData";
import { PRODUCT_TYPES } from "../../constants/listing";

const MAX_EXTRA_FIELDS = 30;
const ImportedListingDetails = ({
  title,
  onClose,
  listingId,
  productType,
  onTagsSaved = (updatedTags) => {},
  onNotesSaved = (updatedNotes) => {},
  onMapButtonClick,
  onNextListingClick,
  onPreviousListingClick,
}) => {
  const listingDetails = useListingDetails({
    productType,
    onTagsSaved,
    onNotesSaved,
    apiUrl: `/api/${productType}/listing/${listingId}/`,
  });

  const {
    listing,
    toggleNotesDialog,
    toggleTagsDialog,
    showFullNote,
    setShowFullNote,
    processedContacts,
    isLoading,
    isError,
  } = listingDetails;

  const socials = pick(
    {
      ...listing?.owner_information,
      ...listing?.social_media,
    },
    SUPPORTED_SOCIAL_MEDIA
  );

  const hasSocials = !!Object.values(socials).length;

  const [importedFields, otherImportedFields] = splitArray(
    listing?.extra_fields
      ? convertObjectToArray(JSON.parse(listing.extra_fields))
      : [],
      MAX_EXTRA_FIELDS
  );

  return (
    <ListingDetails
      onClose={onClose}
      onNextListingClick={onNextListingClick}
      onPreviousListingClick={onPreviousListingClick}
      title={title}
      listingDetails={listingDetails}
      AddNotesProps={{
        postTo: `/api/${productType}/listing/${listingId}`,
        where: productType,
      }}
      AddTagsProps={{
        postTo: `/api/${productType}/listing/${listingId}/tags`,
      }}
      PrintOneViewPropsProvider={(listing) => ({
        title: getImportedListingTitle(listing),
        bath: listing?.baths || "--",
        beds: listing?.bedrooms || "--",
        sqrFeet: listing?.square_footage,
        lotSize: listing?.lot_size || "--",
        buildDate: listing?.year_built,
        type: PropertyTypeEnum["H"],
      })}
      mlsTitleAmenitiesPropsProvider={(listing) => ({
        all: false,
        suppressStatusInfo: true,
        title: getImportedListingTitle(listing),
        bath: listing?.baths || "--",
        beds: listing?.bedrooms || "--",
        sqrFeet: listing?.square_footage || "--",
        lotSize: listing?.lot_size || "--",
        buildDate: listing?.property_features?.year_built,
        type: PropertyTypeEnum[listing?.property_features?.property_type],
        isDetails: true,
        mapLink: listing?.property?.street_address
          ? `https://www.google.com/maps/place/${listing?.property?.street_address}+${listing?.property?.city}+${listing?.property?.state}+${listing?.property?.postal_code}`
          : "",
        onMapButtonClick,
      })}
    >
      {!isLoading && !isError && (
        <>
          <div>
            <div>
              <HDivider />
              <div className="flex gap-[40px] flex-wrap items-center py-[auto] my-2">
                <InfoText
                  title={"Price"}
                  info={
                    getImportedListingPrice(listing)
                      ? getFormattedPrice(getImportedListingPrice(listing))
                      : "--"
                  }
                />
                <InfoText title="Landvoice ID" info={listing?.id} />
                <InfoText
                  title="Date Processed"
                  info={formatDate(listing?.publish_date)}
                />
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-12 mt-6 print:gap-2 print:mt-2">
            <section>
              <div className="max-w-[729px] min-h-[140px]">
                <div className="flex gap-4 h-7 items-center mb-6">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                    Imported Fields
                  </p>
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>
                <div>
                  {importedFields?.length ? (
                    <ExpandableContent
                    showMoreLabel={`Show ${otherImportedFields.length} more fields`}
                      content={
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                          {importedFields.map(({ name, value }) => (
                            <DetailsField
                              fieldLabel={name}
                              fieldValue={value || ""}
                            />
                          ))}
                        </div>
                      }
                      moreContent={
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                          {otherImportedFields.map(({ name, value }) => (
                            <DetailsField
                              fieldLabel={name}
                              fieldValue={value || ""}
                            />
                          ))}
                        </div>
                      }
                    />
                  ) : (
                    <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                      No Imported Fields
                    </p>
                  )}
                </div>
              </div>
              <div className="md:max-w-[729px]  flex gap-8 flex-col md:flex-row print:flex-row ">
                <div className="  w-full ">
                  <div className="flex gap-4 h-7 items-center mb-6 w-full">
                    <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                      Notes{" "}
                      {listing?.notes?.length > 0 ? (
                        <span className="font-[500] text-[12px] text-[#999999]">
                          Last modified{" "}
                          {formatDate(listing?.notes[0]?.modify_date)}
                        </span>
                      ) : (
                        <span className="font-[500] text-[12px] text-[#999999]">
                          no notes were added
                        </span>
                      )}
                    </p>{" "}
                    <HorizonalDivider minWidthClass="min-w-[25px]" />
                    <div
                      onClick={() => toggleNotesDialog()}
                      className="w-[20px] cursor-pointer"
                    >
                      <Editbutton className="text-[20px] w-5 h-5" />
                    </div>
                  </div>
                  {listing?.notes?.length > 0 ? (
                    <>
                      <pre
                        className={`text-[12px] leading-[15px] text-[#666666] cursor-pointer font-[Lato] text-wrap  `}
                      >
                        <span
                          className={`${
                            showFullNote ? "wrap text-wrap" : "line-clamp-3"
                          }`}
                        >
                          {listing?.notes[0]?.note}{" "}
                        </span>{" "}
                        {listing?.notes[0]?.note.length > 195 && (
                          <span
                            className="font-[500] text-[12px] text-[#999999] italic"
                            onClick={() => {
                              setShowFullNote((cur) => !cur);
                            }}
                          >
                            {showFullNote ? "... see less" : "... see more"}
                          </span>
                        )}
                      </pre>

                      {listing?.notes.length > 1 &&
                        listing?.notes[0]?.note?.length < 80 && (
                          <pre
                            className={`text-[12px] leading-[15px] text-[#666666] cursor-pointer font-[Lato] text-wrap   `}
                          >
                            <span
                              className={`${
                                showFullNote ? "wrap text-wrap" : "line-clamp-3"
                              }`}
                            >
                              {listing?.notes[1]?.note}{" "}
                            </span>{" "}
                            {listing?.notes[1]?.note.length > 195 && (
                              <span
                                className="font-[500] text-[12px] text-[#999999] italic"
                                onClick={() => {
                                  setShowFullNote((cur) => !cur);
                                }}
                              >
                                {showFullNote ? "... see less" : "... see more"}
                              </span>
                            )}
                          </pre>
                        )}
                    </>
                  ) : (
                    <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                      No notes added.{" "}
                      <span
                        onClick={toggleNotesDialog}
                        className=" underline cursor-pointer"
                      >
                        Click to add notes.
                      </span>
                    </p>
                  )}
                </div>

                <div className=" max-w-[260px] ">
                  <div className="flex w-full gap-4 h-7 items-center mb-6">
                    <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                      Tags{" "}
                    </p>{" "}
                    <HorizonalDivider minWidthClass="min-w-[200px]" />
                    <div onClick={toggleTagsDialog} className="w-[20px]">
                      <Editbutton className="text-[20px] w-5 h-5" />
                    </div>
                  </div>
                  {listing?.tags?.length ? (
                    <div className=" flex flex-wrap gap-2">
                      {listing?.tags?.map((tag, index) => {
                        return (
                          <div className="flex items-center justify-between h-6 min-w-[50px] bg-[#EBEBEB] px-4 gap-1 rounded-[15.56px]">
                            <p className="text-[11px] font-bold text-[#666666] leading-4">
                              {tag}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                      No tags found.{" "}
                      <span
                        onClick={toggleTagsDialog}
                        className=" underline cursor-pointer"
                      >
                        Click to add tags.
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </section>

            <section>
              <div className="max-w-[729px] min-h-[140px]">
                <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                    Owner Information{" "}
                    <span className="font-[500] text-[12px] text-[#999999]"></span>
                  </p>{" "}
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <DetailsField
                    fieldLabel="Name"
                    fieldValue={
                      listing?.owner_name || listing?.owner?.full_name || ""
                    }
                  />
                  <DetailsField
                    fieldLabel="Address"
                    fieldValue={
                      listing?.owner_information?.property?.street_address ||
                      listing.owner?.mailing_property?.street_address
                        ? `${
                            listing?.owner_information?.property
                              ?.street_address ||
                            listing?.owner?.mailing_property?.street_address
                          }, ${
                            listing?.owner_information?.property.city ||
                            listing?.owner?.mailing_property?.city
                          }, ${
                            listing?.owner_information?.property?.state ||
                            listing?.owner?.mailing_property?.state
                          } ${
                            listing?.owner_information?.property?.postal_code ||
                            listing?.owner?.mailing_property?.postal_code
                          }`
                        : ""
                    }
                  />
                  <div className="flex flex-col">
                    <p className=" font-semibold text-[14px] leading-5 text-[#999999] ">
                      Email and Socials
                    </p>
                    <div className="flex gap-3 items-center">
                      <Mail className="text-[16px]" />{" "}
                      {listing?.social_media?.email ? (
                        <p className="  text-[14px] leading-5 text-[#666666] ">
                          {listing?.social_media?.email}
                        </p>
                      ) : (
                        <p className="  text-[14px] leading-5 text-[#666666] italic">
                          No email found
                        </p>
                      )}
                    </div>
                    {hasSocials && (
                      <>
                        <HDivider />
                        <div className="h-4 mt-2 flex gap-[14px]">
                          <Socials {...socials} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {processedContacts?.length > 0 && (
              <section>
                <div className="max-w-[729px]">
                  <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                    <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                      Phone Numbers{" "}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                  </div>
                  <div className={"grid grid-cols-2 gap-4 md:grid-cols-4"}>
                    {processedContacts?.map((contact, index) => (
                      <ContactCard
                        id={index}
                        name={
                          [contact?.first_name, contact?.last_name].join(" ") ||
                          ""
                        }
                        number={contact?.phone}
                        dnc={contact?.phone_dnc}
                        type={contact?.phone_type}
                        icon="Owner"
                        isStarred={contact?.star}
                        hidden={contact?.hidden}
                        list={"preforeclosure"}
                        propertyId={getPropertyId(listing)}
                      />
                    ))}
                  </div>
                </div>
              </section>
            )}
          </div>
        </>
      )}
    </ListingDetails>
  );
};

export default ImportedListingDetails;
