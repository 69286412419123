export const STEPS = {
  configureUpload: 1,
  mapFields: 2,
  review: 3,
  submitted: 4,
};

export const STEPS_CONFIG = [
  { name: STEPS.configureUpload, pageTitle: "Start Lookup", breadcrumbLabel: "Start" },
  { name: STEPS.mapFields, pageTitle: "Map Your Fields", breadcrumbLabel: "Map Fields" },
  { name: STEPS.review, pageTitle: "Review & Submit", breadcrumbLabel: "Review" }
];

export const UPLOAD_STATUSES = {
  submitted: 1,
  accepting: 2,
  accepted: 3,
  searching: 4,
  finalizing: 5,
  complete: 6,
  insufficientcredits: 7,
  error: 20,
};

export const UPLOAD_STATUSES_CONFIG = [
  { name: UPLOAD_STATUSES.submitted, label: "Submitted" },
  { name: UPLOAD_STATUSES.accepting, label: "Accepting" },
  { name: UPLOAD_STATUSES.accepted, label: "Accepted" },
  { name: UPLOAD_STATUSES.searching, label: "Searching" },
  { name: UPLOAD_STATUSES.finalizing, label: "Finalizing" },
  { name: UPLOAD_STATUSES.complete, label: "Complete" },
  { name: UPLOAD_STATUSES.insufficientcredits, label: "Insufficient Credits" },
  { name: UPLOAD_STATUSES.error, label: "Error" },
];

export const LOOKUP_TYPES = {
  phone: "P",
  address: "A",
};

export const CONTACT_MORE_CREDITS = "801-845-4383 "