import Tooltip from "../core/Tooltip";
import FeatureDiscoveryTooltip, { FeatureDiscoveryTooltipProps } from "../FeatureDiscoveryTooltip";

type ToolbarButtonFeatureDiscoveryTooltipProps = Omit<
  FeatureDiscoveryTooltipProps,
  'children' | 'isOpen'
>;

type ToolbarButtonProps = {
  id?: string;
  onClick: () => void;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  DiscoveryTooltipProps?: ToolbarButtonFeatureDiscoveryTooltipProps;
  tooltipContent?: string;
};

const ToolbarButton = ({
  id,
  onClick,
  icon,
  children,
  className,
  isLoading = false,
  disabled = false,
  DiscoveryTooltipProps,
  tooltipContent,
}: ToolbarButtonProps) => {

  const button = (
    <button
      {...(id && { id })}
      className={`${
        className ? `${className} ` : ""
      }cursor-pointer justify-center items-center disabled:opacity-50 flex px-4 py-[6px] h-[36px] rounded-[4px] border-[1px] border-[#EBEBEB] leading-6 text-[14px] text-[#666666]`}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading && (
        <div className="w-4 h-4 border-2  border-solid  border-t-blue-500  border-[#F27935] rounded-full animate-spin"></div>
      )}
      {!isLoading && icon && (
        <div className={`${children ? "mr-1" : ""}`}>{icon}</div>
      )}
      {!isLoading && children}
    </button>
  );

  if (DiscoveryTooltipProps) {
    return (
      <FeatureDiscoveryTooltip
        {...DiscoveryTooltipProps}
        isOpen
      >
        {button}
      </FeatureDiscoveryTooltip>
    );
  }
  if (tooltipContent) {
    return (
      <Tooltip content={tooltipContent}>
        {button}
      </Tooltip>
    );
  }
  return button;
};

export default ToolbarButton;
