const DetailsField = ({ fieldLabel, fieldValue, noValueDisplay = "Unavailable" }) => {
  return (
    <div className="min-h-[50px] flex flex-col">
      <p className=" font-semibold text-[14px] leading-5 text-[#999999] whitespace-nowrap">
        {fieldLabel}
      </p>
      {fieldValue ? (
        <p className="  text-[14px] leading-5 text-[#666666] ">
          {fieldValue}
        </p>
      ) : (
        <p className="  text-[14px] leading-5 text-[#666666] italic">
          {noValueDisplay}
        </p>
      )}
    </div>
  );
};

export default DetailsField;
