import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export const SCROLLBAR_THEME = {
  light: "os-theme-light",
  dark: "os-theme-dark",
}
const ScrollContainer = ({ children, theme = SCROLLBAR_THEME.dark }) => (
  <OverlayScrollbarsComponent
    options={{
      scrollbars: {
        theme,
        autoHide: "never",
        visibility: "auto"
      },
    }}
    defer
  >
    {children}
  </OverlayScrollbarsComponent>
);

export default ScrollContainer