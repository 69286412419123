import React from "react";
import { Typography } from "@material-tailwind/react";
import { Stack, Box } from "@mui/material";
import createLoopingArray from "../../utils/createLoopingArray";
const ListingSkeleton = () => {
  return (
    <div className="max-w-full animate-pulse mb-12">
      <Stack direction="row">
        <Box>
          <Stack direction="row" className="mb-4" alignItems="center">
            <Typography as="div" className="h-4 w-24 rounded-full bg-gray-300">
              &nbsp;
            </Typography>
            <Typography
              as="div"
              className="h-3 ml-4 w-24 rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
          </Stack>
          <Typography as="div" className="h-5 w-80 rounded-full bg-gray-300">
            &nbsp;
          </Typography>
          <Stack direction="row" className="mb-3 mt-2">
            {createLoopingArray(6).map((_, index, arr) => (
              <React.Fragment key={`group1-${index}`}>
                <Typography
                  as="div"
                  className="h-2 mb-2 w-14 p-2 rounded-full bg-gray-300"
                >
                  &nbsp;
                </Typography>
                {index < arr.length - 1 && (
                  <div className="w-[2px] mr-2 ml-2 h-4 bg-[#EBEBEB]"></div>
                )}
              </React.Fragment>
            ))}
          </Stack>
          {createLoopingArray(2).map((_, index) => (
            <Typography
              key={`group2-${index}`}
              as="div"
              className="h-2 mb-2 w-72 rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
          ))}
        </Box>
        <Box className="mt-5 pl-32">
          {createLoopingArray(3).map((_, index) => (
            <Typography
              key={`group3-${index}`}
              as="div"
              className="h-2 mb-2 w-48 rounded-full bg-gray-300"
            >
              &nbsp;
            </Typography>
          ))}
        </Box>
      </Stack>
    </div>
  );
};

export default ListingSkeleton;
