import { useCallback, useMemo, useState } from "react"
import { Check, ChevronsUpDown, X } from 'lucide-react'

import { cn } from "../../utils/css"
import {
    Button,
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Badge
} from "../shadcn"

import { ComboBoxListOption } from "./ComboBox"

export interface MultiSelectComboboxProps {
    list: ComboBoxListOption[];
    noFilterMatchesLabel?: string;
    placeholder?: string;
    value?: ComboBoxListOption[];
    onSelect: (selected: ComboBoxListOption) => void;
    onRemove: (removed: ComboBoxListOption) => void;
}

export const useMultiSelectCombobox = ({ initialSelectedItems = [] }) => {
    const [selectedItems, setSelectedItems] = useState<ComboBoxListOption[]>(initialSelectedItems)

    const resetSelectedItems = useCallback((newSelectedItems: ComboBoxListOption[] = []) => {
        setSelectedItems(newSelectedItems);
    }, [setSelectedItems])

    const handleSelect = useCallback((item: ComboBoxListOption) => {
        setSelectedItems((prev) => {
            if (prev.some((i) => i.value === item.value)) {
                return prev.filter((i) => i.value !== item.value)
            } else {
                return [...prev, item]
            }
        })
    }, [setSelectedItems])

    const handleRemove = useCallback((item: ComboBoxListOption) => {
        setSelectedItems((prev) => prev.filter((i) => i.value !== item.value))
    }, [setSelectedItems])

    return useMemo(() => ({
        resetSelectedItems,
        handleRemove,
        handleSelect,
        selectedItems,
        setSelectedItems
    }), [resetSelectedItems, handleRemove, handleSelect, selectedItems, setSelectedItems])
}

export default function MultiSelectCombobox({ list, value = [], placeholder = "Select an option...", noFilterMatchesLabel = "No matches", onSelect, onRemove }: MultiSelectComboboxProps) {
    const [open, setOpen] = useState(false)
    return (
        <div className="multi-select-combo-box">
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between text-[#666666] hover:text-[#666666]"
                    >
                        {value.length > 0
                            ? `${value.length} 
                            selected`
                            : placeholder}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                    <Command>
                        <CommandInput placeholder={placeholder} />
                        <CommandEmpty>{noFilterMatchesLabel}</CommandEmpty>
                        <CommandGroup>
                            {list.map((listOption) => (
                                <CommandItem
                                    key={listOption.value}
                                    onSelect={() => onSelect(listOption)}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            value.some((item) => item.value === listOption.value)
                                                ? "opacity-100"
                                                : "opacity-0"
                                        )}
                                    />
                                    {listOption.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
                {!!value?.length && <div className="mt-3">
                    {value.map((item) => (
                        <Badge key={item.value} variant="secondary" className="m-1">
                            {item.label}
                            <Button
                                variant="ghost"
                                size="sm"
                                className="ml-2 h-4 w-4 p-0"
                                onClick={() => onRemove(item)}
                            >
                                <X className="h-3 w-3" />
                            </Button>
                        </Badge>
                    ))}
                </div>}
            </Popover>
        </div>
    )
}

