import { formatNumber } from "../../utils/numbers";
function getDisplayRange(totalRecords, pageSize, currentPage) {
  const start = (currentPage - 1) * pageSize + 1;
  const end = Math.min(currentPage * pageSize, totalRecords);
  return `Showing ${start} to ${end} of ${formatNumber(totalRecords, 0)}`;
}

const SearchSummary = ({ isShowing, totalRecords, pageSize, currentPage }) => (
  <p
    className={`text-[#999999] px-2 leading-[19.2px] font-[400] text-[14px] transition-opacity whitespace-nowrap ${
        !isShowing ? "opacity-0 duration-0" : "opacity-100 duration-1000"
    }`}
  >
     
    {getDisplayRange(totalRecords, pageSize, currentPage)}
  </p>
);

export default SearchSummary;
