import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { PrimaryButtons, SecondaryButtons } from "./buttons";
import useAPI from "./UseApi";
import { formatTimestamp } from "./amenities";
import Cookies from "universal-cookie";
import { DeleteIcon } from "../icons";
import ScrollContainer from "../core/ScrollContainer";
import ToolbarButton from "../SearchListings/ToolbarButton";
const AddNotes = ({
  onClose,
  open,
  notes = [],
  postTo,
  onSaved = (updatedNotes) => {},
  // eslint-disable-next-line react-hooks/exhaustive-deps
  where = "expired",
}) => {
  const { post } = useAPI();
  const [note, setNote] = useState("");
  const [allNotes, setAllNotes] = useState(notes);
  const [CSRFToken, setCSRFToken] = useState("0");

  useEffect(() => {
    const cookies = new Cookies();
    const cookieValue = cookies.get("csrftoken");
    setCSRFToken(cookieValue);
  }, []);

  const clear = () => {
    setNote("");
    onClose();
  };

  const saveNote = async () => {
    const currentDate = new Date();
    const currentTimeMilliseconds = currentDate.getTime();
    if (!note || note.length === 0) return onClose();
    const newNote = await post(
      postTo,
      {
        title: currentTimeMilliseconds,
        note: note,
      },
      () => {} // This empty function might be unnecessary if 'post' returns a promise
    );
    if (note) onSaved([newNote, ...notes]);
    if (note) clear();
  };

  const deleteNote = (itemId) => {
    // Confirm the deletion with the user
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item, action can't be undone?"
    );
    if (!isConfirmed) {
      return;
    }

    fetch(`api/${where}/note/${itemId}`, {
      method: "DELETE", // Specify the method to use
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": CSRFToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Update the UI by filtering out the deleted item
        const updatedItems = allNotes.filter((item) => item.id !== itemId);
        setAllNotes(updatedItems);
        onSaved(updatedItems);
      })
      .catch((error) =>
        console.error("There was an error deleting the item", error)
      );
  };

  return (
    <Modal
      onClose={clear}
      open={open}
      mxWidth={"max-w-[552px]"}
      title={"Notes"}
    >
      <div className="max-w-[552px] min-h-[176px] py-4">
        <p className="text-[#999999] font-semibold leading-5">Add Note</p>
        <div className="flex justify-between gap-2 h-[200px] mt-2">
          <textarea
            autoFocus
            className=" relative  w-full bg-[#F5F7F7] py-2 px-3 border border-[#E0E0E0] rounded placeholder:text-[#999999] text-[14px] leading-6 text-[#666666] outline-[#E2631B] resize-none"
            type="text"
            placeholder="Enter your note here"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
              notes = note;
            }}
          />
        </div>
        <div className="mt-4">
          <ScrollContainer>
            <div className="max-h-[200px] gap-1 ">
              {(allNotes || []).map((note, index) => {
                return (
                  <div
                    key={`note ${index}`}
                    className="flex  justify-between items-center p-1 pr-[20px] border-[#ebebeb] gap-2 border-b"
                  >
                    <p className="text-[13px] text-[#666666] leading-4 max-w-[420px]">
                      {note?.note}
                    </p>
                    <div className=" flex gap-5 items-center ">
                      <p className="text-[13px] text-[#999] leading-4">
                        {formatTimestamp(note.create_date)}
                      </p>
                      <ToolbarButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                          deleteNote(note.id);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollContainer>
        </div>
      </div>
      <div className=" flex justify-between mt-8">
        <SecondaryButtons label={"Cancel"} onClick={clear} />
        <PrimaryButtons label={"Save"} onClick={saveNote} />
      </div>
    </Modal>
  );
};

export default AddNotes;
