import { Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { SELECT_DROPDOWN } from "../../constants/zIndexes";
function joinClassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const LIST_PLACEMENT = {
  above: "above",
  below: "below",
}

export default function Select({
  onChange,
  heading = "",
  headingProvider,
  SelectionColor = true,
  list = [],
  flowWidth = false,
  listPlacement = LIST_PLACEMENT.below,
  value,
  check = true,
  positionLeft = true,
  isLoading = false,
  classNames="",
  suppressMinWidth = false
}) {

  const buttonLabel = useMemo(() => {

    if (headingProvider) {
      return headingProvider(value);
    }

    if (typeof heading === "string") {
      return `${heading ? `${heading} `: ""} ${value?.label || ""}`
    }

    return heading

  }, [headingProvider, heading, value]);

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className={`relative ${classNames}`}>
            <Listbox.Button
              className={`cursor-pointer rounded-[4px] flex justify-between gap-2 cursor-default h-9 items-center border-[1px] border-[#E0E0E0] ${!suppressMinWidth ? "min-w-[125px]": ""} text-[14px] font-[400] leading-[20px] text-[#666666]`}
            >
              <span className="flex items-center">
                <span className="ml-3 block truncate">{buttonLabel}</span>
              </span>
              {isLoading && (
                <div className="w-4 h-4 border-2  border-solid  border-t-blue-500  border-[#F27935] rounded-full animate-spin"></div>
              )}

              <span className="pointer-events-none  inset-y-0 right-0  flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={
                listPlacement === LIST_PLACEMENT.above
                  ? "absolute mb-1 w-full rounded-md bg-white shadow-lg bottom-full"
                  : ""
              }
            >
              <Listbox.Options
                className={
                  flowWidth
                    ? `selectDropDown min-w-200 ${
                        positionLeft ? "" : "right-0"
                      }`
                    : `selectDropDown w-full ${positionLeft ? "" : "right-0"}`
                }
                style={{
                  zIndex: SELECT_DROPDOWN,
                }}
              >
                {list?.map((listOption) => (
                  <Listbox.Option
                    key={`Selection-option-${listOption.id}`}
                    className={({ active }) =>
                      joinClassNames(
                        active && SelectionColor
                          ? "bg-[#F27935] text-white"
                          : "text-[#666666]",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={listOption}
                  >
                    {({ selected, active }) => (
                      <>
                        <div
                          className="flex items-center"
                          onClick={
                            listOption.clickableFunction
                              ? listOption.clickableFunction
                              : () => {}
                          }
                        >
                          <span
                            className={joinClassNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {listOption.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={joinClassNames(
                              active && SelectionColor
                                ? "text-white"
                                : "text-[#F27935]",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {check && (
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
