// See https://app.lottiefiles.com/animation/980a18c4-918a-49a6-b7e2-ba6c6d522f79
import Lottie from 'lottie-react';
import animationData from './lottie.json';
const Dots = ({ width, height }: { width?: number, height?: number}) => {
  return (
    <div style={{ width, height }}>
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};
export default Dots;
