import { useState } from "react";

const FileDragAndDropTarget = ({ onFileSelected, className, children }) => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
      onFileSelected(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };
  return (
    <div
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={`${className} ${
        dragging ? "!border-dashed !border-[#f27a35a6] !border-3" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default FileDragAndDropTarget;
