import React, { useMemo, useState } from "react"
import { Check, ChevronsUpDown, X } from "lucide-react"

import { cn } from "../../utils/css"
import { Button } from "../shadcn/Button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../shadcn/Command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../shadcn/Popover"

export interface ComboBoxListOption {
  label: string;
  value: string;
}

export interface ComboBoxProps {
  list: ComboBoxListOption[];
  noFilterMatchesLabel?: string;
  placeholder?: string;
  value: ComboBoxListOption | null | undefined;
  onSelect: (selected: ComboBoxListOption | null | undefined, isNew?: boolean) => void;
  isNewItemsAllowed?: boolean;
}

const ComboBox = ({ list, noFilterMatchesLabel = "No matches", placeholder = "Select an option...", value, onSelect, isNewItemsAllowed = false }: ComboBoxProps) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('');

  const selectedValueLabel = useMemo(() => value && value?.value
    ? list.find((item) => item.value === value.value)?.label : ""
    , [value, list])

  const isNoMatches = useMemo(() =>
    inputValue ? !list.some((option) => option.value === inputValue) : false
    , [inputValue, list]);

  return (
    <div className="flex items-center gap-1">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={`w-[200px] justify-between ${selectedValueLabel ? "text-accent-foreground" : ""}`}
          >
            {selectedValueLabel || placeholder}
            <ChevronsUpDown className="opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder={placeholder} onValueChange={(value) => setInputValue(value)} />
            <CommandList>
              {!isNewItemsAllowed && isNoMatches && <CommandEmpty>{noFilterMatchesLabel}</CommandEmpty>}
              <CommandGroup>
                {list.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.value}
                    onSelect={(currentValue) => {
                      const selected = list.find(({ value }) => value === currentValue)
                      onSelect(selected === value ? null : selected)
                      setOpen(false)
                    }}
                  >
                    {item.label}
                    <Check
                      className={cn(
                        "ml-auto",
                        value && value.value === item.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
              <CommandGroup forceMount>
                {isNewItemsAllowed && isNoMatches && (
                  <CommandItem forceMount value={inputValue} onSelect={(value) => {
                    onSelect({ label: value, value: value }, true);
                    setOpen(false);
                  }}>
                    <Check className="mr-2 h-4 w-4 opacity-0" />
                    Add "{inputValue}"
                  </CommandItem>)
                }
              </CommandGroup>

            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {value && value?.value && (
        <X
          className="cursor-pointer h-4 w-4 shrink-0 opacity-50"
          onClick={(e) => {
            onSelect(null)
          }}
          aria-label="Clear selection"
        />
      )}
    </div>
  )
}

export default ComboBox