import { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Map from "../core/Map";
import { StatusInfo } from "../common/amenities";
import {
  SELECTED_LISTING_MAP_MARKER_COLOR,
  FALLBACK_MAP_MARKER_COLOR,
} from "../../constants/listing";
import {
  getListingPriceUsingListingType,
  getListingTitleUsingListingType,
} from "../../utils/listings";
const SearchPageMap = ({
  listings,
  mapCenter,
  getListingGeoLocation,
  selectedListing,
  provideMapMarkerColor,
  onMarkerClick,
}) => {
  const [hoveredListing, setHoveredListing] = useState(null);
  const [hoveredListingPosition, setHoveredListingPosition] = useState(null);
  return (
    <Map mapCenter={mapCenter}>
      <>
        {/* Hide the close button with css as there is no option provided to do so  */}
        <style>
          {`
          .gm-style-iw-chr {
            display: none;
          }
        `}
        </style>
        {listings
          ?.filter((listing) => getListingGeoLocation(listing))
          .map((listing, index) => {
            let markerColor;
            if (selectedListing?.id === listing?.id) {
              markerColor = SELECTED_LISTING_MAP_MARKER_COLOR;
            } else {
              markerColor = provideMapMarkerColor
                ? provideMapMarkerColor(listing)
                : FALLBACK_MAP_MARKER_COLOR;
            }

            if (!markerColor) {
              markerColor = FALLBACK_MAP_MARKER_COLOR;
            }

            const listingGeolocation = getListingGeoLocation(listing);

            return (
              <Marker
                key={index}
                position={listingGeolocation}
                onClick={() => {
                  onMarkerClick(listing);
                }}
                onMouseOver={() => {
                  setHoveredListing(listing);
                  setHoveredListingPosition(listingGeolocation);
                }}
                onMouseOut={() => {
                  setHoveredListing(null);
                  setHoveredListingPosition(null);
                }}
                icon={{
                  // Icon taken from https://tabler.io/icons
                  url: `data:image/svg+xml;charset=UTF-8,<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="${encodeURIComponent(
                    markerColor
                  )}"  class="icon icon-tabler icons-tabler-filled icon-tabler-map-pin"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18.364 4.636a9 9 0 0 1 .203 12.519l-.203 .21l-4.243 4.242a3 3 0 0 1 -4.097 .135l-.144 -.135l-4.244 -4.243a9 9 0 0 1 12.728 -12.728zm-6.364 3.364a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z" /></svg>`,
                  ...(window?.google?.maps?.Size && {
                    scaledSize: new window.google.maps.Size(40, 40),
                  }),
                }}
              >
                {hoveredListing === listing && (
                  <InfoWindow
                    position={hoveredListingPosition} // Ensure position prop is correctly passed
                    options={{
                      ...(window?.google?.maps?.Size && {
                        pixelOffset: new window.google.maps.Size(0, -30),
                      }),
                    }}
                  >
                    <div className="pt-[12px]">
                      <StatusInfo listing={hoveredListing} />
                      <p className="font-semibold text-[20px] text-[#666666] text-wrap	">
                        <span className="hover:text-[#F27935] leading-5 align-bottom">
                          {getListingTitleUsingListingType(hoveredListing)}
                        </span>
                      </p>
                      <p className="text-[16px]  leading-6 mt-2 whitespace-nowrap text-[#666666]">
                        Price: {getListingPriceUsingListingType(hoveredListing)}
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
      </>
    </Map>
  );
};

export default SearchPageMap;
