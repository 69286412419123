import React, { useMemo } from "react";
import * as FaIcons from "react-icons/fa";
import Page from "./Page";
import ToolbarButton from "./ToolbarButton";

const Pagination = ({
  numRecords = 0,
  pageSize = 0,
  currentPage = 1,
  onPageChange,
  suppressPageSelector = false,
  suppressNavigationButtons = false,
  PageProps = {},
}) => {
  const numPages = useMemo(
    () => Math.ceil(numRecords / pageSize),
    [numRecords, pageSize]
  );

  return (
    <>
      <div className="lv-pagination flex gap-2 items-start">
        {!suppressPageSelector && (
          <Page
            value={currentPage}
            numPages={numPages}
            onChange={onPageChange}
            {...PageProps}
          />
        )}
        {!suppressNavigationButtons && (
          <div className="flex gap-2 items-center text-base">
            <ToolbarButton
              icon={<FaIcons.FaChevronLeft />}
              disabled={currentPage === 1}
              onClick={(e) => {
                if (currentPage > 1) {
                  onPageChange(currentPage - 1);
                }
              }}
            />
            <ToolbarButton
              icon={<FaIcons.FaChevronRight />}
              disabled={currentPage === numPages}
              onClick={(e) => {
                if (currentPage < numPages) {
                  onPageChange(currentPage + 1);
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Pagination;
