export default function exportToCsv(filename: string, rows: object[], headers?: string[]): void {
    if (!rows || !rows.length) {
        console.error('No data provided');
        return;
    }

    const separator = ',';
    const keys = Object.keys(rows[0]);
    const columnHeaders = headers && headers.length === keys.length ? headers : keys;

    const csvContent =
        'sep=,\n' +
        columnHeaders.join(separator) +
        '\n' +
        rows
            .map(row => {
                return keys
                    .map(key => {
                        let cell = row[key] === null || row[key] === undefined ? '' : row[key];
                        cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
                        if (cell.search(/("|,|\n)/g) >= 0) {
                            cell = `"${cell}"`;
                        }
                        return cell;
                    })
                    .join(separator);
            })
            .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error('Download attribute not supported in this browser');
    }
}

