import React, { useMemo, useState } from "react";
import ComboBox from "../core/ComboBox";
import {
  Table,
  TableHead,
  TableCell,
  TableHeader,
  TableBody,
  TableCell as BaseTableCell,
  TableHead as BaseTableHead,
  TableHeader as BaseTableHeader,
  TableRow,
} from "../core/Table";

const AboutSection = ({ title, children }) => (
  <div className="flex flex-col gap-1">
    <p className="font-semibold leading-5 text-[14px]">{title}</p>
    <p className="leading-5 text-[14px] text-[#999999]">{children}</p>
  </div>
);

const MapFields = ({ fileColumnsKeys, mapping, fieldsConfig, onMappingChange }) => {
  const columnsList = useMemo(
    () =>
      (fileColumnsKeys || []).map((fileColumnKey) => ({
        id: fileColumnKey,
        label: fileColumnKey,
        value: fileColumnKey,
      })),
    [fileColumnsKeys]
  );

  return (
    <section className="flex flex-col gap-8 h-screen pageMargin">
      <div className="grid grid-cols-2 gap-5">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Our Fields</TableHead>
              <TableHead>Your Fields</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {mapping.map(({ mappedKey, key }, index) => {
              const { label, isRequired, isRecommended } = fieldsConfig.find(
                (field) => field.key === key
              );
              return (
                <TableRow key={`map-fields-${key}`}>
                  <TableCell className="text-[#666666]">{label}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <ComboBox
                        placeholder="Select your field"
                        emptyText="No matching fields"
                        list={columnsList}
                        value={
                          mappedKey
                            ? {
                                label: mappedKey,
                                value: mappedKey,
                              }
                            : null
                        }
                        onSelect={(newSelected) => {
                          onMappingChange({
                            key: key,
                            mappedKey: newSelected ? newSelected.value : null,
                          });
                        }}
                      />
                      {isRecommended && (
                        <span className="text-[#E56322]">Recommended</span>
                      )}
                      {isRequired && (
                        <span className="text-[#E56322]">Required</span>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="flex flex-col gap-6 max-w-[456px]">
          <p className="text-[24px] leading-[28.8px] font-semibold text-[#666]">
            About Field Mapping
          </p>
          <AboutSection title="Basics">
            The left column “Our Fields” are the fields that Landvoice uses for
            lookup. The right column “Your Fields” correspond to the headers in
            the spreadsheet that you uploaded. Do your best to match your fields
            to ours, so that our lookup service can be as accurate as possible.
          </AboutSection>
          <AboutSection title="Required Fields">
            To look up a phone number, a valid address field must be provided.
            To look up an address, a valid phone number field must be provided.
            Providing a name will greatly improve the accuracy of the search
            results. Email and social media information will be provided to the
            best of our ability regardless of search type.
          </AboutSection>
          <AboutSection title="Reference Spreadsheet">
            At any point, you can go back to the previous screen to refer back
            to your spreadsheet. We’ll save your progress here.
          </AboutSection>
          <AboutSection title="Address Formatting">
            If your spreadsheet has addresses all in one line, match that field
            to the first dropdown selector on the “address” row. If your
            spreadsheet has addresses split up into various fields, please match
            each field to ours.
          </AboutSection>
        </div>
      </div>
    </section>
  );
};

export default MapFields;
