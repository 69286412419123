import React from "react";
import { FaTimesCircle } from "react-icons/fa";
import { MODAL_CONTENT, MODAL_BACKGROUND } from "../../constants/zIndexes";

const Modal = ({
  children,
  onClose,
  open,
  title,
  mxWidth = "md:max-w-[695px] ",
}) => {
  return (
    <>
      {open ? (
        <div
          className={
            "lv-modal fixed left-0 top-0 right-0 bottom-0 w-screen h-screen flex justify-center md:p-4"
          }
          style={{
            zIndex: MODAL_CONTENT,
          }}
        >
          <div
            onClick={onClose}
            className="absolute left-0 top-0 right-0 bottom-0 w-screen h-screen bg-black opacity-[0.75] "
            style={{
              zIndex: MODAL_BACKGROUND,
            }}
          >
            {" "}
          </div>
          <div
            id="modal_content"
            className={
              " my-auto m-4 min-w-[250px] md:rounded-lg mt-[60px] md:mt-16 mt:0  w-full  md:min-h-[200px] top-[80px] mx-auto bg-white p-4 lg:p-8 " +
              mxWidth
            }
            style={{
              zIndex: MODAL_CONTENT,
            }}
          >
            <div className=" flex justify-between pb-1">
              <p className=" text-[28px] text-[#666666]">{title}</p>{" "}
              <FaTimesCircle
                onClick={onClose}
                className="cursor-pointer h-8 w-8 text-[#666666] hover:text-[#E2631B]"
              />
            </div>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
