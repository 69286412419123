import { useState, useRef, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useReactToPrint } from "react-to-print";
import fetcher from "../utils/fetcher";
import { removeDuplicateContactsAndMarkOriginals } from "src/utils/listings";

const useListingDetails = ({
  apiUrl,
  onNotesSaved,
  onTagsSaved,
  productType,
}) => {
  const [notesOpen, setNotesOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [showFullNote, setShowFullNote] = useState(false);
  const queryClient = useQueryClient();
  const queryKey = ["getListingDetails", apiUrl];
  const getListingDetailsQuery = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const resp = await fetcher({
        url: apiUrl,
      });
      return { ...resp.data, productType };
    },
  });

  const { data: listing, isError, isLoading } = getListingDetailsQuery;

  const toggleNotesDialog = () => {
    setNotesOpen((cur) => !cur);
  };

  const toggleTagsDialog = () => {
    setTagsOpen((cur) => !cur);
  };

  const optimisticUpdateListing = (updatedListing) => {
    const prevListing = queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, updatedListing);
    return { prevListing, updatedListing };
  };

  const handleOnNotesSaved = (newNotes) => {
    optimisticUpdateListing({ ...listing, notes: newNotes });
    onNotesSaved(newNotes);
  };

  const handleOnTagsSaved = (newTagsArray) => {
    optimisticUpdateListing({ ...listing, tags: newTagsArray });
    onTagsSaved([...newTagsArray]);
  };

  const printOneViewComponentRef = useRef(null);
  const printOne = useReactToPrint({
    contentRef: printOneViewComponentRef,
  });

  const processedContacts = useMemo(
    () => removeDuplicateContactsAndMarkOriginals(listing?.contacts || []),
    [listing?.contacts]
  );

  return {
    listing,
    isError,
    isLoading,
    processedContacts,
    printOne,
    printOneViewComponentRef,
    handleOnNotesSaved,
    handleOnTagsSaved,
    notesOpen,
    toggleNotesDialog,
    tagsOpen,
    toggleTagsDialog,
    showFullNote,
    setShowFullNote,
    getListingDetailsQuery,
    optimisticUpdateListing,
  };
};

export default useListingDetails;
