import React from "react";

const PrimaryButtons = ({
  label,
  onClick,
  downloading = false,
  minWidth = 60,
  width,
  isDisable = false,
}) => {
  return (
    <button
      className={`min-w-[${minWidth}px] ${
        width ? `w-[${width}px]` : ""
      } h-10 rounded-lg bg-[#F27935] disabled:bg-[#836e61f9] hover:bg-[#E2631B] selection:bg-[#C04E0E] py-[6px] px-4 cursor-pointer disabled:cursor-default`}
      onClick={onClick}
      disabled={isDisable ? isDisable : downloading}
    >
      {downloading ? (
        <div className=" w-full flex justify-center">
          <div className="w-4 h-4 border-2  border-solid  border-t-0 border-r-0 border-white rounded-full animate-spin"></div>
        </div>
      ) : (
        <p className="text-[14px] text-center font-semibold leading-7 text-[#FFFFFF]">
          {label}
        </p>
      )}
    </button>
  );
};

const SecondaryButtons = ({ label, onClick, minWidth = 60, width }) => {
  return (
    <div
      className={`min-w-[${minWidth}px] ${
        width ? `w-[${width}px]` : ""
      } h-10 rounded-lg bg-[#E8EDF0] hover:bg-[#D5DDE1] selection:bg-[#BFC7CC] py-[6px] px-4 cursor-pointer`}
      onClick={onClick}
    >
      <p className="text-[14px] whitespace-nowrap font-semibold text-center leading-7 text-[#666666]">
        {label}
      </p>
    </div>
  );
};

const OutlineButtons = ({ label, onClick, minWidth = 60, width }) => {
  return (
    <div
      className={`min-w-[${minWidth}px] ${
        width ? `w-[${width}px]` : ""
      } outline outline-1 w-full h-10 rounded-lg  hover:bg-[#D5DDE1] selection:bg-[#BFC7CC] py-[6px] px-4 cursor-pointer`}
      onClick={onClick}
    >
      <p className="text-[14px] font-semibold text-center leading-7 text-[#666666]">
        {label}
      </p>
    </div>
  );
};

export { PrimaryButtons, SecondaryButtons, OutlineButtons };
