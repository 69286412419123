import React, { lazy, Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { OverlayScrollbars } from "overlayscrollbars";
import "overlayscrollbars/overlayscrollbars.css";
import "./index.css";
import "./App.css";

import { Toaster } from "./components/shadcn/Toaster";
import SidebarProvider from "./providers/SidebarProvider";
import SplashScreen from "./components/SplashScreen";
import ErrorBoundary from "./components/utils/error/ErrorBoundary";
import { AuthProvider } from "./components/utils/auth/assist/authContext";
import ImportedListingsPage from "./routes/ImportedListingsPage";
const Login = lazy(() => import("./routes/Login"));
const ForgotPassword = lazy(() => import("./routes/ForgotPassword"));
const Expired = lazy(() => import("./routes/ExpiredLeads"));
const Settings = lazy(() => import("./routes/Settings"));
const ExpiredUploadPage = lazy(() => import("./routes/ExpiredUploadPage"));
const OldExpired = lazy(() => import("./routes/OldExpired"));
const Fsbo = lazy(() => import("./routes/FsboLeads"));
const Preforeclosures = lazy(() => import("./routes/PreforeclosuresLeads"));
const Distressed = lazy(() => import("./routes/Distressed"));
const Scripts = lazy(() => import("./routes/Scripts"));
const ResetPassword = lazy(() => import("./routes/ResetPassword"));
const FouroFour = lazy(() => import("./routes/FouroFour"));
const AppLayout = () => (
  <div>
    <Toaster />
    <Outlet className={"hideScrollBar"} />
  </div>
);

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<SplashScreen />}>{children}</Suspense>
);

const router = createBrowserRouter([
  {
    element: <AppLayout className={"hideScrollBar"} />,
    children: [
      {
        path: "/signin/",
        element: (
          <SuspenseWrapper>
            <Login />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/forgotpassword",
        element: (
          <SuspenseWrapper>
            <ForgotPassword />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/",
        element: (
          <SuspenseWrapper>
            <Expired />
          </SuspenseWrapper>
        ),
      },
      {
        path: "settings",
        element: (
          <SuspenseWrapper>
            <Settings />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/expired",
        element: (
          <SuspenseWrapper>
            <Expired />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/expireduploads",
        element: (
          <SuspenseWrapper>
            <ExpiredUploadPage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/oldexpired",
        element: (
          <SuspenseWrapper>
            <OldExpired />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/fsbo",
        element: (
          <SuspenseWrapper>
            <Fsbo />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/preforeclosure",
        element: (
          <SuspenseWrapper>
            <Preforeclosures />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/distressed",
        element: (
          <SuspenseWrapper>
            <Distressed />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/data-genie/*",
        element: (
          <SuspenseWrapper>
            <ImportedListingsPage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/neighborhoodsearch",
        element: (
          <SuspenseWrapper>
            <Preforeclosures />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/scripts",
        element: (
          <SuspenseWrapper>
            <Scripts />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/help",
        element: (
          <SuspenseWrapper>
            <Fsbo />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/accountsettings",
        element: (
          <SuspenseWrapper>
            <Settings />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/forgotpassword/:token",
        element: (
          <SuspenseWrapper>
            <ResetPassword />
          </SuspenseWrapper>
        ),
      },
      {
        path: "*",
        element: (
          <SuspenseWrapper>
            <FouroFour />
          </SuspenseWrapper>
        ),
      },
    ],
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  useEffect(() => {
    // Apply OverlayScrollbars to the body
    OverlayScrollbars(document.body, {
      scrollbars: {
        autoHide: "scroll",
      },
    });

    // Clean up on unmount
    return () => {
      OverlayScrollbars(document.body).destroy();
    };
  }, []);

  return (
    <ErrorBoundary>
      {/* <React.StrictMode> */}
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <SidebarProvider>
            <RouterProvider router={router} />
          </SidebarProvider>
        </QueryClientProvider>
      </AuthProvider>
      {/* </React.StrictMode> */}
    </ErrorBoundary>
  );
};

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
