import { Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { SELECT_DROPDOWN } from "../../constants/zIndexes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MultipleSelect({
  onChange,
  heading = "",
  selectionColor = true,
  list = [],
  flowWidth = false,
  above = false,
  value,
  check = true,
  positionLeft = true,
  isLoading = false,
  headerItemFormatter = (value) => value,
}) {
  const buttonLabel = useMemo(() => {
    const headingLabel = heading ? `${heading} ` : "";

    if (value.length === list.length || value.length === 0) {
      return `${headingLabel}Show all`;
    }

    return `${headingLabel}${value
      .map((item) => headerItemFormatter(item?.label))
      .join(", ")}`;
  }, [heading, list, value, headerItemFormatter]);

  return (
    <Listbox value={value} onChange={onChange} multiple>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`rounded-[4px] w-full flex justify-between gap-2 cursor-default h-9 bg-[#F5F7F7] items-center border-[1px] border-[#E0E0E0] text-[14px] font-[400] leading-[20px] text-[#666666]`}
            >
              <span className="flex items-center">
                <span className="ml-3 block truncate">{buttonLabel}</span>
              </span>
              {isLoading && (
                <div className="w-4 h-4 border-2 border-solid border-t-blue-500 border-[#F27935] rounded-full animate-spin"></div>
              )}
              <span className="pointer-events-none inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={
                above
                  ? "absolute mb-1 w-full rounded-md bg-white shadow-lg bottom-full"
                  : ""
              }
            >
              <Listbox.Options
                className={
                  flowWidth
                    ? `selectDropDown min-w-200 ${
                        positionLeft ? "" : "right-0"
                      }`
                    : `selectDropDown w-full ${positionLeft ? "" : "right-0"}`
                }
                style={{
                  zIndex: SELECT_DROPDOWN,
                }}
              >
                {list.map((option) => (
                  <Listbox.Option
                    key={`multiselect-listbox-option-${option.id}`}
                    className={({ active }) =>
                      classNames(
                        value.includes(option) && selectionColor
                          ? "bg-[#F27935] text-white"
                          : "text-[#666666]",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ value, active }) => (
                      <>
                        <div
                          className="flex items-center "
                          onClick={
                            option.clickableFunction
                              ? option.clickableFunction
                              : () => {}
                          }
                        >
                          <span
                            className={classNames(
                              value ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {value ? (
                          <span
                            className={classNames(
                              selectionColor ? "text-white " : "text-[#F27935]",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {check && (
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
