export const pick = (obj, ...keys) => {
  const res = Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  );
  return res;
};

export const inclusivePick = (obj, ...keys) =>
  Object.fromEntries(keys.map((key) => [key, obj[key]]));

export const omit = (obj, ...keys) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  );

export const convertObjectToArray = (obj) =>
  Object.entries(obj).map(([key, value]) => ({
    name: key,
    value: value,
  }));
