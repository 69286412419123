const sortingOptions = [
  { name: "Price (High to Low)", value: { sort_dir: "desc", sort: "price"} },
  { name: "Price (Low to High)", value: { sort_dir: "asc", sort: "price"} },
  { name: "Published Date (Newest)", value:  { sort_dir: "desc", sort: "date"} },
  { name: "Published Date (Oldest)", value: { sort_dir: "asc", sort: "date"} },

  { name: "Status Date (Newest)", value: { sort_dir: "desc", sort: "status_date"} },
  { name: "Status Date (Oldest)", value: { sort_dir: "asc", sort: "status_date"} },
  { name: "Days on Market (High to Low)", value: { sort_dir: "desc", sort: "dom"} },
  { name: "Days on Market (Low to High)", value: { sort_dir: "asc", sort: "dom"} },
  { name: "City", value: { sort_dir: "asc", sort: "city"} },
  { name: "State", value: { sort_dir: "desc", sort: "state"} },

  { name: "ZIP (High to Low)", value: { sort_dir: "desc", sort: "postal_code"} },
  { name: "ZIP (Low to High)", value: { sort_dir: "asc", sort: "postal_code"} },
  { name: "Sq Ft (High to Low)", value: { sort_dir: "desc", sort: "sq_ft"} },
  { name: "Sq Ft (Low to High)", value: { sort_dir: "asc", sort: "sq_ft"} },
  { name: "Lot Size (High to Low)", value: { sort_dir: "desc", sort: "lot_size"} },
  { name: "Lot Size (Low to High)", value: { sort_dir: "asc", sort: "lot_size"} },
  { name: "Year Built (Newest)",  value: { sort_dir: "desc", sort: "year_built"} },
  { name: "Year Built (Oldest)", value: { sort_dir: "asc", sort: "year_built"} },
  { name: "Auction Date (Newest)", value: { sort_dir: "desc", sort: "auction_date"} },
  { name: "Auction Date (Oldest)", value: { sort_dir: "asc", sort: "auction_date"} },
];

const distressedSortingOptions = [
  { name: "Price (High to Low)" },
  { name: "Price (Low to High)" },
  { name: "Published Date (Newest)" },
  { name: "Published Date (Oldest)" },
  { name: "City" },
  { name: "State" },
  { name: "ZIP (High to Low)" },
  { name: "ZIP (Low to High)" },
  { name: "Sq Ft (High to Low)" },
  { name: "Sq Ft (Low to High)" },
  { name: "Lot Size (High to Low)" },
  { name: "Lot Size (Low to High)" },
  { name: "Year Built (Newest)" },
  { name: "Year Built (Oldest)" },
  { name: "Distressed level (High to Low)" },
  { name: "Distressed level (Low to High)" },
];

const DistressedNamesToRemove = [
  "Days on Market (High to Low)",
  "Days on Market (Low to High)",
  "Auction Date (Newest)",
  "Auction Date (Oldest)",
  "Status Date (Newest)",
  "Status Date (Oldest)",
];

const communicationTypeFilters = [
  { name: "Show all ", value: "" },
  { name: "Show only with phone numbers", value: "phones-only" },
  { name: "Show only without phone numbers", value: "no-phones" },
  { name: "Show only with email", value: "email-only" },
];

const searchDateTypeFilters = [
  { name: "Delivery Date", short: "Delivery", value: "file_upload_date" },
  { name: "Status Date", short: "Status", value: "listing_status_date" },
];

const distressedLevel = [
  { name: "0 - Not Distressed", value: "0" },
  { name: "1 - Somewhat Distressed", value: "1" },
  { name: "2 - Fairly Distressed", value: "2" },
  { name: "3 - Highly Distressed", value: "3" },
  { name: "4 - Extremely Distressed", value: "4" },
];

const preforeclosureStatusTypesList = [
  { name: "Lis Pendens", code: "L" },
  { name: "Notice of Default", code: "D" },
  { name: "Notice of Sale", code: "S" },
  { name: "Notice of Trustee Sale", code: "T" },
  { name: "Bank Owned (REO)", code: "B" },
];

const showActive = [{ name: "Show Actves" }, { name: "Do Not Show Actives" }];

const bedsList = [
  { name: "Any", value: "" },
  { name: "2+", value: "2" },
  { name: "3+", value: "3" },
  { name: "4+", value: "4" },
  { name: "5+", value: "5" },
  { name: "6+", value: "6" },
  { name: "7+", value: "7" }, 
  { name: "10+", value: "10" },
];

const bathsList = [
  { name: "Any", value: "" },
  { name: "2+", value: "2" },
  { name: "3+", value: "3" },
  { name: "4+", value: "4" },
  { name: "5+", value: "5" },
  { name: "8+", value: "8" },
];

const squareFeet = [
  { name: "Any", value: "" },
  { name: "1000+", value: "1000" },
  { name: "1500+", value: "1500" },
  { name: "2000+", value: "2000" },
  { name: "2500+", value: "2500" },
  { name: "3000+", value: "3000" },
  { name: "4000+", value: "4000" },
  { name: "6000+", value: "6000" },
];

export const statusList = [
  { name: "Expired", value: "X" },
  { name: "Pending", value: "P" },
  { name: "Withdrawn", value: "W" },
  { name: "Off Market", value: "O" },
  { name: "Canceled", value: "C" },
  { name: "Released", value: "R" },
  { name: "Active", value: "A" },
  { name: "Rented", value: "T" },
  { name: "Sold", value: "S" },
  { name: "Unavailable", value: "N" },
]

const PropertyTypeEnum = {
  S: "Single Family",
  H: "Home",
  C: "Condo",
  W: "Twin",
  T: "Townhouse",
  M: "Mobile",
  R: "Recreational",
  G: "Acreage",
  A: "Apartment",
  O: "Other",
  X: "Multiplex",
};

const StatusTypeEnum = {
  A: "Active",
  P: "Pending",
  S: "Sold",
  X: "Expired",
  W: "Withdrawn",
  C: "Canceled",
  O: "Off Market",
  U: "Unknown"
};

const PreforeclosureStatusTypeEnum = {
  A: "Active",
  D: "Notice of Default",
  L: "Lis Pendens",
  T: "Notice of Trustee Sale",
  S: "Notice of Sale",
  B: "Bank Owned",
  U: "Unknown",
};

export {
  sortingOptions,
  distressedSortingOptions,
  communicationTypeFilters,
  searchDateTypeFilters,
  showActive,
  bedsList,
  bathsList,
  squareFeet,
  PropertyTypeEnum,
  StatusTypeEnum,
  PreforeclosureStatusTypeEnum,
  distressedLevel,
  DistressedNamesToRemove,
  preforeclosureStatusTypesList
};
