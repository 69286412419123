import React, { createContext, useState, useContext, useEffect } from "react";

// Create a Context for the sidebar state
export const SidebarContext = createContext();

// Create a Provider component
const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(
    localStorage.getItem("isSidebarOpen") === "true" || false
  );

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem("isSidebarOpen", String(isSidebarOpen));
  }, isSidebarOpen);

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
