import { SEARCH_PAGE_DETAILS_OVERLAY } from "../../constants/zIndexes";
import { Print } from "@landvoice/icons";
import { FaTimesCircle } from "react-icons/fa";
import PrintOneView from "../common/PrintOneView";
import ListingSkeleton from "./ListingSkeleton";
import AddNotes from "../common/addNotes";
import AddTags from "../common/addTags";
import { MlsTitleAmenities } from "../common/amenities";
import NavigateListings from "./NavigateListings";
import { MOBILE_HEADER_HEIGHT } from "../../constants/ui";
const ListingDetails = ({
  onClose,
  listingId = null,
  listingDetails,
  title,
  AddTagsProps,
  AddNotesProps,
  PrintOneViewPropsProvider,
  mlsTitleAmenitiesPropsProvider,
  children,
  onNextListingClick,
  onPreviousListingClick,
}) => {
  const {
    printOne,
    printOneViewComponentRef,
    listing,
    isLoading,
    isError,
    notesOpen,
    tagsOpen,
    toggleNotesDialog,
    toggleTagsDialog,
    handleOnNotesSaved,
    handleOnTagsSaved,
  } = listingDetails;
  return (
    <section
      className="details-page-root fixed top-0 right-0 bottom-0 w-screen h-screen justify-center lg:w-[825px]"
      style={{
        zIndex: SEARCH_PAGE_DETAILS_OVERLAY,
        boxShadow:
          "var(--elevation-drawer, 0 8px 32px 0 rgba(13, 22, 25, 0.1607843137)), 0 -1px 0 0 var(--color-border-neutral-faded, #e5e5e5)",
      }}
    >
      <div
        className="dark-overlay hidden absolute left-0 top-0 right-0 bottom-0 bg-black bg-opacity-75"
        style={{
          zIndex: 9,
        }}
        onClick={() => onClose()}
      ></div>
      <div
        className={`content-pane absolute lg:right-0 flex-col gap-12 h-screen w-screen lg:w-[825px] bg-[#FFFFFF] overflow-hidden md:pt-12 pt-4 mt-[${MOBILE_HEADER_HEIGHT}px] md:mt-0 border-l`}
        style={{
          zIndex: 10,
        }}
      >
        <div className="flex justify-between print:mt-0 print:hidden md:px-12 px-4">
          <div className="flex gap-6 items-center">
            <p className="text-[20px] md:text-[28px] text-[#666666]">
              {title} {listingId}
            </p>
            <Print className={"cursor-pointer"} onClick={printOne} />
          </div>
          <div className="flex gap-3 items-center">
            <div className="md:hidden">
              <NavigateListings
                onNextClick={onNextListingClick}
                onPreviousClick={onPreviousListingClick}
              />
            </div>
            <FaTimesCircle
              onClick={() => onClose()}
              className="cursor-pointer h-8 w-8 text-[#666666] hover:text-[#E2631B]"
            />
          </div>
        </div>
        <div className="w-full print:pb-0 h-screen overflow-y-auto overflow-x-hidden print:hidden pt-[10px] md:px-12 px-4 pb-[150px]">
          {isLoading && <ListingSkeleton />}
          {!isLoading && !isError && listing && (
            <>
              {notesOpen && (
                <AddNotes
                  onClose={toggleNotesDialog}
                  open
                  notes={listing?.notes}
                  onSaved={handleOnNotesSaved}
                  {...AddNotesProps}
                />
              )}
              {tagsOpen && (
                <AddTags
                  onClose={toggleTagsDialog}
                  open
                  tags={listing?.tags}
                  onSaved={handleOnTagsSaved}
                  {...AddTagsProps}
                />
              )}
              <MlsTitleAmenities
                listing={listing}
                {...mlsTitleAmenitiesPropsProvider(listing)}
                openNotes={toggleNotesDialog}
              />
              {children}
            </>
          )}
        </div>
      </div>
      <section
        ref={printOneViewComponentRef}
        className="hidden print:block m-4"
      >
        {!isLoading && !isError && listing && (
          <PrintOneView
            property={listing}
            {...PrintOneViewPropsProvider(listing)}
          />
        )}
      </section>
    </section>
  );
};

export default ListingDetails;
