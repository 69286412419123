import useMlsListBase from "../../hooks/useMlsList";
import { useMemo } from "react";
import { sortStringFieldAlpha } from "../../utils/arrays";

const useMlsList = () => {
  const { data: list, isLoading, isError } = useMlsListBase();

  return useMemo(() => {
    if (isLoading || isError) {
      return [];
    }

    return [
      { state: "All", name: "All", customer_mls_id: "" },
      ...sortStringFieldAlpha(list || [], "name"),
    ];
  }, [list, isLoading, isError]);
};
export default useMlsList;
