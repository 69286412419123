
interface FieldWrapperProps {
  fieldLabel: string
  children: React.ReactNode;
  rootClassName?: string;
  contentClassName?: string;
}

export function FieldWrapper({ fieldLabel, rootClassName, contentClassName, children }: FieldWrapperProps) {
  return (
    <div className={`pt-[11px] ${rootClassName ? `${rootClassName}` : ""}`}>
      <div className={`flex flex-row relative border border-gray-300 rounded-md p-1 pt-2 w-[300px] ${contentClassName ? `${contentClassName}` : ""}`}>
        <span className="absolute -top-3 left-4 bg-white px-2 text-sm text-gray-500">
          {fieldLabel}
        </span>
        {children}
      </div>
    </div>
  )
}