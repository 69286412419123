import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../common/Modal";
import { HDivider } from "../common/amenities";
import Checkbox from "../core/Checkbox";
import { LIST_PLACEMENT } from "../common/Select";
import { PrimaryButtons, SecondaryButtons } from "../common/buttons";
import CommType from "./CommType";
import useMlsList from "./useMlsList";
import TextInput, { NumberInput } from "../core/TextInput";
import ScrollContainer from "../core/ScrollContainer";
import { useFeatureDiscovery } from "src/hooks/useFeatureDiscovery";
import FeatureDiscoveryTooltip from "../FeatureDiscoveryTooltip";
import { MODAL_CONTENT } from "src/constants/zIndexes";
import MultiSelectCombobox, {
  useMultiSelectCombobox,
} from "../core/MultiSelectComboBox";
import {
  communicationTypeFilters,
  statusList,
  bathsList,
  bedsList,
  searchDateTypeFilters,
  squareFeet as squareFeetList,
  distressedLevel as distressedLevelList,
  preforeclosureStatusTypesList,
} from "../../data/selectionData";
import DatePicker from "../DatePicker";
import MultipleSelect from "../common/MultipleSelect";
import Select from "../common/Select";

import {
  useGetCustomUploadsQuery,
  useGetCustomUploadsSourcesQuery,
} from "src/hooks/useCustomUploads";

const findValueByName = (arr, targetName) => {
  const result = arr.find((item) => item.name === targetName);
  return result ? result.value : null; // Return value if found, otherwise null
};

const findDefaultValueByName = (arr, targetName) => {
  const result = arr.find((item) => item.name === targetName);
  return result && Object.hasOwn(result, "defaultValue")
    ? result.defaultValue
    : "";
};

const SearchFilterLayout = ({ label, children }) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-[#999999] text-[14px] font-semibold">{label}</p>
      {children}
    </div>
  );
};

const SearchFilters = ({
  onClose,
  onComplete,
  showListingStatus = false,
  showSearchDateType = false,
  showMlsFilter = false,
  showLookupNameFilter = false,
  showLookupSourceFilter,
  importedListingsImports = [],
  showIncludeActiveOnMLS = false,
  showDistressedFilters = false,
  showPreForeclosureStatus = false,
  suppressPriceFilters = false,
  title = "Filters",
  filters = [],
}) => {
  const mlsList = useMlsList();
  const { data: lookups } = useGetCustomUploadsQuery();
  const { data: sources } = useGetCustomUploadsSourcesQuery();
  const {
    selectedItems: lookupSources,
    handleSelect: handleSelectLookupSource,
    handleRemove: handleRemoveLookupSource,
    resetSelectedItems: resetLookupSources,
  } = useMultiSelectCombobox({
    initialSelectedItems: (findValueByName(filters, "lookupSources") || []).map(
      (source) => ({ label: source, value: source, id: source })
    ),
  });

  const features = useMemo(
    () => ["filter-by-import-name", "filter-by-import-source"],
    []
  );

  const { currentFeature, nextFeature, isLastFeature, shouldShowTooltip } =
    useFeatureDiscovery(features);

  const {
    selectedItems: lookupIds,
    handleSelect: handleSelectLookupName,
    handleRemove: handleRemoveLookupName,
    resetSelectedItems: resetLookupNames,
  } = useMultiSelectCombobox({
    initialSelectedItems: (findValueByName(filters, "lookupIds") || []).reduce(
      (acc, lookupId) => {
        const importedListing = importedListingsImports.find(
          ({ id }) => `${id}` === lookupId
        );

        return importedListing
          ? [
              ...acc,
              {
                id: importedListing.id,
                label: importedListing.name,
                value: importedListing.id,
              },
            ]
          : acc;
      },
      []
    ),
  });

  const [selectedListingStatuses, setSelectedListingStatuses] = useState(
    findValueByName(filters, "status") || []
  );

  const [distressedLevel, setDistressedLevel] = useState(
    findValueByName(filters, "distressedLevel") || []
  );

  const [preForeclosureStatus, setPreForeclosureStatus] = useState(
    findValueByName(filters, "preForeclosureStatus") || []
  );

  const [searchDateType, setSearchDateType] = useState(
    findValueByName(filters, "searchDateType")
  );
  const [fromDate, setFromDate] = useState(
    findValueByName(filters, "fromDate")
  );
  const [toDate, setToDate] = useState(findValueByName(filters, "toDate"));
  const [mls, setMls] = useState(findValueByName(filters, "mls") || "");

  const [numBeds, setNumBeds] = useState(
    findValueByName(filters, "numBeds") || ""
  );
  const [numBaths, setNumBaths] = useState(
    findValueByName(filters, "numBaths") || ""
  );
  const [minPrice, setMinPrice] = useState(
    findValueByName(filters, "minPrice")
  );
  const [maxPrice, setMaxPrice] = useState(
    findValueByName(filters, "maxPrice")
  );

  const [commType, setCommType] = useState(
    findValueByName(filters, "commType") || ""
  );

  const [sqFootage, setSqFootage] = useState(
    findValueByName(filters, "sqFootage") || ""
  );
  const [postalCode, setPostalCode] = useState(
    findValueByName(filters, "postalCode") || ""
  );

  const [showMLSActives, setShowActives] = useState(
    findValueByName(filters, "showMLSActives") || false
  );

  const [tags, setTags] = useState(findValueByName(filters, "tags") || "");

  const resetFilters = useCallback(() => {
    setSelectedListingStatuses(findDefaultValueByName(filters, "status"));
    setDistressedLevel(findDefaultValueByName(filters, "distressedLevel"));
    setPreForeclosureStatus(
      findDefaultValueByName(filters, "preForeclosureStatus")
    );
    setSearchDateType(findDefaultValueByName(filters, "searchDateType"));
    setFromDate(findDefaultValueByName(filters, "fromDate"));
    setToDate(findDefaultValueByName(filters, "toDate"));
    setMls(findDefaultValueByName(filters, "mls"));
    setNumBeds(findDefaultValueByName(filters, "numBeds"));
    setNumBaths(findDefaultValueByName(filters, "numBaths"));
    setMinPrice(findDefaultValueByName(filters, "minPrice"));
    setMaxPrice(findDefaultValueByName(filters, "maxPrice"));
    setSqFootage(findDefaultValueByName(filters, "sqFootage"));
    setPostalCode(findDefaultValueByName(filters, "postalCode"));
    setShowActives(findDefaultValueByName(filters, "showMLSActives"));
    setTags(findDefaultValueByName(filters, "tags"));
    setCommType(findDefaultValueByName(filters, "commType"));
    resetLookupNames();
    resetLookupSources();
  }, [filters]);

  const selectedCommType = useMemo(() => {
    const selectedCommTypeOption = communicationTypeFilters.find(
      ({ value }) => value === commType
    );
    if (selectedCommTypeOption) {
      return {
        id: selectedCommTypeOption.name,
        label: selectedCommTypeOption.name,
        value: selectedCommTypeOption.value,
      };
    }
  }, [commType]);

  const numBathsOptions = bathsList.map((option) => ({
    id: option.value,
    label: option.name,
    value: option.value,
  }));

  const selectedNumBathsOption = useMemo(
    () => numBathsOptions.find(({ value }) => value === numBaths),
    [numBathsOptions, numBaths]
  );

  const preForeclosureStatusOptions = preforeclosureStatusTypesList.map(
    (option) => ({
      id: option.code,
      label: option.name,
      value: option.code,
    })
  );

  const selectedPreForeclosureStatusOptions = useMemo(
    () =>
      preForeclosureStatusOptions.filter(({ value }) =>
        preForeclosureStatus.includes(value)
      ),
    [preForeclosureStatusOptions, preForeclosureStatus]
  );

  const distressedLevelOptions = distressedLevelList.map((option) => ({
    id: option.value,
    label: option.name,
    value: option.value,
  }));

  const selectedDistressedLevelOptions = useMemo(
    () =>
      distressedLevelOptions.filter(({ value }) =>
        distressedLevel.includes(value)
      ),
    [distressedLevelOptions, distressedLevel]
  );

  const numBedsOptions = bedsList.map((option) => ({
    id: option.value,
    label: option.name,
    value: option.value,
  }));

  const selectedNumBedsOption = useMemo(
    () => numBedsOptions.find(({ value }) => value === numBeds),
    [numBedsOptions, numBeds]
  );

  const sqFootOptions = squareFeetList.map((option) => ({
    id: option.value,
    label: option.name,
    value: option.value,
  }));

  const selectedSqFootOption = useMemo(
    () => sqFootOptions.find(({ value }) => value === sqFootage),
    [sqFootOptions, sqFootage]
  );

  const searchDateTypeOptions = searchDateTypeFilters.map((option) => ({
    id: option.value,
    label: option.name,
    value: option.value,
  }));

  const selectedSearchDateTypeOption = useMemo(() => {
    const found = searchDateTypeFilters.find(
      ({ value }) => value === searchDateType
    );
    if (found) {
      return {
        id: found.value,
        label: found.name,
        value: found.value,
      };
    }
    return null;
  }, [searchDateType]);

  const mlsOptions = useMemo(
    () =>
      mlsList.map((option) => ({
        id: option.customer_mls_id,
        label: option.name,
        value: `${option.customer_mls_id}`,
      })),
    [mlsList]
  );

  const selectedMlsOption = useMemo(
    () => mlsOptions.find(({ value }) => value === mls),
    [mlsOptions, mls]
  );

  const lookupsNameOptions = useMemo(
    () =>
      (lookups?.imports || [])
        .filter(({ name }) => !!name)
        .map(({ name, id }) => ({
          id,
          label: name,
          value: id,
        })),
    [lookups]
  );

  const lookupSourceOptions = useMemo(
    () =>
      (sources || []).map(({ source }) => ({
        id: source,
        label: source,
        value: source,
      })),
    [sources]
  );

  const handleDistressedLevelChange = useCallback((newValue) => {
    setDistressedLevel(newValue.map((item) => item.value));
  }, []);

  const handlePreForeclosureStatusChange = useCallback((newValue) => {
    setPreForeclosureStatus(newValue.map((item) => item.value));
  }, []);

  const handleShowMLSActivesChange = (newValue) => {
    setShowActives(newValue);
  };

  const handleOnComplete = useCallback(() => {
    onComplete({
      fromDate,
      toDate,
      minPrice,
      maxPrice,
      numBeds,
      numBaths,
      sqFootage,
      postalCode,
      mls,
      searchDateType,
      tags,
      status: selectedListingStatuses,
      showMLSActives,
      distressedLevel,
      preForeclosureStatus,
      commType,
      ...(showLookupSourceFilter && {
        lookupSources: lookupSources.map((item) => item.value),
      }),
      ...(showLookupNameFilter && {
        lookupIds: lookupIds.map((item) => item.value),
      }),
    });
  }, [
    commType,
    onComplete,
    fromDate,
    toDate,
    minPrice,
    maxPrice,
    numBeds,
    numBaths,
    sqFootage,
    postalCode,
    mls,
    searchDateType,
    tags,
    selectedListingStatuses,
    showMLSActives,
    distressedLevel,
    preForeclosureStatus,
    lookupSources,
    lookupIds,
  ]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleOnComplete();
      }
    },
    [handleOnComplete]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    // Removing the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Modal onClose={onClose} open mxWidth={"max-w-[620px]"} title={title}>
      <HDivider />

      <ScrollContainer>
        <div
          id="focusableDiv"
          className=" overscroll-x-none md:h-[calc(70vh-32px)] h-[calc(100vh-250px)] max-w-[100vw]"
        >
          <div className="flex flex-col gap-4 pt-4">
            <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
              <div className=" h-16 gap-1 flex-1">
                <SearchFilterLayout label="From">
                  <DatePicker onValueChange={setFromDate} value={fromDate} />
                </SearchFilterLayout>
              </div>
              <div className=" h-16 gap-1 flex-1">
                <SearchFilterLayout label="To">
                  <DatePicker onValueChange={setToDate} value={toDate} />
                </SearchFilterLayout>
              </div>
            </div>
            {showLookupNameFilter && (
              <SearchFilterLayout label="Name">
                <FeatureDiscoveryTooltip
                  zIndex={MODAL_CONTENT + 1}
                  content="Filter data by import name!"
                  isOpen={shouldShowTooltip("filter-by-import-name")}
                  onNext={nextFeature}
                  isLast={isLastFeature}
                >
                  <MultiSelectCombobox
                    list={lookupsNameOptions}
                    value={lookupIds}
                    onRemove={handleRemoveLookupName}
                    onSelect={handleSelectLookupName}
                    placeholder="Select name(s)"
                  />
                </FeatureDiscoveryTooltip>
              </SearchFilterLayout>
            )}
            {showLookupSourceFilter && (
              <SearchFilterLayout label="Source">
                <FeatureDiscoveryTooltip
                  zIndex={MODAL_CONTENT + 1}
                  content="Filter data by source!"
                  isOpen={shouldShowTooltip("filter-by-import-source")}
                  onNext={nextFeature}
                  isLast={isLastFeature}
                >
                  <MultiSelectCombobox
                    list={lookupSourceOptions}
                    value={lookupSources}
                    onRemove={handleRemoveLookupSource}
                    onSelect={handleSelectLookupSource}
                    placeholder="Select source(s)"
                  />
                </FeatureDiscoveryTooltip>
              </SearchFilterLayout>
            )}
            <div className="flex flex-row gap-10">
              <SearchFilterLayout label="Communication Type">
                <CommType
                  value={selectedCommType}
                  onChange={(newValue) => {
                    setCommType(newValue.value);
                  }}
                />
              </SearchFilterLayout>
              {showIncludeActiveOnMLS && (
                <SearchFilterLayout label="MLS Status">
                  <Checkbox
                    label="Show Actives"
                    checked={showMLSActives}
                    onCheckedChange={handleShowMLSActivesChange}
                  />
                </SearchFilterLayout>
              )}
            </div>

            {showListingStatus && (
              <SearchFilterLayout label="MLS Status">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full">
                  {statusList.map(({ name, value }) => {
                    return (
                      <Checkbox
                        key={`status-checkbox-${value}`}
                        label={name}
                        checked={selectedListingStatuses.includes(value)}
                        onCheckedChange={(newIsChecked) => {
                          if (newIsChecked) {
                            if (!selectedListingStatuses.includes(value)) {
                              setSelectedListingStatuses([
                                ...selectedListingStatuses,
                                value,
                              ]);
                            }
                          } else {
                            setSelectedListingStatuses(
                              selectedListingStatuses.filter(
                                (status) => status !== value
                              )
                            );
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </SearchFilterLayout>
            )}
            {showDistressedFilters && (
              <SearchFilterLayout label="Level Of Distress">
                <MultipleSelect
                  headerItemFormatter={(item) => item.replace(/^\d+\s-\s/, "")}
                  list={distressedLevelOptions}
                  value={selectedDistressedLevelOptions}
                  onChange={handleDistressedLevelChange}
                />
              </SearchFilterLayout>
            )}

            {showPreForeclosureStatus && (
              <SearchFilterLayout label="Pre-Foreclosure Status">
                <MultipleSelect
                  list={preForeclosureStatusOptions}
                  value={selectedPreForeclosureStatusOptions}
                  onChange={handlePreForeclosureStatusChange}
                />
              </SearchFilterLayout>
            )}
            {!suppressPriceFilters && (
              <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
                <div className=" h-16 gap-1 flex-1">
                  <SearchFilterLayout label="Min Price">
                    <NumberInput value={minPrice} onChange={setMinPrice} />
                  </SearchFilterLayout>
                </div>
                <div className=" h-16 gap-1 flex-1">
                  <SearchFilterLayout label="Max Price">
                    <NumberInput value={maxPrice} onChange={setMaxPrice} />
                  </SearchFilterLayout>
                </div>
              </div>
            )}
            <SearchFilterLayout label="Tags">
              <TextInput
                onChange={setTags}
                placeholder={"Ex. tag"}
                value={tags}
              />
              <p className="text-[12px] leading-4 text-[#999999]">
                Separate tags with spaces to match all, or commas to match any
              </p>
            </SearchFilterLayout>
            <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
              <SearchFilterLayout label="Beds">
                <Select
                  SelectionColor={false}
                  value={selectedNumBedsOption}
                  list={numBedsOptions}
                  onChange={(newValue) => {
                    setNumBeds(newValue.value);
                  }}
                  flowWidth={true}
                />
              </SearchFilterLayout>
              <SearchFilterLayout label="Bath">
                <Select
                  numBathsOptions
                  SelectionColor={false}
                  value={selectedNumBathsOption}
                  list={numBathsOptions}
                  onChange={(newValue) => {
                    setNumBaths(newValue.value);
                  }}
                  flowWidth={true}
                />
              </SearchFilterLayout>
            </div>

            <SearchFilterLayout label="Square Feet">
              <Select
                SelectionColor={false}
                setIsActive={() => {}}
                list={sqFootOptions}
                flowWidth={true}
                value={selectedSqFootOption}
                onChange={(newValue) => {
                  setSqFootage(newValue.value);
                }}
              />
            </SearchFilterLayout>

            <SearchFilterLayout label="Postal Code(s)">
              <TextInput
                placeholder={"Ex. 84043"}
                onChange={setPostalCode}
                value={postalCode}
              />
              <p className="text-[12px] leading-4 text-[#999999]">
                Enter Postal codes separated by commas
              </p>
            </SearchFilterLayout>
            {showSearchDateType && (
              <SearchFilterLayout label="Search Date">
                <Select
                  SelectionColor={true}
                  list={searchDateTypeOptions}
                  value={selectedSearchDateTypeOption}
                  listPlacement={LIST_PLACEMENT.above}
                  onChange={(newValue) => {
                    setSearchDateType(newValue.value);
                  }}
                />
              </SearchFilterLayout>
            )}

            {showMlsFilter && (
              <SearchFilterLayout label="MLS">
                <Select
                  SelectionColor={false}
                  list={mlsOptions}
                  value={selectedMlsOption}
                  flowWidth={true}
                  listPlacement={LIST_PLACEMENT.above}
                  onChange={(newValue) => {
                    setMls(newValue.value);
                  }}
                />
              </SearchFilterLayout>
            )}
          </div>
        </div>
      </ScrollContainer>
      <HDivider />
      <div className="mt-4 flex justify-between">
        <SecondaryButtons
          minWidth={100}
          label="Reset"
          onClick={resetFilters}
          min
        />
        <PrimaryButtons
          minWidth={100}
          label="Apply"
          onClick={() => {
            handleOnComplete();
          }}
        />
      </div>
    </Modal>
  );
};

export default SearchFilters;
