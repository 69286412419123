import React from "react";
import { HDivider } from "./amenities";
import capitalizeWords from "./capitalizeWords";
import { Mail } from "@landvoice/icons";
import { pick } from "../../utils/objects";
import Socials from "./Socials";
import { SUPPORTED_SOCIAL_MEDIA } from "../../constants/listing";

const OwnerContacts = ({ owner }) => {
  const socials = pick(owner, SUPPORTED_SOCIAL_MEDIA);
  const hasSocials = !!Object.values(socials).length;
  return (
    <div className="lg:flex   flex-col h-full lg:py-0 py-4  md:w-[216px]">
      <p className=" text-[14px] text-[#999999] lg:leading-6 font-semibold">
        Owner's Information
      </p>
      {owner?.full_name && owner?.full_name !== " " ? (
        <p className=" text-[16px] text-[#666666] lg:leading-6 lg:mb-3 mb-1">
          {capitalizeWords(owner?.full_name || "--")}
        </p>
      ) : (
        <p className=" text-[16px] text-[#666666] leading-6 mb-3 italic">
          Owner's Name not found
        </p>
      )}

      {owner?.property?.street_address ? (
        <p className=" text-[14px] text-[#666666] leading-[14px] mb-[13px]">
          {owner?.property.street_address
            ? `${owner?.property?.street_address || "--"}, ${
                owner?.property?.city || "--"
              }, ${owner?.property?.state || "--"} ${
                owner?.property?.postal_code || "--"
              }`
            : "--"}
        </p>
      ) : (
        <p className=" text-[14px] text-[#666666] leading-[14px] mb-[13px] italic">
          No address found
        </p>
      )}

      <div className="flex gap-2 max-h-4 mb-2">
        <Mail />
        {owner?.email ? (
          <p className=" leading-[14px] text-[14px] text-[#666666] underline cursor-pointer text-ellipsis overflow-hidden">
            <a href={`mailto:${owner?.email}`}>{owner?.email}</a>
          </p>
        ) : (
          <p className=" leading-[14px] text-[14px] text-[#666666] italic">
            No email found
          </p>
        )}
      </div>
      {hasSocials && (
        <>
          <HDivider />
          <div className="h-4 mt-2 flex gap-[14px]">
            <Socials {...socials} />
          </div>
        </>
      )}
    </div>
  );
};

export default OwnerContacts;
