import { useQuery, useMutation, useQueryClient, QueryClient } from "@tanstack/react-query";
import fetcher from "../utils/fetcher";
import { objectToSearchParams } from "src/utils/queryString";

const prepareUploadRequestBody = (
  { mapping, fileColumnsKeys, fileName, listSource, listName, lookupType },
  jsonData
) => {

  const formattedData = {
    file_name: fileName,
    source: listSource,
    name: listName,
    search_type: lookupType,
    records: [],
    original_file_headers: fileColumnsKeys.map((fileColumnKey, index) => ({
      key: fileColumnKey,
      mapped_key:
        mapping.find(({ mappedKey }) => mappedKey === fileColumnKey)?.key || "",
      order: index,
    })),
  };

  // Transform records based on mapping
  formattedData.records = jsonData.map((data) => {
    const record = {};
    const extraFields = {};

    // Map fields dynamically
    mapping.forEach(({ key, mappedKey }) => {
      if (mappedKey) {
        const value = data[mappedKey];
        record[key] = value !== undefined ? value : "";
      } else {
        record[key] = "";
      }
    });

    // Collect extra fields
    Object.keys(data)
      .filter((key) => !mapping.find(({ mappedKey }) => mappedKey === key))
      .forEach((key) => {
        extraFields[key] = data[key]; // Add unmapped fields to extraFields
      });

    // Add extra fields to the record
    record.extra_fields = extraFields;

    return record;
  });

  return formattedData;
};

export const useGetCustomUploadsQuery = (
  { pageSize = 25, currentPage = 1 } = { limit: 25, currentPage: 1 }
) => {
  const queryString = objectToSearchParams({
    page_size: pageSize,
    offset: (currentPage - 1) * pageSize,
  }).toString();
  return useQuery({
    queryKey: ["customUploads", queryString],
    queryFn: async () => {
      const resp = await fetcher({
        url: `/api/customupload?${queryString}`,
      });
      return {
        imports: resp.data?.batches || [],
        totalRecords: resp.data?.total_records || 0,
      };
    },
  });
};

export const checkIsValidImportNameQuery = (nameToCheck) => {
  const queryClient = new QueryClient();
  return queryClient.fetchQuery({
    cacheTime: 0,
    queryKey: ["checkIsValidImportName", nameToCheck],
    queryFn: async () => {
      const resp = await fetcher({
        url: `/api/customupload/check_name?name=${nameToCheck}`,
      });
      return !resp.data?.exists;
    }
  })
};

export const useGetCustomUploadsQueryCreditsQuery = () => {
  return useQuery({
    queryKey: ["customUploadsCredits"],
    queryFn: async () => {
      const resp = await fetcher({
        url: "/api/customupload/credits",
      });
      return resp?.data?.credits || 0;
    },
  });
};

export const useGetCustomUploadsSourcesQuery = () => {
  return useQuery({
    queryKey: ["customUploadsSources"],
    queryFn: async () => {
      const resp = await fetcher({
        url: "/api/customupload/source",
      });
      return resp.data?.sources || [];
    },
  });
};


export const useUploadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["customUploadsUpload"],
    mutationFn: async ({ config, jsonData }) => {
      const resp = await fetcher({
        url: "/api/customupload",
        method: "POST",
        body: prepareUploadRequestBody(config, jsonData),
      });
      return resp.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customUploadsCredits"] });
      queryClient.invalidateQueries({ queryKey: ["customUploads"] });
    },
  });
};
