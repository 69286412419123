const PageTitle = ({
  superscriptText,
  adjacentContent,
  title,
  subtitle,
  isToolbarOpen,
  rightSideContent,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col">
        {subtitle && (
          <div className="text-sm text-primary font-semibold relative top-[8px]">
            {subtitle}
          </div>
        )}
        <div className="flex items-baseline gap-3">
          <h1 className=" leading-[57px] font-[600] text-[35px] md:text-[48px] text-[#666666] whitespace-nowrap">
            {title}{" "}
            {superscriptText && (
              <sup className="text-[15px] md:text-[20px] relative top-[-20px] text-[#999999] right-[4px]">
                {superscriptText}
              </sup>
            )}
          </h1>
          <div>{adjacentContent}</div>
        </div>
      </div>
      <div>{rightSideContent}</div>
    </div>
  );
};

export default PageTitle;
