export const searchParamsToObject = (searchParams) => {
  const result = {};

  for (const [key, value] of searchParams.entries()) {
    // If the key already exists in the object, push to an array
    if (result[key]) {
      // If the existing value is not already an array, convert it to an array
      result[key] = Array.isArray(result[key]) ? result[key] : [result[key]];
      result[key].push(value);
    } else {
      // If it's a new key, directly assign the value
      result[key] = value;
    }
  }

  return result;
}

export const objectToSearchParams = (params) => {
    const searchParams = new URLSearchParams();
  
    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        // If the value is an array, append each item separately
        value.forEach(val => searchParams.append(key, val));
      } else {
        // Otherwise, set the single value
        searchParams.set(key, value);
      }
    }
  
    return searchParams;
  }