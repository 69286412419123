import React from "react";
import Badge from "../core/Badge";

const ActiveFiltersPanel = ({ activeFilters }) => {
  return (
    <div className="min-h-[28px] w-full flex flex-wrap md:flex-nowrap gap-1">
      <p className="font-semibold w-fit whitespace-nowrap text-[14px] text-[#999999] leading-6">
        Active Filters:
      </p>
      <div className="flex flex-wrap gap-2 overflow-x-auto whitespace-nowrap hideScrollBar">
        {activeFilters
          .filter(
            ({ suppressInActiveFiltersPanel }) => !suppressInActiveFiltersPanel
          )
          .map((activeFilter, index) => {
            const { label, delete: removeFilter } = activeFilter;
            return (
              <Badge
                className="text-[#666666]"
                variant="outlined"
                key={index}
                {...(removeFilter && { onClose: removeFilter })}
              >
                {label}
              </Badge>
            );
          })}
      </div>
    </div>
  );
};

export default ActiveFiltersPanel;