import { useState, useEffect, useMemo, useCallback } from "react";

export function useFeatureDiscovery(features: string[]) {
  const [seenFeaturesArr, setSeenFeatureArr] = useState<string[]>([]);

  // Read from local storage on mount
  useEffect(() => {
    const storedSeenFeatures = localStorage.getItem("seenFeatures");
    if (storedSeenFeatures) {
      setSeenFeatureArr(JSON.parse(storedSeenFeatures));
    }
  }, []);

  const currentFeatureIndex = useMemo(
    () => features.findIndex((feature) => !seenFeaturesArr?.includes(feature)),
    [features, seenFeaturesArr]
  );

  const currentFeature = useMemo(
    () => (currentFeatureIndex > -1 ? features[currentFeatureIndex] : null),
    [features, currentFeatureIndex]
  );

  const markAsSeen = (feature: string) => {
    const baseFeatureId = feature.replace(/-(mobile|desktop)$/, "");
    const updatedSeenFeaturesArr = [...seenFeaturesArr];
    updatedSeenFeaturesArr.push(feature);
    updatedSeenFeaturesArr.push(`${baseFeatureId}-mobile`);
    updatedSeenFeaturesArr.push(`${baseFeatureId}-desktop`);
    setSeenFeatureArr(updatedSeenFeaturesArr);
    localStorage.setItem(
      "seenFeatures",
      JSON.stringify(Array.from(updatedSeenFeaturesArr))
    );
  };

  const nextFeature = () => {
    markAsSeen(features[currentFeatureIndex]);
  };

  const shouldShowTooltip = useCallback((featureId: string) => currentFeature === featureId, [currentFeature]);

  const baseFeatures = useMemo(() => {
    return features.reduce((acc: string[], feature) => {
      const baseFeatureId = feature.replace(/-(mobile|desktop)$/, "");
      if (acc.includes(baseFeatureId)) {
        return acc;
      }
      return [...acc, baseFeatureId];
    }, []);
  }, [features]);

  const baseSeenFeatures = useMemo(() => {
    return seenFeaturesArr.reduce((acc: string[], feature) => {
      const baseFeatureId = feature.replace(/-(mobile|desktop)$/, "");
      if (acc.includes(baseFeatureId)) {
        return acc;
      }
      return [...acc, baseFeatureId];
    }, []);
  }, [seenFeaturesArr]);

  const isLastFeature = useMemo(
    () =>
      baseFeatures.filter((feature) => !baseSeenFeatures.includes(feature))
        ?.length === 1,
    [baseFeatures, baseSeenFeatures]
  );

  return {
    shouldShowTooltip,
    currentFeature,
    nextFeature,
    isLastFeature,
  };
}
