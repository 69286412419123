import React, { useEffect, useState } from "react";
import { SecondaryButtons } from "./buttons";
import { useAuth } from "../utils/auth/assist/authContext";
import useAPI from "./UseApi";
import { stringToBoolean } from "../../utils/strings";
import useAccount from "../../hooks/useAccount";

const LoginAs = ({ setLoginAs = () => {} }) => {
  const { user, isImpersonating, setIsImpersonating } = useAuth();
  const { get } = useAPI();
  const { data: account, isLoading, isError } = useAccount();

  useEffect(() => {
    if (isLoading || isError || !account) {
      return;
    }
    const isImpersonating = stringToBoolean(sessionStorage.getItem("loginAs"));
    setIsImpersonating(isImpersonating);
    setLoginAs(isImpersonating);
  }, [setLoginAs, account, isLoading, isError]);

  const stopImpersonation = async () => {
    await get("/api/impersonate/stop", () => {});
    window.location.reload();
  };
  return (
    <>
      {isImpersonating === true && (
        <div className="flex justify-between items-center h-[60px] w-screen bottom-0 left-0 fixed bg-red-700 text-white px-5 z-[5]">
          <div></div>
          <p>
            Logged in as:{" "}
            <span className=" cursor-pointer  italic">
              {user?.first_name} {user?.last_name}
            </span>
          </p>

          <SecondaryButtons
            label={"Stop"}
            onClick={() => {
              setIsImpersonating(false);
              setLoginAs(false);
              stopImpersonation();
            }}
          />
        </div>
      )}
    </>
  );
};

export default LoginAs;
