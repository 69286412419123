import Select from "../common/Select";
import { sortingOptions } from "../../data/selectionData";
const SortyBy = ({ value, onChange, ...props }) => (
  <Select
    heading="Sort:"
    list={sortingOptions.map(({ name, value }) => ({
      id: name,
      label: name,
      value,
    }))}
    rounded="8px"
    SelectionColor={false}
    selecionHeight="36px"
    flowWidth
    value={value}
    onChange={onChange}
    {...props}
  />
);

export default SortyBy;
