import React from "react";

const TextInput = ({ onChange, value, ...props }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className=" relative w-full bg-[#F5F7F7] py-2 px-3 border border-[#E0E0E0] rounded placeholder:text-[#999999] text-[14px] leading-6 text-[#666666] outline-[#E2631B]"
      {...props}
    />
  );
};

export const NumberInput = (props) => {
  return <TextInput type="number" {...props} />;
};

export default TextInput;
