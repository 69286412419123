import React, { Children, useEffect, useState, useMemo } from "react";
import { HDivider } from "../common/amenities";
import Button from "../core/Button";
import PageTitle from "../common/PageTitle";
import { STEPS } from "./constants";
import { STEPS_CONFIG } from "./constants";
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from "src/constants/ui";

const StepView = ({
  onPrevClick,
  onNextClick,
  onCancelClick,
  onStepClick,
  currentStep,
  currentStepInfo,
  isFirstStep,
  isLastStep,
  children,
  stepsInfo,
}) => {

  const isNextDisabled = useMemo(
    () =>
      !(stepsInfo || []).find(({ name }) => name === currentStep + 1)
        ?.navigateToPermitted,
    [currentStep, stepsInfo]
  );

  const isPreviousDisabled = useMemo(
    () => currentStep === STEPS.configureUpload,
    [currentStep]
  );

  return (
    <section className="step-view h-screen md:h-screen mb-[80px] ">
      <PageTitle title={currentStepInfo.pageTitle} subtitle="Data Genie" />
      <main className="pt-3">{children}</main>
      <section
        className={`sticky right-0 bottom-0 h-[72px] mt-4 z-10`}
      >
        <div className=" relative  flex border-t-[#EBEBEB] border-t-2 h-[72px] w-full bottom-0 bg-white items-center justify-center ">
          <div className="flex absolute left-0 gap-2">
            <Button disabled={isPreviousDisabled} onClick={onPrevClick}>
              Back
            </Button>
            <Button onClick={onCancelClick}>Cancel</Button>
          </div>

          <div className=" items-center gap-3 min-w-[300px] hidden md:flex">
            {(stepsInfo || [])
              .filter(({ name }) =>
                [STEPS.configureUpload, STEPS.mapFields, STEPS.review].includes(
                  name
                )
              )
              .map(
                (
                  { breadcrumbLabel, name, navigateToPermitted },
                  index,
                  array
                ) => (
                  <>
                    <Button
                      key={`breadcrumb-${name}`}
                      variant="ghost"
                      className={`font-semibold ${
                        currentStep === name
                          ? "text-[#E56322]"
                          : " text-[#666666]"
                      }`}
                      disabled={!navigateToPermitted}
                      onClick={() => onStepClick(name)}
                    >
                      {breadcrumbLabel}
                    </Button>
                    {index < array.length - 1 && (
                      <HDivider key={`breadcrumb-divider-${name}`} />
                    )}
                  </>
                )
              )}
          </div>
          {!isLastStep && (
            <Button
              onClick={onNextClick}
              disabled={isNextDisabled}
              className="absolute right-0"
            >
              Next
            </Button>
          )}
        </div>
      </section>
    </section>
  );
};

export default StepView;
