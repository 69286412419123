import React from "react";
import * as FaIcons from "react-icons/fa";
import { LiaHatWizardSolid } from "react-icons/lia";

export const SidebarDataTop = [
  {
    title: "Expired",
    path: "/expired",
    icon: <FaIcons.FaUserClock />,
    cName: "nav-text",
  },
  {
    title: "Expired Uploads",
    path: "/expireduploads",
    icon: <FaIcons.FaClock />,
    cName: "nav-text",
  },

  {
    title: "FSBO",
    path: "/fsbo",
    icon: <FaIcons.FaSign />,
    cName: "nav-text",
  },
  {
    title: "Pre-Foreclosure",
    path: "/Preforeclosure",
    icon: <FaIcons.FaHouseDamage />,
    cName: "nav-text",
  },
  {
    title: "Distressed",
    path: "/Distressed",

    cName: "nav-text",
  },
];

export const SidebarDataBottom = [
  {
    title: "Neighborhood Search",
    path: "https://search.landvoice.com/",
    icon: <FaIcons.FaMap />,
    cName: "nav-text",
    window: true,
  },
  {
    title: "Data Genie",
    path: "/data-genie",
    icon: <LiaHatWizardSolid style={{ fontSize: "19px" }} />,
    cName: "nav-text",
  },
  {
    title: "Old Expired (Add-On)",
    path: "/OldExpired",
    icon: <FaIcons.FaUserAltSlash />,
    cName: "nav-text",
    window: false,
  },
  {
    title: "Call Capture",
    path: "https://callcapture.landvoice.com/account/dashboard",
    icon: <FaIcons.FaPhone />,
    cName: "nav-text",
    window: true,
  },
  {
    title: "Scripts",
    path: "/scripts",
    icon: <FaIcons.FaFile />,
    cName: "nav-text",
    window: false,
  },
  {
    title: "Help",
    path: "https://www.landvoice.com/help",
    icon: <FaIcons.FaQuestion />,
    cName: "nav-text",
    window: true,
  },
];
