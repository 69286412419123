export const findByKey = (
  // eslint-disable-next-line
  arr: { [key: string]: any }[],
  key: string
// eslint-disable-next-line
): { [key: string]: any } | undefined =>
  arr.find((obj) => obj.hasOwnProperty(key));

type SortableObject = {
  // eslint-disable-next-line
  [key: string]: any;
};

export const sortStringFieldAlpha = (
  array: SortableObject[],
  key: string,
  ascending = true
): SortableObject[] => {
  return array.sort((a, b) => {
    const valueA = a[key]?.toString().toLowerCase() ?? "";
    const valueB = b[key]?.toString().toLowerCase() ?? "";

    if (valueA < valueB) return ascending ? -1 : 1;
    if (valueA > valueB) return ascending ? 1 : -1;
    return 0; // Equal values
  });
};

export function splitArray<T>(arr: T[], firstPartSize: number): [T[], T[]] {
  const firstPart = arr.slice(0, firstPartSize);
  const secondPart = arr.slice(firstPartSize);
  return [firstPart, secondPart];
}
