import React, { useCallback } from "react";
import { HDivider } from "../common/amenities";
import { Tooltip } from "@material-tailwind/react";
import { Button } from "../shadcn";
import { CONTACT_MORE_CREDITS } from "./constants";
import { useGetCustomUploadsQueryCreditsQuery } from "src/hooks/useCustomUploads";

const Review = ({ mapping, fieldsConfig, numRecords, onSubmitClick }) => {
  const { data: userCredits } = useGetCustomUploadsQueryCreditsQuery();

  return (
    <section className=" flex flex-col gap-8">
      <section className=" flex gap-8  flex-col-reverse md:flex-row  ">
        <div className=" max-w-[509px] flex flex-col gap-8 py-8">
          <div className=" flex flex-col gap-6">
            <p className=" text-[24px] leading-[28.8px] font-semibold text-[#666666]">
              {" "}
              Your Mappings
            </p>
            <p className=" max-w-[405px] text-[14px] leading-5 text-[#999999]">
              To get a missing phone number, an address is required. To get a
              missing address, a phone number is required. A full name will
              improve the accuracy of your search results.
            </p>
          </div>

          <div>
            <div className="flex gap-6 max-w-[500px] border-y border-[#EBEBEB]">
              <div className=" w-[140px] h-[36px] p-2">
                <p className=" font-semibold text-[14px] text-[#666666] leading-5">
                  Our Fields
                </p>
              </div>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0297 6.52969L7.27965 10.2797C7.13434 10.4273 6.94215 10.5 6.74996 10.5C6.55778 10.5 6.36606 10.4268 6.21981 10.2803C5.92684 9.9873 5.92684 9.5127 6.21981 9.21973L8.69059 6.75H1.49996C1.08582 6.75 0.75 6.41484 0.75 6C0.75 5.58516 1.08582 5.25 1.49996 5.25H8.69059L6.22027 2.77969C5.92731 2.48672 5.92731 2.01211 6.22027 1.71914C6.51324 1.42617 6.98785 1.42617 7.28082 1.71914L11.0308 5.46914C11.3226 5.76328 11.3226 6.23672 11.0297 6.52969Z"
                  fill="#fff"
                />
              </svg>

              <div className=" max-w-[14opx] h-[36px] p-2">
                <p className=" font-semibold text-[14px] text-[#666666] leading-5">
                  Your Fields
                </p>
              </div>
            </div>

            {mapping.map(({ mappedKey, key }, index) => {
              const { label } = fieldsConfig.find((field) => field.key === key);
              return (
                <div
                  key={index}
                  className="flex items-center gap-6 max-w-[500px] h-[60px] border-y border-[#EBEBEB] py-3"
                >
                  <div className=" w-[140px] h-[36px] p-2 ">
                    <p className=" font-semibold text-[14px]  text-[#666666] leading-5">
                      {label}
                    </p>
                  </div>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0297 6.52969L7.27965 10.2797C7.13434 10.4273 6.94215 10.5 6.74996 10.5C6.55778 10.5 6.36606 10.4268 6.21981 10.2803C5.92684 9.9873 5.92684 9.5127 6.21981 9.21973L8.69059 6.75H1.49996C1.08582 6.75 0.75 6.41484 0.75 6C0.75 5.58516 1.08582 5.25 1.49996 5.25H8.69059L6.22027 2.77969C5.92731 2.48672 5.92731 2.01211 6.22027 1.71914C6.51324 1.42617 6.98785 1.42617 7.28082 1.71914L11.0308 5.46914C11.3226 5.76328 11.3226 6.23672 11.0297 6.52969Z"
                      fill="#999999"
                    />
                  </svg>

                  <div className=" max-w-[14opx] h-[36px] p-2">
                    <p className="  text-[14px] text-[#666666] leading-5">
                      {mappedKey || "N/A"}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className=" p-8 gap-8 flex flex-col self-start max-w-[520px] rounded-2xl shadow-md">
          <div className=" max-w-[456px] flex flex-col gap-8">
            <div className=" max-w-[456px] flex flex-col gap-6">
              <p className=" font-semibold text-[24px] leading-[28.8px] text-[#666666] ">
                Review and Submit
              </p>
              <p className=" text-[14px] text-[#999999] leading-5">
                Estimated lookups is an estimation of how much data we can find
                for you given the file you uploaded. If we are unable to find
                data for a contact, you will be refunded the credit.{" "}
              </p>
            </div>
            <div className=" flex justify-between">
              <div className=" flex items-center gap-[6px]">
                <p className=" text-[16px] font-medium leading-5 text-[#666]">
                  Credits Available
                </p>{" "}
                <Tooltip
                  className={"tooltip"}
                  content={
                    `Purchase more credits by calling Landvoice Support at ${CONTACT_MORE_CREDITS}`
                  }
                  placement="right"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_4983_139474)">
                      <path
                        d="M6 0C2.68594 0 0 2.68594 0 6C0 9.31406 2.68594 12 6 12C9.31406 12 12 9.31406 12 6C12 2.68594 9.31406 0 6 0ZM6 3C6.41414 3 6.75 3.33586 6.75 3.75C6.75 4.16414 6.41414 4.5 6 4.5C5.58586 4.5 5.25 4.16484 5.25 3.75C5.25 3.33516 5.58516 3 6 3ZM6.9375 9H5.0625C4.75313 9 4.5 8.74922 4.5 8.4375C4.5 8.12578 4.75195 7.875 5.0625 7.875H5.4375V6.375H5.25C4.93945 6.375 4.6875 6.12305 4.6875 5.8125C4.6875 5.50195 4.94063 5.25 5.25 5.25H6C6.31055 5.25 6.5625 5.50195 6.5625 5.8125V7.875H6.9375C7.24805 7.875 7.5 8.12695 7.5 8.4375C7.5 8.74805 7.24922 9 6.9375 9Z"
                        fill="#666666"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4983_139474">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Tooltip>
              </div>
              <p className=" text-[20px] text-[#AD6714]">{userCredits}</p>
            </div>
            <div className=" flex justify-between">
              <div className=" flex items-center gap-[6px]">
                <p className=" text-[16px] font-medium leading-5 text-[#666]">
                  Estimated Lookups
                </p>{" "}
                <Tooltip
                  className={"tooltip"}
                  content={
                    "Your file has 1,625 rows with data to be appended. We’ll do our best to fill all of these in. "
                  }
                  placement="right"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_4983_139474)">
                      <path
                        d="M6 0C2.68594 0 0 2.68594 0 6C0 9.31406 2.68594 12 6 12C9.31406 12 12 9.31406 12 6C12 2.68594 9.31406 0 6 0ZM6 3C6.41414 3 6.75 3.33586 6.75 3.75C6.75 4.16414 6.41414 4.5 6 4.5C5.58586 4.5 5.25 4.16484 5.25 3.75C5.25 3.33516 5.58516 3 6 3ZM6.9375 9H5.0625C4.75313 9 4.5 8.74922 4.5 8.4375C4.5 8.12578 4.75195 7.875 5.0625 7.875H5.4375V6.375H5.25C4.93945 6.375 4.6875 6.12305 4.6875 5.8125C4.6875 5.50195 4.94063 5.25 5.25 5.25H6C6.31055 5.25 6.5625 5.50195 6.5625 5.8125V7.875H6.9375C7.24805 7.875 7.5 8.12695 7.5 8.4375C7.5 8.74805 7.24922 9 6.9375 9Z"
                        fill="#666666"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4983_139474">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Tooltip>
              </div>
              <p className=" text-[20px] text-[#666666]">{numRecords}</p>
            </div>

            <HDivider />
            <div>
              <div className=" flex justify-between">
                <div className=" flex items-center gap-[6px]">
                  <p className=" text-[16px] font-medium leading-5 text-[#666]">
                  Remaining Credits
                  </p>{" "}
                </div>
                <p className=" text-[20px] text-[#AD6714]">
                  {userCredits - numRecords}
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button className="w-[50%]" disabled={numRecords > userCredits} onClick={onSubmitClick}>
              Submit
            </Button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Review;
