import { ReactNode } from "react";
import { Button as BaseButton, ButtonProps as BaseButtonProps } from "../shadcn"

export type ButtonProps = BaseButtonProps & { icon?: ReactNode }
const Button = ({ children, icon, ...otherProps }: ButtonProps) =>
    <BaseButton
        size="sm"
        {...otherProps}
    >
        <div className="flex items-center gap-1">
            {icon}
            {children}
        </div>
    </BaseButton>


export default Button;