import React, { useMemo } from "react";
import { PRODUCT_TYPES } from "src/constants/listing";
import {
  DistressRate,
  Divider,
  InfoIcon,
  formatDate,
  formatPhoneNumber,
} from "./amenities";
import {
  Bath,
  Bed,
  Grid,
  Tree,
  CalendarClock,
  Building,
} from "@landvoice/icons";
import { StatusTypeEnum } from "../../data/selectionData";
import { formatAsCurrency } from "src/utils/numbers";

const PrintOneView = ({
  property,
  title,
  beds,
  bath,
  sqrFeet,
  lotSize,
  buildDate,
  type,
  price = 0,
  dom,
  rate = 0,
  index,
}) => {
  const firstContact = property.contacts?.[0];
  const productType = property.productType;

  const ownerName = useMemo(() => {
    if (property.owner_name) {
      return property.owner_name;
    }

    if (property?.owner_information) {
      return property.owner_information.full_name;
    }

    if (firstContact) {
      return `${firstContact.first_name} ${firstContact.last_name}`;
    }
  }, [property.owner_name, property.owner_information, firstContact]);

  return (
    <section className={"break-inside-avoid  align-top break-after-page"}>
      <div className=" flex flex-col gap-6 mt-4 break-inside-avoid avoidBreakInside  ">
        <div className="break-inside-avoid gap-1">
          <div className="flex flex-col group items-start w-full justify-between ">
            {rate ? <DistressRate rate={rate} /> : <></>}
            <div className="flex  group items-center w-full justify-between ">
              <p className=" leading-7 font-[500] text-[24px] text-[#666666] text-wrap	 cursor-pointer">
                <span className="hover:text-[#F27935]">{`${title}`}</span>
              </p>

              <p className=" leading-[12px] font-[500] text-[14px] text-[#C2C2C2] text-wrap	 cursor-pointer">
                <span className="hover:text-[#F27935]">{`Date Processed: ${formatDate(
                  property?.publish_date
                )}`}</span>
              </p>
            </div>
          </div>
          <div className="  flex gap-1 pt-3 flex-wrap">
            <p className="text-[14px]  font-bold text-[#666666]">{price}</p>
            <Divider />

            {dom && (
              <p className="text-[14px]  font-bold text-[#666666]">
                {dom} Days on Market
              </p>
            )}
            {dom && <Divider />}
            <InfoIcon
              icon={<Bed className={"max-h-[20px] "} />}
              text={`${beds ? beds : "--"} Bed`}
              textSize="14px"
              gap="4px"
            />
            <Divider />
            <InfoIcon
              icon={<Bath className={"max-h-[14px] "} />}
              text={`${bath ? bath : "--"} Bath`}
              textSize="14px"
              gap="4px"
            />
            <Divider />
            <InfoIcon
              icon={<Grid className={"max-h-[16px] max-w-[16px]"} />}
              text={`${
                sqrFeet
                  ? sqrFeet.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2, // You can adjust this based on your requirements
                    })
                  : "--"
              }  Sq Ft`}
              textSize="16px"
              gap="4px"
            />
            <Divider />
            <InfoIcon
              icon={<Tree className={"max-h-[14px] max-w-[16px]"} />}
              text={` LtSz ${lotSize ? lotSize : "--"}`}
              textSize="14px"
              gap="4px"
            />
            <Divider />
            <InfoIcon
              icon={<CalendarClock className={"max-h-[14px] max-w-[16px]"} />}
              text={`Built ${buildDate ? buildDate : "--"}`}
              textSize="14px"
            />
            <Divider />
            <InfoIcon
              icon={<Building className={"max-h-[14px] max-w-[14px]"} />}
              text={`${type ? type : "--"} `}
              textSize="14px"
              gap="4px"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2 flex flex-col gap-6">
            {
              <section>
                <div className="">
                  <div className="flex gap-4 h-7 items-center  my-1">
                    <p className="whitespace-nowrap text-[24px] font-bold leading-7 text-[#666666]">
                      {[
                        PRODUCT_TYPES.fsbo,
                        PRODUCT_TYPES.preforeclosure,
                        PRODUCT_TYPES.distressed,
                      ].includes(productType)
                        ? "Owner Information"
                        : "Tax Information"}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-[14px] ">
                    <div className=" flex flex-col justify-between gap-2">
                      <div className="flex justify-between  text-[#666666]  ">
                        <p className="font-semibold">Owner Name:</p>
                        {ownerName ? (
                          <p className=" text-right">{ownerName}</p>
                        ) : (
                          <p className="">- -</p>
                        )}
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Phone Number:</p>
                        <p className=" text-right">
                          {" "}
                          {formatPhoneNumber(firstContact?.phone) || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#666666] ] ">
                        <p className="font-semibold">Email: </p>
                        <p className=" text-right">
                          {property?.social_media?.email ||
                            firstContact?.email ||
                            "--"}
                        </p>
                      </div>
                    </div>

                    <div className=" flex flex-col text-[14px]  gap-2">
                      {productType !== PRODUCT_TYPES.fsbo && (
                        <div className="flex justify-between  text-[#666666] ] ">
                          <p className="font-semibold">Mailing Address:</p>

                          {property?.owner_information?.property
                            ?.street_address ? (
                            <p className=" text-right">
                              {`${property?.owner_information?.property?.street_address}, ${property?.owner_information?.property?.city}, ${property?.owner_information?.property?.state} ${property?.owner_information?.property?.postal_code}`}
                            </p>
                          ) : (
                            <p className=" text-right">- -</p>
                          )}
                        </div>
                      )}

                      {productType === PRODUCT_TYPES.fsbo && (
                        <div className="flex justify-between  text-[#666666] ] ">
                          <p className="font-semibold">Owner Address:</p>

                          {firstContact?.street_address ? (
                            <p className=" text-right">
                              {`${firstContact?.street_address}, ${firstContact?.city}, ${firstContact?.state} ${firstContact?.postal_code}`}{" "}
                            </p>
                          ) : (
                            <p className=" text-right">- -</p>
                          )}
                        </div>
                      )}

                      {productType !== PRODUCT_TYPES.fsbo && (
                        <div className="flex justify-between  text-[#666666] ] ">
                          <p className="font-semibold">Tax ID: </p>
                          <p className=" text-right">
                            {" "}
                            {property?.tax_id || "--"}
                          </p>
                        </div>
                      )}

                      <div className="flex justify-between  text-[#666666] ">
                        <p className="font-semibold">Owner Occupied: </p>
                        <p className=" text-right">
                          {" "}
                          {property?.owner_information?.owner_occupied
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            }

            {[PRODUCT_TYPES.expired, PRODUCT_TYPES.oldexpired].includes(
              productType
            ) && (
              <section>
                <div className="">
                  <div className="flex gap-4 h-7 items-center  my-1 ">
                    <p className="whitespace-nowrap text-[24px] font-bold leading-7 text-[#666666]">
                      MLS Information{" "}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-[14px]">
                    <div className=" flex flex-col justify-between gap-2">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">MLS ID:</p>
                        <p className=" text-right">
                          {" "}
                          {property?.mls_number || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Status: </p>
                        <p className=" text-right">
                          {StatusTypeEnum[property?.status] || " - -"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Status Date: </p>
                        <p className=" text-right">
                          {formatDate(property?.status_date) || "--"}
                        </p>
                      </div>
                    </div>

                    <div className=" flex flex-col justify-between gap-2">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Agent:</p>
                        <p className=" text-right">
                          {property?.list_agent || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Phone: </p>
                        <p className=" text-right">
                          {" "}
                          {formatPhoneNumber(property?.list_agent_phone) ||
                            "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Company: </p>
                        <p className=" text-right">
                          {property?.list_agent_company || "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {productType === PRODUCT_TYPES.preforeclosure && (
              <section>
                <div className="">
                  <div className="flex gap-4 h-7 items-center  my-1 ">
                    <p className="whitespace-nowrap text-[24px] font-bold leading-7 text-[#666666]">
                      Mortgage Information{" "}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 ">
                    <div className=" flex flex-col justify-between gap-2 text-[14px]">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Mortgage Holder:</p>
                        <p className=" text-right">
                          {" "}
                          {property?.mortgage_holder || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Loan Amount: </p>
                        <p className=" text-right">
                          {property?.loan_amount || " - -"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">County Recorded: </p>
                        <p className=" text-right">
                          {property?.county_recorded || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Attorney Name: </p>
                        <p className=" text-right">
                          {property?.attorney_name || "--"}
                        </p>
                      </div>
                    </div>

                    <div className=" flex flex-col justify-between gap-2">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Mortgage Date:</p>
                        <p className=" text-right">
                          {formatDate(property?.mortgage_date) || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Delinquent Amount: </p>
                        <p className=" text-right">
                          {" "}
                          {formatAsCurrency(property?.delinquent_amount, "--")}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Date Recorded: </p>
                        <p className=" text-right">
                          {formatDate(property?.date_recorded) || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Attorney Phone: </p>
                        <p className=" text-right">
                          {formatPhoneNumber(property?.attorney_phone) || "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {productType === PRODUCT_TYPES.distressed && (
              <section>
                <div className="">
                  <div className="flex gap-4 h-7 items-center  my-1 ">
                    <p className="whitespace-nowrap text-[24px] font-bold leading-7 text-[#666666]">
                      Mortgage Information{" "}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 ">
                    <div className=" flex flex-col justify-between gap-2 text-[14px]">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Mortgage Holder:</p>
                        <p className=" text-right">
                          {" "}
                          {property?.mortgage_holder || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Loan Amount: </p>
                        <p className=" text-right">
                          {property?.loan_amount || " - -"}
                        </p>
                      </div>
                    </div>

                    <div className=" flex flex-col justify-between gap-2">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Mortgage Date:</p>
                        <p className=" text-right">
                          {formatDate(property?.mortgage_date) || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">County Recorded: </p>
                        <p className=" text-right">
                          {property?.county_recorded || "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {productType === PRODUCT_TYPES.preforeclosure && (
              <section className="text-[14px]">
                <div className="">
                  <div className="flex gap-4 h-7 items-center  my-1 ">
                    <p className="whitespace-nowrap text-[24px] font-bold leading-7 text-[#666666]">
                      Auction Information{" "}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-[14px]">
                    <div className=" flex flex-col justify-between gap-2">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Trustee Sale Number:</p>
                        <p className=" text-right">
                          {" "}
                          {property?.trustee_sale_number || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Auction Location: </p>
                        <p className=" text-right">
                          {property?.auction_location || " - -"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Min Bid: </p>
                        <p className=" text-right">
                          {formatAsCurrency(property?.auction_min_bid, "--")}
                        </p>
                      </div>
                    </div>

                    <div className=" flex flex-col  gap-2">
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Auction Date:</p>
                        <p className=" text-right">
                          {formatDate(property?.auction_date) || "--"}
                        </p>
                      </div>
                      <div className="flex justify-between  text-[#666666] ] ">
                        <p className="font-semibold">Owner Occupied: </p>
                        <p className=" text-right">
                          {" "}
                          {property?.owner_information?.owner_occupied
                            ? "Yes"
                            : "No" || "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
          <div className="col-span-1 ">
            <div className="flex gap-4 h-7 items-center  print:mb-0 ">
              <p className="whitespace-nowrap  font-bold leading-3 text-[#666666]">
                Phone Numbers{" "}
                <span className="font-[500] text-[12px] text-[#999999]"></span>
              </p>{" "}
              <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
            </div>
            <div className="flex flex-col gap-2 pt-1">
              {property?.contacts?.length > 0 ? (
                property?.contacts?.map((contact, index) => (
                  //  name={`${contact?.first_name} ${contact?.last_name}`}
                  //  dnc={contact?.phone_dnc}
                  <div key={`contact-phone-${contact.id}`}>
                    <p className=" text-[#666666] ">{`${contact?.first_name} ${contact?.last_name}`}</p>{" "}
                    <p
                      className={` ${
                        contact?.phone_dnc ? "text-[#E5342E]" : "text-[#999999]"
                      }  `}
                    >{`${formatPhoneNumber(contact?.phone)} ${
                      contact?.phone_dnc ? " DNC" : ""
                    }`}</p>{" "}
                  </div>
                ))
              ) : (
                <div>
                  <p className=" text-[#666666] ">No phone numbers found.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {[
          PRODUCT_TYPES.fsbo,
          PRODUCT_TYPES.expired,
          PRODUCT_TYPES.oldexpired,
        ].includes(productType) && (
          <section className="">
            <div className="">
              <div className="flex gap-4 h-7 items-center  my-1 ">
                <p className="whitespace-nowrap text-[24px] font-bold leading-7 text-[#666666]">
                  {productType === PRODUCT_TYPES.fsbo
                    ? "Ad Text"
                    : "Agent Remarks from MLS"}{" "}
                  <span className="font-[500] text-[12px] text-[#999999]"></span>
                </p>
                <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
              </div>
            </div>

            <p className=" text-[#666666] leading-[12px] print:text-[10px] print:leading-3 ">
              {productType === PRODUCT_TYPES.fsbo
                ? property?.ad_text
                : property?.comments}
            </p>
          </section>
        )}

        <section>
          <div className="flex flex-col gap-6 text-[18px]">
            {[PRODUCT_TYPES.expired, PRODUCT_TYPES.oldexpired].includes(
              productType
            ) && (
              <div className="col-span-1 flex flex-col gap-2">
                <div className="">
                  <div className="flex gap-4 h-3 items-center  print:mb-0 ">
                    <p className="whitespace-nowrap  font-bold  text-[#666666]">
                      Showing Instructions{" "}
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                  </div>
                </div>
                <p className=" text-[#666666] text-[10px] ">
                  {property?.showing_instructions}
                </p>
              </div>
            )}
            <div className="col-span-1 flex flex-col gap-2">
              <div className="">
                <div className="flex gap-4 h-3 items-center  print:mb-0 ">
                  <p className="whitespace-nowrap  font-bold  text-[#666666]">
                    Tags{" "}
                    <span className="font-[500] text-[12px] text-[#999999]"></span>
                  </p>{" "}
                  <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                </div>
              </div>

              {property?.tags?.length ? (
                <div className=" grid grid-cols-12  grid-flow-row gap-2">
                  {property?.tags?.map((tag, index) => {
                    return (
                      <div
                        key={`tag-${tag}-${index}`}
                        className="flex items-center justify-between  px-4 gap-1 "
                      >
                        <p className=" text-[#666666] text-[10px] ">{tag}</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className=" text-[#c2c2c2]  ">No tags found. </p>
              )}
            </div>
            <div className="col-span-2 flex flex-col gap-2">
              {" "}
              <div className="">
                <div className="flex gap-4 h-3 items-center  print:mb-0 ">
                  <p className="  font-bold  text-[#666666]">
                    Notes{" "}
                    <span className="font-[500] text-[12px] text-[#999999]"></span>
                  </p>{" "}
                  <div className="h-[2px] w-full   bg-[#EBEBEB]"></div>
                </div>
              </div>
              <div className=" inline-block">
                {property?.notes?.length > 0 ? (
                  property?.notes
                    ?.filter((note) => !!note)
                    .map((note, index) => {
                      return (
                        <p
                          key={`tag-${note.id}`}
                          className=" text-[#666666] text-[10px]  font-normal font-[lato] mr-2"
                        >
                          {`${index + 1})`} {note?.note}
                        </p>
                      );
                    })
                ) : (
                  <p className=" text-[#c2c2c2]  ">No note </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default PrintOneView;
