import Button, { ButtonProps } from "./Button";
import { Link } from "react-router-dom";

const LinkButton = ({ to, children, ...otherProps }: ButtonProps & { to: string }) =>
    <Button
        {...otherProps}
    >
        <Link to={to}>
            {children}
        </Link>
    </Button>


export default LinkButton;