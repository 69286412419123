import { useCallback, useMemo } from "react";
import Select from "../common/Select";

const Page = ({ numPages, value, onChange, ...props }) => {
  const pagesList = useMemo(() => {
    const pagesArray = [];
    for (let i = 1; i <= numPages; i++) {
      pagesArray.push({ id: `page-${i}`, label: i, value: i });
    }
    return pagesArray;
  }, [numPages]);

  return (
    <Select
      headingProvider={useCallback(
        (value) => `Page ${value.label} ${numPages ? `/${numPages}` : ""}`,
        [numPages]
      )}
      list={pagesList}
      rounded="8px"
      SelectionColor={false}
      selecionHeight={"36px"}
      flowWidth
      value={{ id: `page-${value}`, label: value, value }}
      onChange={(newSelection) => {
        onChange(newSelection.value);
      }}
      {...props}
    />
  );
};

export default Page;
