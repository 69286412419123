import React, { useCallback, useMemo, useState } from "react";
import copyTextToClipboard from "./copyToCLipBoard";
import { FiCopy } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { FaPhone, FaPhoneSlash, FaStar } from "react-icons/fa";
import { formatPhoneNumber } from "./amenities";
import { SvgBusiness, SvgHome, SvgMobile } from "./svg";
import useAPI from "./UseApi";
import ToggleButton from "../core/ToggleButton";
import ToolbarButton from "../SearchListings/ToolbarButton";
import Tooltip from "../core/Tooltip";

export const CONTACT_CARD_BUTTONS = {
  copy: "copy",
  call: "call",
  disable: "disable",
}

export type ContactCardProps = {
  name: string;
  number: string;
  dnc: boolean;
  hidden: boolean;
  propertyId: string;
  list: string;
  type: string;
  isStarred?: boolean;
  suppressStar?: boolean;
  discoveryTooltip?: string;
  onDiscoveryTooltipNextClick?: () => void;
  isLastDiscoveryTooltip?: boolean;
};

const ContactCard = ({
  name,
  number,
  dnc,
  hidden,
  propertyId,
  list,
  type,
  isStarred = false,
  suppressStar = true,
  discoveryTooltip,
  onDiscoveryTooltipNextClick,
  isLastDiscoveryTooltip = false
}: ContactCardProps) => {
  const { post } = useAPI();
  const [isEnabled, setIsEnabled] = useState(!hidden);
  const [isCopied, setIsCopied] = useState(false);
  const contentOpacityClassName = isEnabled ? "" : "opacity-50";

  const handleCopy = async () => {
    try {
      await copyTextToClipboard(number);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const toggleIsEnabled = useCallback(
    async (isEnabled: boolean) => {
      setIsEnabled(isEnabled);
      await post(
        `/api/${list}/hide-phone`,
        {
          phone: number,
          property_id: propertyId,
          hidden: !isEnabled,
        }
      );
    },
    [number, propertyId, list, post]
  );

  const handlePhoneClick = () => {
    window.location.href = `tel:${number}`;
  };

  const getPhoneIcon = useCallback(() => {
    switch (type) {
      case "M":
        return (
          <SvgMobile
            className={`md:text-[12px] text-[12px] ${
              dnc
                ? "text-[#FA423C] fill-[#FA423C] "
                : "text-[#36B25F] fill-[#36B25F]"
            }`}
          />
        );
      case "B":
        return (
          <SvgBusiness
            className={`md:text-[12px] text-[12px] ${
              dnc
                ? "text-[#FA423C] fill-[#FA423C] "
                : "text-[#36B25F] fill-[#36B25F]"
            }`}
          />
        );

      case "R":
        return (
          <SvgHome
            className={`md:text-[12px] text-[12px] ${
              dnc
                ? "text-[#FA423C] fill-[#FA423C] "
                : "text-[#36B25F] fill-[#36B25F]"
            }`}
          />
        );
      default:
        return <></>;
    }
  }, [type, dnc]);

  const borderClassName = useMemo(() => {
    if (isStarred) {
      return "border-[#36B25F] border-2";
    }

    if (isEnabled) {
    return "border-[#999999]";
    }

    return "border-[rgb(255,99,71)/0.5";

  },[isStarred, isEnabled])

  return (
    <div
      className={`contact-card-root w-fit flex flex-col min-w-[150px]  rounded-lg p-[8px] border gap-[8px] text-[#666666] ${borderClassName}`}
    >
      <div className={`flex flex-col ${contentOpacityClassName}`}>
        <div className="flex flex-col gap-[4px]">
          <div className="flex flex-row justify-between">
            {name && name !== " " ? (
              <p className="font-bold text-[11px]  leading-4">
                {name.toUpperCase()}
              </p>
            ) : (
              <p className="font-bold text-[12px]  leading-4 italic">
                No name found
              </p>
            )}
            {isStarred && !suppressStar && (
              <Tooltip content="Highly qualified contact info">
                <span>
                  <FaStar className="text-yellow-600" />
                </span>
              </Tooltip>
            )}
          </div>
          <div className={`flex gap-1 items-end`}>
            {getPhoneIcon()}
            <p
              className={
                `  text-[11px]  leading-3  relative text-nowrap ` +
                (dnc
                  ? "text-[#FA423C] fill-[#FA423C] "
                  : "text-[#36B25F] fill-[#36B25F] font-[700]") +
                (!isEnabled ? " " : "")
              }
            >
              {" "}
              {formatPhoneNumber(number)}
            </p>
            {dnc && (
              <p className="text-[10px]  leading-3  text-[#FA423C] font-bold">
                DNC
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="contact-card-buttons flex flex-row items-center gap-2">
        <ToggleButton
          {...(discoveryTooltip === CONTACT_CARD_BUTTONS.disable && {
            DiscoveryTooltipProps: {
              content: "Click to make contact less visible when contact is not interested",
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onNext: onDiscoveryTooltipNextClick || (() => {}),
              isLast: isLastDiscoveryTooltip,
            },
          })}
          isEnabled={isEnabled}
          onChange={toggleIsEnabled}
          disabledClassName="bg-red-600"
          enabledClassName="bg-green-500"
        />
        <ToolbarButton
          {...(discoveryTooltip === CONTACT_CARD_BUTTONS.copy && {
            DiscoveryTooltipProps: {
              content: "Copy the phone number to your clipboard",
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onNext: onDiscoveryTooltipNextClick || (() => {}),
              isLast: isLastDiscoveryTooltip,
            },
          })}
          disabled={!isEnabled}
          icon={isCopied ? <FaCheck className="text-[#36B25F]" /> : <FiCopy />}
          onClick={handleCopy}
        />
        <ToolbarButton
          {...(discoveryTooltip === CONTACT_CARD_BUTTONS.call && {
            DiscoveryTooltipProps: {
              content: "Click to start a call with this contact",
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onNext: onDiscoveryTooltipNextClick || (() => {}),
              isLast: isLastDiscoveryTooltip,
            },
          })}
          icon={isEnabled ? <FaPhone /> : <FaPhoneSlash />}
          disabled={!isEnabled}
          onClick={handlePhoneClick}
        />
      </div>
    </div>
  );
};

export default ContactCard;
