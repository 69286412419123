import moment from "moment";
export const stringToBoolean = (str: string) =>
  str ? str.toLowerCase() === "true" : false;

export const generateRandomString = (length: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const stringToNumber = (value: string, defaultValue = 0): number => {
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
}

/**
 * Generates a pretty name by combining a prefix and the current date & time in human-readable form.
 */
export function createDatePrettyName(prefix: string): string {
    // Get the current date and time in a human-readable format
    const formattedDate = moment().format('MMMM D, YYYY h:mm a');

    // Combine the prefix and the formatted date & time
    return `${prefix} - ${formattedDate}`;
}

export function isBlank(str: string): boolean {
  return str.trim() === "";
}
