import { useState, ReactNode } from 'react'
import Button from '../core/Button'
interface ExpandableContentProps {
    content: ReactNode
    moreContent: ReactNode,
    showMoreLabel?: string,
    showLessLabel?: string   
}

export default function ExpandableContent({ content, moreContent, showMoreLabel = "Show more", showLessLabel = "Show less" }: ExpandableContentProps) {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div>
            <div className="mb-4">{content}</div>
            {isExpanded && (
                <div
                    className="mb-4"
                    aria-hidden={!isExpanded}
                >
                    {moreContent}
                </div>
            )}

            <div className="text-center">
                <Button
                    variant="ghost"
                    onClick={toggleExpand}
                    aria-expanded={isExpanded}
                    aria-controls="more-content"
                >
                    {isExpanded ? showLessLabel : showMoreLabel}
                </Button>
            </div>
        </div>
    )
}

