"use client"

import React from "react"
import { X } from 'lucide-react'
import { Badge as BadgeBase, BadgeProps as BadgePropsBase } from "../shadcn"
import { cn } from "../../utils/css"

interface BadgeProps extends BadgePropsBase {
  onClose?: () => void
}

export default function Badge({ children, className, variant, onClose, ...props }: BadgeProps) {
  return (
    <BadgeBase
      variant={variant}
      className={cn("pr-1.5 pl-3", className)}
      {...props}
    >
      <span className="mr-1">{children}</span>
      {onClose && (
        <button
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
          className="inline-flex items-center justify-center rounded-full text-xs hover:bg-muted/20 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
          aria-label="Remove badge"
        >
          <X className="h-3 w-3" />
        </button>
      )}
    </BadgeBase>
  )
}
