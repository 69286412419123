import SearchSummary from "./SearchSummary";
import BasePageTitle from "../common/PageTitle";

const PageTitle = ({
  isPro = false,
  title,
  totalRecords,
  isLoading = true,
  currentPage,
  pageSize,
  onToggleClicked,
  ...otherProps
}) => {
  return (
    <BasePageTitle
      title={title}
      superscriptText={isPro ? "Pro" : ""}
      adjacentContent={
        <div className="hidden md:block">
          <SearchSummary
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            isShowing={!isLoading && totalRecords}
          />
        </div>
      }
      {...otherProps}
    />
  );
};

export default PageTitle;
