import SearchPage from "../SearchListings/SearchPage";
import { useAuth } from "../utils/auth/assist/authContext";
import { Import, Plus } from "lucide-react";
import { useFeatureDiscovery } from "src/hooks/useFeatureDiscovery";
import FeatureDiscoveryTooltip from "../FeatureDiscoveryTooltip";
import { pick } from "src/utils/objects";
import { PropertyTypeEnum, StatusTypeEnum } from "../../data/selectionData";
import useSearchListings from "../SearchListings/useSearchListings";
import useSearchListingsPage from "../SearchListings/useSearchListingsPage";
import useAccount from "../../hooks/useAccount";
import { useMemo } from "react";
import LinkButton from "../core/LinkButton";
import { PRODUCT_TYPES, SUPPORTED_SOCIAL_MEDIA } from "src/constants/listing";
import { getImportedListingTitle } from "src/utils/listings";
import ImportedListingDetails from "./ImportedListingDetails";
import { formatAsCurrency } from "src/utils/numbers";
const SearchImportedListings = () => {
  const { user } = useAuth();

  const {
    data: account,
    isLoading: isLoadingAccount,
    isError: isErrorLoadingAccount,
  } = useAccount();

  const features = useMemo(() => ["start-new-lookup", "previous-lookups"], []);

  const { currentFeature, nextFeature, isLastFeature, shouldShowTooltip } =
    useFeatureDiscovery(features);

  const searchListings = useSearchListings({
    searchQueryKey: "getCustomUploadsListings",
    searchUrl: "/api/customupload/listing",
    includeLookupNameFilter: true,
    includeLookupSourceFilter: true,
  });

  const searchListingsPage = useSearchListingsPage({
    searchQueryKey: "getCustomUploadsListings",
    exportedCsvFileName: "customUploadslisting",
    searchListings,
  });

  return (
    <SearchPage
      searchListings={searchListings}
      searchListingsPage={searchListingsPage}
      pageTitle="Imported Contacts"
      productName="Data Genie"
      PageTitleProps={{
        subtitle: "Data Genie",
        rightSideContent: (
          <div className="flex gap-2">
            <FeatureDiscoveryTooltip
              content="View your previous lookups!"
              isOpen={shouldShowTooltip("previous-lookups")}
              onNext={nextFeature}
              isLast={isLastFeature}
            >
              <LinkButton
                variant="secondary"
                to="/data-genie/previous"
                icon={<Import />}
              >
                Previous Lookups
              </LinkButton>
            </FeatureDiscoveryTooltip>
            <FeatureDiscoveryTooltip
              content="Start a new Data Genie lookup"
              isOpen={shouldShowTooltip("start-new-lookup")}
              onNext={nextFeature}
              isLast={isLastFeature}
            >
              <LinkButton
                variant="secondary"
                to="/data-genie/start"
                icon={<Plus />}
              >
                Start Lookup
              </LinkButton>
            </FeatureDiscoveryTooltip>
          </div>
        ),
      }}
      apiBaseUrl="/api/customupload/listing"
      AddNotesProps={{ where: "customupload" }}
      isAllowed={!!user?.customupload}
      detailsPanelProvider={({
        detailsListingId,
        toggleDetailsPanel,
        ...otherProps
      }) => (
        <ImportedListingDetails
          {...otherProps}
          title="Imported"
          productType={PRODUCT_TYPES.customupload}
          listingId={detailsListingId}
          onClose={toggleDetailsPanel}
        />
      )}
      mlsTitleAmenitiesPropsProvider={(property) => {
        return {
          suppressStatusInfo: true,
          id: property?.id,
          title: getImportedListingTitle(property),
          beds: property?.bedrooms,
          bath: property?.baths,
          sqrFeet: property?.square_feet?.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }),
          lotSize: property?.acres,
          buildDate: property?.year_built,
          type: PropertyTypeEnum[property?.property_type],
          statusDate: property?.status_date,
          status: StatusTypeEnum[property?.status],
          price: formatAsCurrency(property?.list_price, "--"),
          notes: property?.notes,
          tags: property?.tags,
          hasDom: false,
          where: "CustomUploads",
          mls_number: property?.mls_number,
          mapLink: property?.property?.street_address
            ? `https://www.google.com/maps/place/${property?.property?.street_address}+${property?.property?.city}+${property?.property?.state}+${property?.property?.postal_code}`
            : "",
        };
      }}
      ownerContactsPropsProvider={(property) => {
        const { owner = {}, social_media: socialMedia = {} } = property;
        const { mailing_property: address = {} } = owner || {};
        return {
          full_name: owner?.full_name,
          email: socialMedia?.email || "",
          ...pick(socialMedia || {}, SUPPORTED_SOCIAL_MEDIA),
          property: {
            street_address: address?.street_address || "",
            city: address?.city || "",
            state: address?.state || "",
            postal_code: address?.postal_code || "",
          },
        };
      }}
      printOneViewPropsProvider={(printProperty) => ({
          title: getImportedListingTitle(printProperty),
          beds: printProperty?.bedrooms,
          bath: printProperty?.baths,
          sqrFeet: printProperty?.square_feet,
          lotSize: printProperty?.acres,
          buildDate: printProperty?.year_built,
          type: PropertyTypeEnum[printProperty?.property_type],
          price: formatAsCurrency(printProperty?.list_price, "--"),
        })
      }
      printListViewPropsProvider={(property) => ({
        title: getImportedListingTitle(property),
        beds: property?.bedrooms,
        bath: property?.baths,
        sqrFeet: property?.square_footage,
        lotSize: property?.acres,
        buildDate: property?.year_built,
        type: PropertyTypeEnum[property?.property_type],
        price: formatAsCurrency(property?.list_price, "--"),
      })}
    />
  );
};
export default SearchImportedListings;
