import { useId } from "react";
import { Checkbox as CheckboxBase, CheckboxProps, Label } from "../shadcn";

const Checkbox = ({ label, ...checkboxProps }: CheckboxProps & { label?: string }) => {
    const instanceId = useId();
    if (label) {
        return <div className="flex items-center space-x-2 text-[#666666]">
            <CheckboxBase id={instanceId} {...checkboxProps} />
            <Label className="cursor-pointer" htmlFor={instanceId}>{label}</Label>
        </div>
    }
    return <CheckboxBase {...checkboxProps} />;
}

export default Checkbox;