"use client"

import type React from "react"
import { useState, useEffect, useCallback } from "react"
import { Input } from "../shadcn"
import { Check, AlertCircle } from "lucide-react"
import Spinner from "./Spinner"

interface ValidatedInputProps {
    value: string
    onChange: (value: string) => void
    onValidate: (value: string) => Promise<boolean>
    debounceTime?: number
}

export function AsyncValidateOnTypeInput({ value, onChange, onValidate, debounceTime = 500, ...props }: ValidatedInputProps) {
    const [isValid, setIsValid] = useState<boolean | null>(null)
    const [isValidating, setIsValidating] = useState(false)

    const debouncedValidate = useCallback(
        (value: string) => {
            setIsValidating(true)
            debounce(async () => {
                const result = await onValidate(value)
                setIsValid(result)
                setIsValidating(false)
            }, debounceTime)()
        },
        [onValidate, debounceTime],
    )

    useEffect(() => {
        if (value) {
            debouncedValidate(value)
        } else {
            setIsValid(null)
        }
    }, [value, debouncedValidate])

    return (
        <div className="relative">
            <Input
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={`pr-10 ${isValid === true ? "border-green-500" : isValid === false ? "border-red-500" : ""}`}
                {...props}
            />
            {isValidating && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <Spinner size="small" />
                </div>
            )}
            {!isValidating && isValid !== null && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    {isValid ? <Check className="h-4 w-4 text-green-500" /> : <AlertCircle className="h-4 w-4 text-red-500" />}
                </div>
            )}
        </div>
    )
}

// Debounce function
// eslint-disable-next-line
function debounce<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
    let timeout: NodeJS.Timeout | null = null

    return (...args: Parameters<T>) => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => func(...args), wait)
    }
}

