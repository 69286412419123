import { useMemo, useCallback, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getDate } from "../common/currentDateFormated";
import fetcher from "../../utils/fetcher";
import useMlsList from "./useMlsList";
import { stringToBoolean, stringToNumber } from "../../utils/strings";
import {
  bathsList,
  bedsList,
  squareFeet as squareFeetList,
  searchDateTypeFilters,
  preforeclosureStatusTypesList,
  statusList,
  distressedLevel,
  communicationTypeFilters,
} from "../../data/selectionData";
import {
  searchParamsToObject,
  objectToSearchParams,
} from "../../utils/queryString";

const DEFAULT_PREFORECLOSURE_STATUS_TYPES = preforeclosureStatusTypesList.map(
  ({ code }) => code
);

const useSearchListings = ({
  defaultBatchId,
  defaultLookupId,
  productType,
  searchQueryKey,
  fromDateDaysOffset = 30,
  defaultSortField = "date",
  defaultSortDir = "desc",
  defaultPageSize = 25,
  defaultToDate = getDate(0),
  defaultCommType = "",
  defaultSearchDateType = "file_upload_date",
  defaultFromDate = getDate(-fromDateDaysOffset),
  searchUrl = "/api/expired/listing",
  suppressPriceFilters = false,
  includePreForeclosureStatusFilter = false,
  includeDistressedFilters = false,
  includeListingStatusFilter = false,
  includeSearchDateTypeFilter = false,
  includeMLSActivesFilter = false,
  includeBatchIdFilter = false,
  includeLookupNameFilter = false,
  includeLookupSourceFilter = false,
  includeMlsFilter = false,
} = {}) => {
  const mlsList = useMlsList();
  const isFirstLoad = useRef(true);

  // Search
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = stringToNumber(searchParams.get("currentPage"), 1);
  const pageSize = stringToNumber(
    searchParams.get("pageSize"),
    defaultPageSize
  );
  const sortDir = searchParams.get("sortDir") || defaultSortDir;
  const sortField = searchParams.get("sortField") || defaultSortField;
  const commType = searchParams.get("commType") || defaultCommType;
  const toDate = searchParams.has("toDate") ? searchParams.get("toDate") : defaultToDate;
  const fromDate = searchParams.has("fromDate") ? searchParams.get("fromDate") : defaultFromDate;
  const minPrice = searchParams.get("minPrice") || "";
  const maxPrice = searchParams.get("maxPrice") || "";
  const numBeds = searchParams.get("numBeds") || "";
  const numBaths = searchParams.get("numBaths") || "";
  const batchId = searchParams.get("batchId") || defaultBatchId || "";
  const lookupSources = searchParams.getAll("lookupSources") || [];  
  const showMLSActives = stringToBoolean(searchParams.get("showMLSActives"));
  const sqFootage = searchParams.get("sqFootage") || "";
  const postalCode = searchParams.get("postalCode") || "";
  const mls = searchParams.get("mls") || "";
  const tags = searchParams.get("tags") || "";
  const searchDateType =
    searchParams.get("searchDateType") || defaultSearchDateType;

  const distressedLevels = useMemo(
    () => searchParams.getAll("distressedLevel") || [],
    [searchParams]
  );

  const lookupIds = useMemo(() => {
    if (!includeLookupNameFilter) {
      return [];
    }

    const lkUpIds = searchParams.getAll("lookupIds");
    if (lkUpIds.length) {
      return lkUpIds;
    }

    if (isFirstLoad.current && defaultLookupId) {
      return [defaultLookupId];
    }

    return [];
  }, [searchParams, includeLookupNameFilter]);

  const preForeclosureStatuses = useMemo(() => {
    if (!includePreForeclosureStatusFilter) {
      return [];
    }

    const preForeclosureStatuses = searchParams.getAll("preForeclosureStatus");
    if (preForeclosureStatuses.length) {
      return preForeclosureStatuses;
    }

    if (isFirstLoad.current) {
      return DEFAULT_PREFORECLOSURE_STATUS_TYPES;
    }

    return [];
  }, [searchParams, includePreForeclosureStatusFilter]);

  const listingStatuses = useMemo(() => {
    if (!includeListingStatusFilter) {
      return [];
    }
    const statuses = searchParams.getAll("status");
    if (statuses.length) {
      return statuses;
    }

    if (isFirstLoad.current && !batchId) {
      return ["X", "W", "C"];
    }
    return [];
  }, [searchParams, includeListingStatusFilter, batchId]);

  const filters = useMemo(() => {
    return [
      {
        name: "currentPage",
        value: currentPage,
        defaultValue: 1,
        excludeFromSearchQuery: true,
      },
      {
        name: "pageSize",
        value: pageSize,
        label: "Records Per Page",
        apiKeyName: "limit",
        defaultValue: defaultPageSize,
        excludeFromReset: true,
      },
      {
        name: "sortDir",
        value: sortDir,
        label: "Sort Direction",
        apiKeyName: "sort_dir",
        defaultValue: defaultSortDir,
        excludeFromReset: true,
      },
      {
        name: "sortField",
        value: sortField,
        label: "Sort Field",
        apiKeyName: "sort",
        defaultValue: defaultSortField,
        excludeFromReset: true,
      },
      {
        name: "commType",
        value: commType,
        label: "Comm Type",
        apiKeyName: "lead_filter",
        isFilter: true,
        isEnabled: true,
        defaultValue: defaultCommType,
      },
      {
        name: "toDate",
        value: toDate,
        label: "To Date",
        apiKeyName: "to_date",
        isFilter: true,
        isEnabled: true,
        defaultValue: defaultToDate,
      },
      {
        name: "fromDate",
        value: fromDate,
        label: "From Date",
        apiKeyName: "from_date",
        isFilter: true,
        isEnabled: true,
        defaultValue: defaultFromDate,
      },
      {
        name: "minPrice",
        value: minPrice,
        label: "Min Price",
        apiKeyName: "min_price",
        isEnabled: !suppressPriceFilters,
        isFilter: true,
      },
      {
        name: "maxPrice",
        value: maxPrice,
        label: "Max Price",
        apiKeyName: "max_price",
        isEnabled: !suppressPriceFilters,
        isFilter: true,
      },
      {
        name: "numBeds",
        value: numBeds,
        label: "Beds",
        apiKeyName: "beds",
        isEnabled: true,
        isFilter: true,
      },
      {
        name: "numBaths",
        value: numBaths,
        label: "Baths",
        apiKeyName: "baths",
        isEnabled: true,
        isFilter: true,
      },
      {
        name: "sqFootage",
        value: sqFootage,
        label: "Sq Ft",
        apiKeyName: "sq_ft",
        isEnabled: true,
        isFilter: true,
      },
      {
        name: "postalCode",
        value: postalCode,
        label: "Postal Codes",
        apiKeyName: "postal_codes",
        isEnabled: true,
        isFilter: true,
      },
      {
        name: "mls",
        value: mls,
        label: "MLS",
        apiKeyName: "mls",
        isEnabled: includeMlsFilter,
        isFilter: true,
      },
      {
        name: "tags",
        value: tags,
        label: "Tags",
        apiKeyName: "tags",
        isEnabled: true,
        isFilter: true,
      },
      {
        name: "searchDateType",
        value: searchDateType,
        label: "Search Date",
        apiKeyName: "date_search_type",
        isEnabled: includeSearchDateTypeFilter,
        isFilter: true,
        defaultValue: defaultSearchDateType,
      },
      {
        name: "status",
        value: listingStatuses,
        label: "Status",
        apiKeyName: "status",
        isEnabled: includeListingStatusFilter,
        isFilter: true,
        defaultValue: batchId ? [] : ["X", "W", "C"],
      },
      {
        name: "showMLSActives",
        value: showMLSActives,
        label: "Show MLS Actives",
        apiKeyName: "show_mls_active",
        isEnabled: includeMLSActivesFilter,
        isFilter: true,
        defaultValue: false,
      },
      {
        name: "distressedLevel",
        value: distressedLevels,
        label: "Distressed Level",
        apiKeyName: "distressed_level",
        isEnabled: includeDistressedFilters,
        isFilter: true,
        defaultValue: [],
      },
      {
        name: "preForeclosureStatus",
        value: preForeclosureStatuses,
        label: "Pre-Foreclosure Status",
        apiKeyName: "listing_type",
        isEnabled: includePreForeclosureStatusFilter,
        isFilter: true,
        defaultValue: DEFAULT_PREFORECLOSURE_STATUS_TYPES,
      },
      {
        name: "batchId",
        value: batchId,
        label: "Batch ID",
        apiKeyName: "batch_id",
        isEnabled: includeBatchIdFilter,
        isFilter: true,
      },
      {
        name: "lookupIds",
        value: lookupIds,
        label: "Name",
        apiKeyName: "list_id",
        isEnabled: includeLookupNameFilter,
        isFilter: true,
        prepareApiValue: (value) => (value || []).join(","),
        defaultValue: [],
      },
      {
        name: "lookupSources",
        value: lookupSources,
        label: "Source",
        apiKeyName: "source",
        isEnabled: includeLookupSourceFilter,
        isFilter: true,
        prepareApiValue: (value) => value.join("|"),
        defaultValue: [],
      },
    ];
  }, [
    suppressPriceFilters,
    defaultCommType,
    defaultPageSize,
    defaultSortDir,
    defaultSortField,
    defaultToDate,
    currentPage,
    includeMlsFilter,
    defaultFromDate,
    includeBatchIdFilter,
    includeMLSActivesFilter,
    batchId,
    preForeclosureStatuses,
    distressedLevels,
    showMLSActives,
    pageSize,
    sortDir,
    sortField,
    commType,
    toDate,
    fromDate,
    minPrice,
    maxPrice,
    numBeds,
    numBaths,
    sqFootage,
    postalCode,
    mls,
    tags,
    searchDateType,
    listingStatuses,
    lookupIds,
    includeSearchDateTypeFilter,
    includeListingStatusFilter,
    includeDistressedFilters,
    includePreForeclosureStatusFilter,
    defaultSearchDateType,
  ]);

  // Merge (not wholesale replace) updates to the search params
  const updateSearchParams = useCallback(
    (updatedParams) => {
      const currentParams = searchParamsToObject(searchParams);
      const updateURLSearchParams = objectToSearchParams({
        ...currentParams,
        ...updatedParams,
      });
      setSearchParams(updateURLSearchParams);
    },
    [searchParams, setSearchParams]
  );

  // Reset filters to their default values
  const resetSearchFilters = useCallback(() => {
    const resetParams = filters
      .filter(
        ({ isEnabled, isFilter, excludeFromReset }) =>
          ((isEnabled && isFilter) || !isFilter) && !excludeFromReset
      )
      .reduce((acc, { name, defaultValue }) => {
        return {
          ...acc,
          [name]: defaultValue !== undefined ? defaultValue : "",
        };
      }, {});
    updateSearchParams(resetParams);
  }, [filters, updateSearchParams]);

  const deleteSearchParam = useCallback(
    (paramName) => {
      searchParams.delete(paramName);
      searchParams.set("currentPage", 1);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const enabledFilters = useMemo(() => {
    return filters.filter(
      ({ isEnabled, isFilter }) => (isEnabled || undefined) && isFilter
    );
  }, [filters]);

  const searchApiQueryParams = useMemo(() => {
    const queryParams = objectToSearchParams(
      filters.reduce(
        (
          acc,
          { value, apiKeyName, excludeFromSearchQuery, prepareApiValue }
        ) => {
          if (excludeFromSearchQuery || value === "") {
            return acc;
          }
          acc[apiKeyName] = prepareApiValue ? prepareApiValue(value) : value;
          return acc;
        },
        {}
      )
    );
    queryParams.set("offset", (currentPage - 1) * pageSize);
    return queryParams;
  }, [currentPage, pageSize, filters]);

  const searchQuery = useQuery({
    queryKey: [searchQueryKey, searchApiQueryParams.toString()],
    enabled: !!(currentPage && pageSize && sortField && sortDir),
    queryFn: async () => {
      const resp = await fetcher({
        url: `${searchUrl}?${searchApiQueryParams.toString()}`,
      });

      const { listings, ...others } = resp.data;
      return {
        listings: resp.data.listings.map((listing) => {
          return {
            ...listing,
            // Augment the data with a productType
            productType,
          };
        }),
        ...others,
      };
    },
  });

  const { total_records: totalRecords } = searchQuery.data || {};

  const importedListingsImports = useMemo(
    () => searchQuery.data?.lists || [],
    [searchQuery.data]
  );

  const activeFilters = useMemo(() => {
    const updatedActiveFilters = [];
    if (fromDate && toDate) {
      updatedActiveFilters.push({
        label: `${fromDate} - ${toDate}`,
      });
    }

    const commTypeFilter = enabledFilters.find(
      ({ name }) => name === "commType"
    );
    if (commTypeFilter && commTypeFilter.value) {
      const commTypeValueLabel = communicationTypeFilters.find(
        ({ value }) => value === commTypeFilter.value
      )?.name;
      updatedActiveFilters.push({
        label: commTypeValueLabel,
        delete: () => {
          deleteSearchParam("commType");
        },
      });
    }

    const showMLSActivesFilter = enabledFilters.find(
      ({ name }) => name === "showMLSActives"
    );
    if (showMLSActivesFilter && showMLSActivesFilter.value) {
      updatedActiveFilters.push({
        label: "MLS Actives Included",
        delete: () => {
          deleteSearchParam("showMLSActives");
        },
      });
    }

    if (
      enabledFilters.find(({ name }) => name === "distressedLevel") &&
      distressedLevels.length
    ) {
      const selectedDistressedLevels = distressedLevel
        .filter(({ value }) => distressedLevels.includes(value))
        .map(({ name }) => name);
      updatedActiveFilters.push({
        label: selectedDistressedLevels.join(", "),
        delete: () => {
          deleteSearchParam("distressedLevel");
        },
      });
    }

    if (
      enabledFilters.find(({ name }) => name === "preForeclosureStatus") &&
      preForeclosureStatuses.length
    ) {
      const selectedPreForeclosureStatuses = preforeclosureStatusTypesList
        .filter(({ code }) => preForeclosureStatuses.includes(code))
        .map(({ name }) => name);
      updatedActiveFilters.push({
        label: `Pre-Foreclosure status: ${selectedPreForeclosureStatuses.join(
          ", "
        )}`,
        delete: () => {
          deleteSearchParam("preForeclosureStatus");
        },
      });
    }

    if (enabledFilters.find(({ name }) => name === "status")) {
      listingStatuses.forEach((status) => {
        const found = statusList.find(({ value }) => value === status);
        if (found) {
          updatedActiveFilters.push({
            label: found.name,
            delete: () => {
              const statuses = searchParams.getAll("status");
              updateSearchParams({
                currentPage: 1,
                status: statuses.filter((s) => s !== status),
              });
            },
          });
        }
      });
    }

    const searchDateTypeFilter = enabledFilters.find(
      ({ name }) => name === "searchDateType"
    );
    if (searchDateTypeFilter && searchDateTypeFilter.value) {
      updatedActiveFilters.push({
        label: `${searchDateTypeFilter.label}: ${
          searchDateTypeFilters.find(
            ({ value }) => searchDateTypeFilter.value === value
          ).name
        }`,
      });
    }

    const batchIdFilter = enabledFilters.find(({ name }) => name === "batchId");
    if (batchIdFilter && batchIdFilter.value) {
      updatedActiveFilters.push({
        label: `Batch: ${batchIdFilter.value}`,
        delete: () => {
          deleteSearchParam("batchId");
        },
      });
    }

    if (enabledFilters.find(({ name }) => name === "lookupIds")) {
      lookupIds.forEach((lookupId) => {
        const found = importedListingsImports.find(({ id }) => `${id}` === lookupId);
        if (found) {
          updatedActiveFilters.push({
            label: `Name: ${found.name}`,
            delete: () => {
              updateSearchParams({
                currentPage: 1,
                lookupIds: lookupIds.filter((lId) => lId !== lookupId),
              });
            },
          });
        }
      });
    }

    if (enabledFilters.find(({ name }) => name === "lookupSources")) {
      lookupSources.forEach((source) => {
        updatedActiveFilters.push({
          label: `Source: ${source}`,
          delete: () => {
            updateSearchParams({
              currentPage: 1,
              lookupSources: lookupSources.filter((s) => s !== source),
            });
          },
        });
      });
    }

    const tagsFilter = enabledFilters.find(({ name }) => name === "tags");
    if (tagsFilter && tagsFilter.value) {
      updatedActiveFilters.push({
        label: `${tagsFilter.label}: ${tagsFilter.value}`,
        delete: () => {
          deleteSearchParam("tags");
        },
      });
    }

    const mindPriceFilter = enabledFilters.find(
      ({ name }) => name === "minPrice"
    );
    if (mindPriceFilter && mindPriceFilter.value) {
      updatedActiveFilters.push({
        label: `${mindPriceFilter.label}: ${mindPriceFilter.value}`,
        delete: () => {
          deleteSearchParam("minPrice");
        },
      });
    }

    const maxPriceFilter = enabledFilters.find(
      ({ name }) => name === "maxPrice"
    );
    if (maxPriceFilter && maxPriceFilter.value) {
      updatedActiveFilters.push({
        label: `${maxPriceFilter.label}: ${maxPriceFilter.value}`,
        delete: () => {
          deleteSearchParam("maxPrice");
        },
      });
    }

    const numBedsFilter = enabledFilters.find(({ name }) => name === "numBeds");
    if (numBedsFilter && numBedsFilter.value) {
      const numBedsValueLabel = bedsList.find(
        ({ value }) => value === numBedsFilter.value
      )?.name;
      updatedActiveFilters.push({
        label: `${numBedsFilter.label}: ${numBedsValueLabel}`,
        delete: () => {
          deleteSearchParam("numBeds");
        },
      });
    }

    const numBathsFilter = enabledFilters.find(
      ({ name }) => name === "numBaths"
    );
    if (numBathsFilter && numBathsFilter.value) {
      const numBathsValueLabel = bathsList.find(
        ({ value }) => value === numBathsFilter.value
      )?.name;
      updatedActiveFilters.push({
        label: `${numBathsFilter.label}: ${numBathsValueLabel}`,
        delete: () => {
          deleteSearchParam("numBaths");
        },
      });
    }

    const sqFootageFilter = enabledFilters.find(
      ({ name }) => name === "sqFootage"
    );
    if (sqFootageFilter && sqFootageFilter.value) {
      const sqFootageLabel = squareFeetList.find(
        ({ value }) => value === sqFootageFilter.value
      )?.name;
      updatedActiveFilters.push({
        label: `${sqFootageFilter.label}: ${sqFootageLabel}`,
        delete: () => {
          deleteSearchParam("sqFootage");
        },
      });
    }

    const postalCodeFilter = enabledFilters.find(
      ({ name }) => name === "postalCode"
    );
    if (postalCodeFilter && postalCodeFilter.value) {
      updatedActiveFilters.push({
        label: `${postalCodeFilter.label}: ${postalCodeFilter.value}`,
        delete: () => {
          deleteSearchParam("postalCode");
        },
      });
    }

    const mlsFilter = enabledFilters.find(({ name }) => name === "mls");
    if (mlsFilter && mlsFilter.value) {
      const selectMlsListItem = mlsList?.find(
        ({ customer_mls_id }) => `${customer_mls_id}` === mlsFilter.value
      );
      if (selectMlsListItem) {
        updatedActiveFilters.push({
          label: `${mlsFilter.label}: ${selectMlsListItem.name}`,
          delete: () => {
            deleteSearchParam("mls");
          },
        });
      }
    }

    return updatedActiveFilters;
  }, [
    mlsList,
    enabledFilters,
    updateSearchParams,
    searchParams,
    distressedLevels,
    preForeclosureStatuses,
    listingStatuses,
    fromDate,
    toDate,
    deleteSearchParam,
    importedListingsImports,
  ]);

  const printQuery = useQuery({
    queryKey: [`${searchQueryKey}-print}`],
    enabled: false,
    staleTime: Infinity,
    queryFn: async () => {
      const searchForPrintApiQueryParams = new URLSearchParams(
        searchApiQueryParams
      );
      searchForPrintApiQueryParams.set("limit", totalRecords);
      searchForPrintApiQueryParams.set("offset", 0);
      const resp = await fetcher({
        url: `${searchUrl}?${searchForPrintApiQueryParams.toString()}`,
      });

      return {
        listings: resp.data.listings.map((listing) => {
          return {
            ...listing,
            // Augment the data with a productType
            productType,
          };
        }),
        total_records: resp.data.total_records,
      };
    },
  });

  const csvApiUrl = useMemo(() => {
    const searchForCsvApiQueryParams = new URLSearchParams(
      searchApiQueryParams
    );
    searchForCsvApiQueryParams.set("limit", totalRecords);
    searchForCsvApiQueryParams.set("offset", (currentPage - 1) * pageSize);
    return `${searchUrl}/csv?${searchForCsvApiQueryParams.toString()}`;
  }, [searchApiQueryParams, totalRecords, currentPage, pageSize, searchUrl]);

  useEffect(() => {
    // Set the ref to false after the first render
    isFirstLoad.current = false;
  }, []);

  useEffect(() => {
    // As the filters change update the URL to match
    updateSearchParams(
      filters.reduce((acc, { value, name }) => {
        if (value === "") {
          return acc;
        }
        acc[name] = value;
        return acc;
      }, {})
    );
  }, [updateSearchParams, filters]);

  return {
    filters,
    activeFilters,
    sortDir,
    sortField,
    commType,
    toDate,
    fromDate,
    minPrice,
    maxPrice,
    numBeds,
    numBaths,
    sqFootage,
    postalCode,
    tags,
    mls,
    batchId,
    listingStatuses,
    searchDateType,
    searchParams,
    currentPage,
    pageSize,
    updateSearchParams,
    deleteSearchParam,
    resetSearchFilters,
    searchQuery,
    printQuery,
    csvApiUrl,
    suppressPriceFilters,
    includePreForeclosureStatusFilter,
    includeDistressedFilters,
    includeListingStatusFilter,
    includeSearchDateTypeFilter,
    includeMLSActivesFilter,
    includeBatchIdFilter,
    includeLookupNameFilter,
    includeLookupSourceFilter,
    includeMlsFilter,
    importedListingsImports
  };
};

export default useSearchListings;
