import { useState, useEffect, useRef, DependencyList } from 'react';

export default function useAsyncMemo<T>(
  factory: () => Promise<T>,
  deps: DependencyList,
  initialValue: T
): T {
  const [value, setValue] = useState<T>(initialValue);
  const hasMounted = useRef(false);

  useEffect(() => {
    let isActive = true;

    if (hasMounted.current) {
      factory().then((result) => {
        if (isActive) {
          setValue(result);
        }
      });
    } else {
      hasMounted.current = true;
    }

    return () => {
      isActive = false;
    };
  // eslint-disable-next-line
  }, deps);

  return value;
}
