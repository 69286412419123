import { useQuery } from "@tanstack/react-query";
import getCurrentGeoLocation from "../utils/getCurrentGeoLocation";
const useCurrentUserGeoLocation = () =>
  useQuery({
    queryKey: ["currentUserGeoLocation"],
    queryFn: getCurrentGeoLocation,
    staleTime: Infinity,
    enabled: false,
  });
export default useCurrentUserGeoLocation;
