import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../utils/sidebar/Sidebar";
import useSidebar from "src/hooks/useSidebar";
import { useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth/assist/authContext";
import Banner from "./banner";
import insp, { install } from "inspectlet-es";
import LoginAs from "./loginAs";
import TOSModal from "./TOSModal";
import { TOS_OVERLAY } from "../../constants/zIndexes";
import { DEFAULT_GUTTER_WIDTH_DESKTOP, DEFAULT_GUTTER_WIDTH_MOBILE, MOBILE_HEADER_HEIGHT } from "../../constants/ui";

const PageLayout = ({
  children,
  pageBG,
  hideSideBar,
  detailsOpen = false,
  suppressGutters = false,
}) => {
  install(1808888316);

  const { isAuth, prelogin, user, hasAcceptedTerms } = useAuth();

  const location = useLocation();
  const currentPage = location.pathname;
  const [isLoggedInAs, setIsLoggedInAs] = useState(false);
  const [showTos, setShowTos] = useState(false);
  const { isSidebarOpen } = useSidebar();

  const pageLayoutClassNames = [
    `p-[${suppressGutters ? 0 : DEFAULT_GUTTER_WIDTH_MOBILE}px] md:p-[${
      suppressGutters ? 0 : DEFAULT_GUTTER_WIDTH_DESKTOP
    }px] md:pt-[16px] pt-[${MOBILE_HEADER_HEIGHT}px]`
  ];
  if (!isSidebarOpen && !hideSideBar) {
    pageLayoutClassNames.push(
      `overflow-visible md:ml-[100px] print:md:ml-0 pageLayout ${pageBG} bg-cover  ${
        detailsOpen ? "max-h-screen" : "hideScrollBar"
      }`
    );
  } else if (isSidebarOpen && !hideSideBar) {
    pageLayoutClassNames.push(
      `overflow-visible md:ml-[240px] print:ml-0 pageLayout ${pageBG} bg-cover  ${
        detailsOpen ? "max-h-screen" : "hideScrollBar"
      }`
    );
  } else {
    pageLayoutClassNames.push(
      `overflow-visible ml-[0px] authPageLayout ${pageBG} bg-cover  ${
        detailsOpen ? "max-h-screen" : "hideScrollBar"
      }`
    );
  }

  const handleTOSAccepted = useCallback(() => {
    setShowTos(false);
  }, []);

  const navigate = () => {
    if (
      currentPage !== "/signin" &&
      currentPage !== "/forgotpassword" &&
      !location.pathname.startsWith("/forgotpassword/") &&
      !isAuth
    ) {
      const redirectLink = document.createElement("a");
      redirectLink.href = "/signin";
      document.body.appendChild(redirectLink);
      if (!isAuth) {
        redirectLink.click();
      }
    }
  };

  useEffect(() => {
    insp(["identify", user?.email]);
    // window.__insp.push(['identify', "john@example.com"]);
  }, [user?.email]);

  useEffect(() => {
    prelogin(() => navigate());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, currentPage]);

  useEffect(() => {
    setShowTos(isAuth && !isLoggedInAs && !hasAcceptedTerms);
  }, [hasAcceptedTerms, isLoggedInAs, isAuth]);

  return (
    <div className="hideScrollBar">
      {showTos && (
        <div
          className="bg-hero-pattern h-screen w-screen bg-cover fixed overflow-y-scroll "
          style={{
            zIndex: TOS_OVERLAY,
          }}
        >
          <TOSModal
            onAccepted={handleTOSAccepted}
            open={true}
            title={"Landvoice License And Service Agreement"}
          ></TOSModal>
        </div>
      )}

      <LoginAs setLoginAs={setIsLoggedInAs} />
      {currentPage === "/signin" ||
      currentPage === "/forgotpassword" ||
      location.pathname.startsWith("/forgotpassword/") ||
      (isAuth && !showTos) ? (
        <main className={pageLayoutClassNames.join(" ")}>
          {hideSideBar ? "" : <Sidebar detailsOpen={detailsOpen} />}
          {currentPage === "/signin" ||
          currentPage === "/forgotpassword" ||
          location.pathname.startsWith("/forgotpassword/") ? (
            ""
          ) : (
            <>
              <Banner />
            </>
          )}

          <div className={detailsOpen ? "hideScrollBar" : ""}>{children}</div>
        </main>
      ) : null}
    </div>
  );
};

export default PageLayout;
