import { useReactToPrint } from "react-to-print";
import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  createRef,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { stringToNumber } from "../../utils/strings";
import useCurrentUserGeoLocation from "../../hooks/useCurrentUserGeoLocation";
import createCsv from "src/utils/createCsv";
import {
  sortingOptions,
  communicationTypeFilters,
} from "../../data/selectionData";
import pdfDownload from "../../components/common/pdfDownload";
export const PrintType = {
  single: "single",
  multiple: "multiple",
};

export const SelectedListingsType = {
  selections: "selections",
  all: "all",
};

export const SearchResultsFormatType = {
  list: "list",
  map: "map",
};

const defaultGetListingGeoLocation = (listing) =>
  listing?.property?.geolocation
    ? {
        lat: stringToNumber(listing.property.geolocation?.latitude),
        lng: stringToNumber(listing.property.geolocation?.longitude),
      }
    : null;

const useSearchListingsPage = ({
  listingIdKey = "id",
  searchQueryKey,
  exportedCsvFileName,
  searchListings,
  getListingGeoLocation = defaultGetListingGeoLocation,
  suppressDetailsOpening = false,
} = {}) => {
  const queryClient = useQueryClient();

  // Query to fetch the current user's geolocation
  const {
    isLoading: isLoadingCurrentUserGeoLocation,
    refetch: getCurrentUserGeoLocation,
  } = useCurrentUserGeoLocation();

  // Printing
  const printOneViewComponentRef = useRef(null);
  const printListViewComponentRef = useRef(null);
  const printDetailsPropertyComponentRef = useRef(null);
  const [printType, setPrintType] = useState(PrintType.single);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);
  const printOne = useReactToPrint({
    contentRef: printOneViewComponentRef,
  });

  const printAll = useReactToPrint({
    contentRef: printListViewComponentRef,
  });

  const printDetailsProperty = useReactToPrint({
    contentRef: printDetailsPropertyComponentRef,
  });

  const [isToolbarOpen, setIsToolbarOpen] = useState(true);
  const [isDownloadingListings, setIsDownloadingListings] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [detailsListingId, setDetailsListingId] = useState(false);
  const [selectedPageListing, setSelectedPageListing] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [printWarningIsOpen, setPrintWarningIsOpen] = useState(false);
  const [downloadWarningIsOpen, setDownloadWarningIsOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState();
  const [selectedListingsType, setSelectedListingsType] = useState(
    SelectedListingsType.all
  );
  const [searchResultsFormat, setSearchResultsFormat] = useState(
    SearchResultsFormatType.list
  );

  const { csvApiUrl, printQuery, commType, sortField, sortDir, searchQuery, searchParams } =
    searchListings;

  const { data: { total_records: totalRecords, listings } = {}, isLoading } =
    searchQuery;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const {
    isLoading: isLoadingListingsForPrint,
    refetch: fetchListingsForPrint,
    data: { listings: fetchedListingsForPrint } = {},
  } = printQuery;

  const listingRefs = useRef([]); // Store refs for each item
  const listingRefsById = useRef([]); // Store refs for each item by id

  const getListingId = useCallback(
    (listing) => (listing ? listing[listingIdKey] : null),
    [listingIdKey]
  );

  // Initialize refs array
  if (listings && listingRefs.current.length !== listings?.length) {
    listingRefsById.current = listings?.reduce((acc, listing) => {
      return {
        ...acc,
        [getListingId(listing)]: createRef(),
      };
    }, {});

    listingRefs.current = Array(listings.length)
      .fill()
      .map((_, i) => listingRefs.current[i] || createRef());
  }

  const detailsListingInfo = useMemo(() => {
    const searchResults = queryClient.getQueriesData({
      predicate: (query) => {
        return query.queryKey.includes(searchQueryKey);
      },
    });

    const foundQuery = searchResults.find((searchResult) =>
      searchResult[1]?.listings?.find(
        (listing) => detailsListingId === listing[listingIdKey]
      )
    );

    if (!foundQuery) {
      return null;
    }

    const listings = foundQuery?.[1]?.listings;
    return {
      listing: listings?.find(
        (listing) => detailsListingId === listing[listingIdKey]
      ),
      queryKey: foundQuery[0],
    };
  }, [queryClient, searchQueryKey, detailsListingId, listingIdKey]);

  const detailsListing = useMemo(
    () => detailsListingInfo?.listing,
    [detailsListingInfo]
  );
  const detailsListingNotes = useMemo(
    () => detailsListing?.notes || [],
    [detailsListing]
  );
  const detailsListingTags = useMemo(
    () => detailsListing?.tags || [],
    [detailsListing]
  );

  useEffect(() => {
    setSelectedItems(new Set());
    setIsSelectAllChecked(false);
  }, [searchParams]);

  const optimisticUpdateListing = useCallback(
    async (updatedListing) => {
      const { queryKey } = detailsListingInfo;

      await queryClient.cancelQueries(queryKey);

      const searchResults = queryClient.getQueryData(queryKey);

      const updatedListings = searchResults?.listings?.reduce(
        (acc, listing) => {
          if (listing[listingIdKey] === updatedListing[listingIdKey]) {
            return [...acc, updatedListing];
          }
          return [...acc, listing];
        },
        []
      );

      queryClient.setQueryData(queryKey, {
        ...searchResults,
        listings: updatedListings,
      });

      return updatedListings;
    },
    [detailsListingInfo, queryClient, listingIdKey]
  );

  const optimisticUpdateDetailsListingTags = useCallback(
    (updatedTags) => {
      optimisticUpdateListing({
        ...detailsListingInfo.listing,
        tags: updatedTags,
      });
    },
    [detailsListingInfo, optimisticUpdateListing]
  );

  const optimisticUpdateDetailsListingNotes = useCallback(
    (updatedNotes) => {
      optimisticUpdateListing({
        ...detailsListingInfo.listing,
        notes: updatedNotes,
      });
    },
    [detailsListingInfo, optimisticUpdateListing]
  );

  const togglePrintWarning = useCallback(() => {
    setPrintWarningIsOpen((cur) => !cur);
  }, []);

  const toggleDownloadWarning = useCallback(() => {
    setDownloadWarningIsOpen((cur) => !cur);
  }, []);

  const toggleDetailsPanel = useCallback(
    (listing) => {
      setDetailsListingId(listing ? getListingId(listing) : null);
      setDetailsOpen(!!listing);
    },
    [setDetailsListingId, getListingId]
  );

  const selectedSort = useMemo(() => {
    const sortOption = sortingOptions.find(
      ({ value }) => value.sort_dir === sortDir && value.sort === sortField
    );
    if (sortOption) {
      return {
        id: sortOption.name,
        label: sortOption.name,
        value: sortOption.value,
      };
    }
  }, [sortField, sortDir]);

  const selectedCommType = useMemo(() => {
    const selectedCommTypeOption = communicationTypeFilters.find(
      ({ value }) => value === commType
    );
    if (selectedCommTypeOption) {
      return {
        id: selectedCommTypeOption.name,
        label: selectedCommTypeOption.name,
        value: selectedCommTypeOption.value,
      };
    }
  }, [commType]);

  const detailsProperty = useMemo(
    () => detailsListingInfo?.listing,
    [detailsListingInfo]
  );

  const numPrintRecords = useMemo(() => {
    if (selectedListingsType === SelectedListingsType.selections) {
      return selectedItems.size;
    }

    return totalRecords;
  }, [selectedItems, totalRecords, selectedListingsType]);

  const downloadListings = useCallback(async () => {
    if (detailsOpen || isDownloadingListings) {
      return;
    }

    if (selectedListingsType === SelectedListingsType.selections) {
      setIsDownloadingListings(true);
      createCsv(exportedCsvFileName, listingsForPrint);
      setIsDownloadingListings(false);
    } else {
      try {
        setIsDownloadingListings(true);
        await pdfDownload({
          url: csvApiUrl,
          fileName: exportedCsvFileName,
          extension: "csv",
          onComplete: () => {
            setIsDownloadingListings(false);
          },
        });
      } finally {
        setIsDownloadingListings(false);
      }
    }
  }, [
    csvApiUrl,
    exportedCsvFileName,
    detailsOpen,
    isDownloadingListings,
    numPrintRecords,
  ]);

  const printListings = useCallback(async () => {
    if (selectedListingsType === SelectedListingsType.selections) {
      if (!selectedItems.size) {
        return;
      }
      setIsReadyToPrint(true);
      return;
    }

    if (isLoadingListingsForPrint || detailsOpen) {
      return;
    }
    const resp = await fetchListingsForPrint();
    if (resp.data.total_records > 0) {
      setIsReadyToPrint(true);
    }
  }, [
    fetchListingsForPrint,
    setIsReadyToPrint,
    detailsOpen,
    selectedListingsType,
    selectedItems,
    isLoadingListingsForPrint,
  ]);

  useEffect(() => {
    if (!isReadyToPrint || !printType) {
      return;
    }
    setTimeout(() => {
      printType === PrintType.single ? printOne() : printAll();
    }, 500);
    setIsReadyToPrint(false);
  }, [
    isReadyToPrint,
    printOne,
    printAll,
    printType,
    setPrintType,
    setIsReadyToPrint,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if 'P' is pressed along with 'Control' or 'Command'
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "p") {
        event.preventDefault();
        if (detailsOpen) {
          printDetailsProperty();
        } else {
          printListings();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [detailsOpen]);

  const toggleFiltersPanel = () => setFilterOpen((cur) => !cur);
  const toggleToolbar = () => setIsToolbarOpen((cur) => !cur);

  const selectListing = useCallback(
    (listing, suppressDetailsOpen) => {
      if (!suppressDetailsOpen && !suppressDetailsOpening) {
        toggleDetailsPanel(listing);
      }
      listing && setSelectedPageListing(listing);
    },
    [toggleDetailsPanel, setSelectedPageListing, suppressDetailsOpening]
  );

  const selectNextListing = useCallback(
    ({ suppressDetailsOpen = false }) => {
      let nextListingIndex;
      const targetListing = detailsListing || selectedPageListing;
      if (!targetListing) {
        nextListingIndex = 0;
      } else {
        nextListingIndex =
          listings.indexOf(targetListing) > -1
            ? listings.indexOf(targetListing) + 1
            : listings.length - 1;
      }
      selectListing(listings?.[nextListingIndex], suppressDetailsOpen);
      if (searchResultsFormat === SearchResultsFormatType.list) {
        listingRefs?.current?.[nextListingIndex]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    [
      detailsListing,
      selectListing,
      searchResultsFormat,
      listings,
      selectedPageListing,
    ]
  );

  const selectPreviousListing = useCallback(
    ({ suppressDetailsOpen = false }) => {
      const targetListing = detailsListing || selectedPageListing;
      const nextListingIndex =
        listings.indexOf(targetListing) > 0
          ? listings.indexOf(targetListing) - 1
          : 0;
      selectListing(listings?.[nextListingIndex], suppressDetailsOpen);

      if (searchResultsFormat === SearchResultsFormatType.list) {
        listingRefs?.current?.[nextListingIndex]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    [
      detailsListing,
      selectListing,
      searchResultsFormat,
      listings,
      selectedPageListing,
    ]
  );

  const toggleNotesDialog = useCallback(
    (property) => {
      setDetailsListingId(property ? getListingId(property) : null);
      setNotesOpen((cur) => !cur);
    },
    [setDetailsListingId, getListingId]
  );

  const toggleSelectAll = useCallback(
    (newIsChecked) => {
      setIsSelectAllChecked(newIsChecked);
      if (newIsChecked) {
        setSelectedItems(
          new Set(listings.map((listing) => getListingId(listing)))
        );
      } else {
        setSelectedItems(new Set());
      }
    },
    [isSelectionMode, listings, setIsSelectionMode, setSelectedItems]
  );

  const toggleSelectionMode = useCallback(() => {
    setIsSelectionMode(!isSelectionMode);
    setIsSelectAllChecked(false);
    setSelectedItems(new Set());
  }, [isSelectionMode, setIsSelectionMode, setSelectedItems]);

  const listingsForPrint = useMemo(() => {
    if (selectedListingsType === SelectedListingsType.selections) {
      return (listings || []).filter((listing) => {
        return selectedItems.has(getListingId(listing));
      });
    }
    return fetchedListingsForPrint;
  }, [fetchedListingsForPrint, listings, selectedListingsType, selectedItems]);

  const toggleItemSelection = useCallback(
    (id) => {
      const newSelectedItems = new Set(selectedItems);
      if (newSelectedItems.has(id)) {
        newSelectedItems.delete(id);
      } else {
        newSelectedItems.add(id);
      }
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, setSelectedItems]
  );

  const toggleTagsDialog = useCallback(
    (property) => {
      setDetailsListingId(property ? getListingId(property) : null);
      setTagsOpen((cur) => !cur);
    },
    [setDetailsListingId, setTagsOpen, getListingId]
  );

  return {
    selectedCommType,
    selectedSort,
    optimisticUpdateListing,
    detailsListingId,
    detailsProperty,
    detailsOpen,
    toggleDetailsPanel,
    filterOpen,
    tagsOpen,
    printType,
    setPrintType,
    toggleNotesDialog,
    toggleTagsDialog,
    toggleToolbar,
    isToolbarOpen,
    detailsListing,
    detailsListingNotes,
    detailsListingTags,
    notesOpen,
    printOneViewComponentRef,
    printListViewComponentRef,
    printDetailsPropertyComponentRef,
    toggleFiltersPanel,
    printWarningIsOpen,
    togglePrintWarning,
    downloadWarningIsOpen,
    toggleDownloadWarning,
    printListings,
    downloadListings,
    isDownloadingListings,
    setIsDownloadingListings,
    scrollToTop,
    updateDetailsListingNotes: optimisticUpdateDetailsListingNotes,
    updateDetailsListingTags: optimisticUpdateDetailsListingTags,
    getListingId,
    getListingGeoLocation,
    mapCenter,
    setMapCenter,
    searchResultsFormat,
    setSearchResultsFormat,
    isLoadingCurrentUserGeoLocation,
    getCurrentUserGeoLocation,
    selectedPageListing,
    setSelectedPageListing,
    selectListing,
    selectNextListing,
    selectPreviousListing,
    listingRefsById,
    listingRefs,
    listingsForPrint,
    numPrintRecords,
    toggleSelectionMode,
    toggleSelectAll,
    toggleItemSelection,
    isSelectionMode,
    selectedItems,
    setSelectedItems,
    isSelectAllChecked,
    setIsSelectAllChecked,
    selectedListingsType,
    setSelectedListingsType,
  };
};

export default useSearchListingsPage;
