import React from "react";

const StatusTag = ({ status, date }) => {
  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const formattedDate = `${parseInt(parts[1], 10)}/${parseInt(
      parts[2],
      10
    )}/${parts[0]}`;
    return formattedDate;
  }

  return status === "" ? (
    <></>
  ) : (
    <div
      className={
        ` h-[28px] w-fit rounded-[6px]  py-[2px] px-2 gap-[6px] pb-2 lg:mb-1 ` +
        (status.toLocaleLowerCase() === "active"
          ? "bg-[#FAE6E6]"
          : status.toLocaleLowerCase() === "expired" ||
            status.toLocaleLowerCase() === "canceled" ||
            status.toLocaleLowerCase() === "withdrawn" ||
            status.toLocaleLowerCase() === "lis pendens" ||
            status.toLocaleLowerCase() === "notice of default" ||
            status.toLocaleLowerCase() === "fsbo"
          ? "bg-[#daf4e2]"
          : status.toLocaleLowerCase() === "notice of trustee sale" ||
            status.toLocaleLowerCase() === "notice of sale" ||
            status.toLocaleLowerCase() === "pending" ||
            status.toLocaleLowerCase() === "sold" ||
            status.toLocaleLowerCase() === "off Market" ||
            status.toLocaleLowerCase() === "unknown"
          ? "bg-[#FAF1E6]"
          : status.toLocaleLowerCase() === "bank owned"
          ? "bg-[#ECEFEF]"
          : "bg-transparent")
      }
    >
      <p
        className={
          `text-[14px] font-semibold leading-6 text-center whitespace-nowrap ` +
          (status.toLocaleLowerCase() === "active"
            ? "text-[#E5342E]"
            : status.toLocaleLowerCase() === "expired" ||
              status.toLocaleLowerCase() === "canceled" ||
              status.toLocaleLowerCase() === "withdrawn" ||
              status.toLocaleLowerCase() === "lis pendens" ||
              status.toLocaleLowerCase() === "notice of default" ||
              status.toLocaleLowerCase() === "fsbo"
            ? "text-[#36B25F]"
            : status.toLocaleLowerCase() === "notice of trustee sale" ||
              status.toLocaleLowerCase() === "notice of sale" ||
              status.toLocaleLowerCase() === "pending" ||
              status.toLocaleLowerCase() === "sold" ||
              status.toLocaleLowerCase() === "off Market" ||
              status.toLocaleLowerCase() === "unknown"
            ? "text-[#E5941B]"
            : status.toLocaleLowerCase() === "bank owned"
            ? "text-[#666666]"
            : " text-transparent")
        }
      >
        {`${status}${date ? ": " : ""} ${date ? formatDate(date) : ""}`}
      </p>
    </div>
  );
};

export default StatusTag;
