export const MODAL_CONTENT = 50;
export const MODAL_BACKGROUND = 40;
export const SEARCH_PAGE_HEADER = 5;
export const SELECT_DROPDOWN = 10;
export const SEARCH_PAGE_DETAILS_OVERLAY = 60;
export const SIDEBAR = 45;
export const FEATURE_DISCOVERY_TOOLTIP = 49
export const MOBILE_SIDEBAR_OVERLAY = 999999999;
export const MOBILE_SIDEBAR = 999999;
export const DATE_PICKER_OVERLAY = 99999999;
export const TOS_OVERLAY = 9999999999999
