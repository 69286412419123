import React, { useRef } from 'react'
import { Button, Input } from '../shadcn'
import { Upload } from 'lucide-react'

export default function FileInputButton({ onFileSelected, buttonLabel = "Upload File" }: { buttonLabel: string, onFileSelected: (file: File | null) => void }) {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleButtonClick = () => {
        fileInputRef.current?.click()
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null
        onFileSelected(file)
    }

    return (
        <div className="flex flex-col items-center space-y-4">
            <Input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                id="file-upload"
            />
            <Button onClick={handleButtonClick} size="sm">
                <Upload className="mr-1" /> {buttonLabel}
            </Button>
        </div>
    )
}
