import { Switch } from "@headlessui/react";
import Tooltip from "./Tooltip";
import FeatureDiscoveryTooltip, { FeatureDiscoveryTooltipProps } from "../FeatureDiscoveryTooltip";

type ToggleButtonFeatureDiscoveryTooltipProps = Omit<
  FeatureDiscoveryTooltipProps,
  'children' | 'isOpen'
>;

type ToggleButtonProps = {
  isEnabled?: boolean;
  onChange: (isEnabled: boolean) => void;
  disabledClassName?: string;
  enabledClassName?: string;
  tooltipContent?: string;
  DiscoveryTooltipProps?: ToggleButtonFeatureDiscoveryTooltipProps
};

const ToggleButton = ({
  isEnabled = false,
  onChange,
  disabledClassName = "bg-teal-700",
  enabledClassName = "bg-teal-900",
  tooltipContent,
  DiscoveryTooltipProps
}: ToggleButtonProps) => {
  const button = (
    <Switch
      checked={isEnabled}
      onChange={onChange}
      className={`${isEnabled ? enabledClassName : disabledClassName}
        relative inline-flex h-[20px] w-[35px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${isEnabled ? "translate-x-[13px]" : "translate-x-0"}
          pointer-events-none inline-block h-[16px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
  if (DiscoveryTooltipProps) {
    return <FeatureDiscoveryTooltip {...DiscoveryTooltipProps} isOpen>{button}</FeatureDiscoveryTooltip>
  }
  if (tooltipContent) {
    return <Tooltip content={tooltipContent}>{button}</Tooltip>
  }
  return button;
};

export default ToggleButton;
