import React from 'react';
import { Button, Popover, PopoverContent, PopoverAnchor, PopoverArrow } from './shadcn';
import { FEATURE_DISCOVERY_TOOLTIP } from 'src/constants/zIndexes';
export interface FeatureDiscoveryTooltipProps {
  children: React.ReactNode;
  content: string;
  isOpen: boolean;
  onNext: () => void;
  isLast: boolean;
  zIndex?: number;
}

const FeatureDiscoveryTooltip = ({ children, content, isOpen, onNext, isLast, zIndex = FEATURE_DISCOVERY_TOOLTIP }: FeatureDiscoveryTooltipProps) => {
  return (
    <Popover open={isOpen}>
      <PopoverAnchor>
        {children}
      </PopoverAnchor>
      <PopoverContent style={{ zIndex }} className="w-80 text-[#666666] border-[#fa7532]" side="bottom">
        <PopoverArrow style={{ fill: "#F27935" }} className="h-3 w-4" />
        <p className="mb-2">{content}</p>
        <div className="flex justify-end">
          <Button size="sm" onClick={onNext} className="min-w-[100px] bg-[#F27935] disabled:bg-[#836e61f9] hover:bg-[#E2631B] selection:bg-[#C04E0E]">
            {isLast ? 'Finish' : 'Next'}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default FeatureDiscoveryTooltip

