import { useQuery } from "@tanstack/react-query";
import fetcher from "../utils/fetcher";
const useAccount = () => {
  return useQuery({
    queryKey: ["getUserAccount"],
    queryFn: async () => {
      const resp = await fetcher({
        url: "/api/core/account",
      });
      return resp.data;
    },
    staleTime: Infinity,
  });

};
export default useAccount;
