import { PRODUCT_TYPES } from "../constants/listing";
import {
  PreforeclosureStatusTypeEnum,
  StatusTypeEnum,
} from "../data/selectionData";
export const getDistressedListingMapMarkerColor = (rate) => {
  if (rate === 0) {
    return "#F5F7F7";
  } else if (rate === 1) {
    return "#F6E3D2";
  } else if (rate === 2) {
    return "#FDCFB8";
  } else if (rate === 3) {
    return "#F6A77F";
  } else if (rate === 4) {
    return "#FA7532";
  }
  return null;
};

export const getExpiredListingMapMarkerColor = (status) => {
  if (["X", "W", "C"].includes(status)) {
    return "#36B25F";
  }

  if (["C"].includes(status)) {
    return "#E5342E";
  }

  return null;
};

export const getPreForeclosureListingMapMarkerColor = (status) => {
  /* eslint-disable no-fallthrough */
  switch (status) {
    // Lis Pendens
    case "L":
    // Notice of Default
    case "D":
      // Green
      return "#daf4e2";
    // Notice of Sale
    case "S":
    // Notice of Trustee Sale
    case "T":
      // Orange
      return "#FAF1E6";
    // Bank Owned (REO)
    case "B":
      // Blue
      return "#36B25F";
    default:
      return null;
  }
};

export const getStatusDateFromMlsStatus = (listing) =>
  listing?.mls_status?.status_date;

export const getListingStatusDate = (listing) => listing?.status_date;

export const getListingStatusDateUsingListingType = (listing) => {
  const { productType } = listing;
  switch (productType) {
    case PRODUCT_TYPES.preforeclosure:
    case PRODUCT_TYPES.fsbo:
    case PRODUCT_TYPES.distressed:
      return getStatusDateFromMlsStatus(listing);
    case PRODUCT_TYPES.expired:
    case PRODUCT_TYPES.oldexpired:
      return getListingStatusDate(listing);
    default:
      return null;
  }
};

export const getPreForeclosureListingStatus = (listing) => {
  return listing?.mls_status
    ? StatusTypeEnum[listing.mls_status?.status]
    : PreforeclosureStatusTypeEnum?.[listing?.listing_type];
};

export const getOldExpiredListingStatus = (listing) =>
  StatusTypeEnum[listing?.status];

export const getFsboListingStatus = (listing) =>
  StatusTypeEnum[listing?.mls_status?.status] || "FSBO";

export const getDistressedListingStatus = (listing) =>
  listing?.mls_status?.status ? StatusTypeEnum[listing.mls_status.status] : "";

export const getExpiredListingStatus = (listing) =>
  StatusTypeEnum[listing?.status];

export const getListingStatusUsingListingType = (listing) => {
  const { productType } = listing;
  switch (productType) {
    case PRODUCT_TYPES.preforeclosure:
      return getPreForeclosureListingStatus(listing);
    case PRODUCT_TYPES.fsbo:
      return getFsboListingStatus(listing);
    case PRODUCT_TYPES.distressed:
      return getDistressedListingStatus(listing);
    case PRODUCT_TYPES.expired:
      return getExpiredListingStatus(listing);
    case PRODUCT_TYPES.oldexpired:
      return getOldExpiredListingStatus(listing);
    default:
      return null;
  }
};

export const getListingPriceUsingListingType = (listing) => {
  let price = 0;
  const { productType } = listing;
  switch (productType) {
    case PRODUCT_TYPES.preforeclosure:
      price = getPreforeclosureListingPrice(listing);
      break;
    case PRODUCT_TYPES.distressed:
      price = getDistressedListingPrice(listing);
      break;
    case PRODUCT_TYPES.expired:
    case PRODUCT_TYPES.oldexpired:
      price = getListingPrice(listing);
      break;
    case PRODUCT_TYPES.fsbo:
      price = getFsboListingPrice(listing);
      break;
    default:
      price = 0;
  }
  return getFormattedPrice(price);
};

export const getListingTitleUsingListingType = (listing) => {
  const { productType } = listing;
  switch (productType) {
    case PRODUCT_TYPES.preforeclosure:
      return getPreforeclosureListingTitle(listing);
    case PRODUCT_TYPES.distressed:
      return getDistressedListingTitle(listing);
    case PRODUCT_TYPES.expired:
    case PRODUCT_TYPES.oldexpired:
      return getExpiredListingTitle(listing);
    case PRODUCT_TYPES.fsbo:
      return getFsboListingTitle(listing);
    default:
      return "";
  }
};

export const getExpiredListingTitle = (listing) =>
  `${listing?.property?.street_address || listing?.contacts?.street_address}, ${
    listing?.property?.city || listing?.contacts?.city
  }, ${listing?.property?.state || listing?.contacts?.state} ${
    listing?.property?.postal_code || listing?.contacts?.postal_code
  }`;

export const getDistressedListingTitle = (listing) =>
  `${listing?.property?.street_address || listing?.street_address}, ${
    listing?.property?.city || listing?.city
  }, ${listing?.property?.state || listing?.state} ${
    listing?.property?.postal_code || listing?.postal_code
  }`;

export const getImportedListingTitle = (listing) =>
  listing?.property?.street_address
    ? `${listing?.property?.street_address || "--"}, ${
        listing?.property?.city || "--"
      }, ${listing?.property?.state || "--"} ${
        listing?.property?.postal_code || "--"
      }`
    : "No Address";

export const getFsboListingTitle = (listing) =>
  `${listing?.street_address} ${
    listing?.unit_number ? " Unit " + listing?.unit_number : ""
  }, ${listing?.city}, ${listing?.state} ${listing?.postal_code}`;

export const getPreforeclosureListingTitle = (listing) =>
  `${listing?.property?.street_address}, ${listing?.property?.city}, ${listing?.property?.state} ${listing?.property?.postal_code}`;

export const getListingPrice = ({ list_price }) => parseFloat(list_price || 0);

export const getFsboListingPrice = (listing) => parseFloat(listing?.price || 0);

export const getImportedListingPrice = (listing) =>
  parseFloat(listing?.price || 0);

export const getDistressedListingPrice = (listing) =>
  parseFloat(
    listing?.list_price ||
      listing?.auction_min_bid ||
      listing?.market_value ||
      0
  );

export const getPreforeclosureListingPrice = (listing) =>
  parseFloat(listing?.list_price || listing?.auction_min_bid || 0);

export const getFormattedPrice = (price) =>
  price
    ? price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : 0;

export const getPropertyId = (listing) =>
  listing.productType === PRODUCT_TYPES.fsbo
    ? listing?.property_id
    : listing?.property?.id;

export const removeDuplicateContactsAndMarkOriginals = (contacts) => {
  const uniqueContacts = [];
  const seenNumbers = new Map();

  contacts.forEach((contact) => {
    if (!seenNumbers.has(contact?.phone)) {
      seenNumbers.set(contact?.phone, uniqueContacts?.length);
      uniqueContacts.push({ ...contact, star: false });
    } else {
      const originalIndex = seenNumbers.get(contact?.phone);
      const originalContact = uniqueContacts[originalIndex];

      // Check if the original contact's phone_type is 'U' or 'u'
      if (
        originalContact?.phone_type === "U" ||
        originalContact?.phone_type === "u"
      ) {
        // Check if the current contact's phone_type is not 'U' or 'u'
        if (contact?.phone_type !== "U" && contact?.phone_type !== "u") {
          // Update the original contact's phone_type
          originalContact.phone_type = contact?.phone_type;
        }
      }

      // Mark the original contact with a star
      originalContact.star = true;
    }
  });

  return uniqueContacts;
};
